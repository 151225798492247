import { SET_PHARMACY_DATA } from './pharmacyTypes'
import { SET_PHARMACY_ADDRESS_DATA } from './pharmacyTypes'

const initialState = {
    id: '',
    addressId: ''
    
}

const pharmacyReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_PHARMACY_DATA:
            return {
                ...state,
                id : action.payload
            }
        case SET_PHARMACY_ADDRESS_DATA:
                 
                return {
                  ...state,
                  addressId: action.payload
                };
        default:
            return state
    }
}

export default pharmacyReducer;