import React from 'react'
import Select from 'react-select'
import styles from './styles'

class AutoSuggest extends React.Component {
    render() {
        const {
            placeholder = 'Select',
            options = [],
            name = '',
            onChange = () => { }
        } = this.props

        return (<Select
            className="autosuggest"
            options={options || []}
            classNamePrefix="auto"
            name={name}
            isSearchable
            onChange={onChange}
            styles={styles}
            placeholder={placeholder}
        />)
    }
}

export default AutoSuggest;