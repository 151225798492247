import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const PrivateRoute = ({ reditectBack = false, 
    component: Component, doctor, patient, to = '/login', ...rest }) => {

    const { token } = useSelector(state => state.auth);
    let userTypeValid = true; 

    if (doctor) {
        userTypeValid = token && token.user_type === 'DOCTOR'
    }

    if (patient) {
        userTypeValid = token && token.user_type === 'PATIENT'
    }

    // if (pharmacist) {
    //     userTypeValid = token && token.user_type === 'PHARMACIST'
    // }

    // if (laboratory) {
    //     userTypeValid = token && token.user_type === 'LABORATORY'
    // }
    
    return (
        <Route
            {...rest}
            render={props => {
                if (reditectBack) {
                    // sessionStorage.setItem('redirectTo', props.location.pathname)
                    to = to.split('?').slice(0,1).join('') +"?to="+ props.location.pathname
                }
                return (token && userTypeValid) ? <Component {...props} /> : <Redirect to={to} />

            }
            }
        />
    )
}

export default PrivateRoute;