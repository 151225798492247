import React, { useState } from 'react';



const ImageUpload = props => {

    const [file, setFile] = useState();
    const { src } = props

    return (<div><input
        style={{ display: "none" }}
        id="profile"
        name="profile"
        type="file"
        onChange={event => {
            console.log(event.currentTarget.files[0])
            setFile(event.currentTarget.files[0])
        }}
        accept="image/x-png,image/gif,image/jpeg"
    />
        <label htmlFor="profile">
                <img style={{cursor: 'pointer'}}
                    src={
                        src || process.env.PUBLIC_URL+"/assets/img/blank-profile.jpg"
                    }
                    alt="User Image"
                />
        </label>
        {/* {errors && <label style={{ display: "block", position: "absolute", zIndex:100 }}
            className="is-invalid text-danger">{errors}</label>} */}
    </div>)
}

export default ImageUpload;