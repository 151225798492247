import React from 'react';

function Breadcrumb(props) {

    const { items = [], title } = props
    let content = props.children;

    if (!content && items && items.length > 0) {
        content = items.map((item, index) => {
            const itemText = item.text ? item.text : item;
            if (!item.url || index === items.length) {
                return <li key={index} className="breadcrumb-item" aria-current="page">{itemText}</li>
            }
            return <li key={index} className="breadcrumb-item"><a href={item.url}>{itemText}</a></li>
        })
    }
    if (!content) {
        return <></>
    }
    return (
        <div className="breadcrumb-bar">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-12 col-12">
                        <nav aria-label="breadcrumb" className="page-breadcrumb">
                            <ol className="breadcrumb">

                                {content}

                            </ol>
                        </nav>
                        {title && <h2 className="breadcrumb-title">{title}</h2>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Breadcrumb;