import { SET_LAB_DATA } from "./labTypes";
import { SET_LAB_ADDRESS_DATA } from './labTypes'
import { SET_LAB_TEST_DATA } from './labTypes'

export const setLabData = data =>{
  
    return {
        type: SET_LAB_DATA,
        payload: data
    }
}
export const setLabAddressData = (data) =>{
    return {
        type: SET_LAB_ADDRESS_DATA,
        payload: data
    }
}
export const setLabTestData = (data) =>{
    return {
        type: SET_LAB_TEST_DATA,
        payload: data
    }
}

export default {
    setLabData,
    setLabAddressData,
    setLabTestData


}