import moment from 'moment-timezone';
// const getTimezoneInstance = Intl.DateTimeFormat().resolvedOptions().timeZone;


var getTimezoneInstance =  moment.tz.guess();

const timezone = getTimezoneInstance === "Asia/Calcutta" ? "Asia/Kolkata" : getTimezoneInstance;

export {
    timezone
}