import { Button, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { showSuccessToast } from '../../../redux';
import autoCatchApi from '../../../common/util/autoCatch';
import ButtonWithSpinner from '../../General/Form/ButtonWithSpinner';
import Input from '../../General/Form/Input';
import { checkUsername, changeUsername } from '../../../common/api/accountApi';
import * as Yup from 'yup';

const validationS = Yup.object({
    username: Yup.string()
    .nullable()
    .max(15, 'Must be 15 characters or less.')
    .required('The username field is required.'),
    })

export default function AddAddress({ color, userName, updateUsername, onSuccess = () => { }, varient = "outline-primary", className = "mr-1", size = "sm" }) {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch()
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  
  function isUserValid(value, setFieldError){
    checkUsername(value).catch(err => {
        let message = 'The username has already been taken.';
        try {
            message = err.response.data.errors.username[0];
        } catch (e) {
            console.error(e)
        }
        setFieldError('username', message)
    })
}
  
const initialValues = {
    username: userName,
}
  return (
    <>
    <Button variant="link" style={{color:color}} onClick={handleShow}>{userName}</Button>

      <Formik
        initialValues={initialValues}
        validationSchema={validationS}
        onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
          setSubmitting(true);
          // console.log(values, "values")
          autoCatchApi(changeUsername(values).then(res => {
            updateUsername(values.username)
            const { data: { message } = {} } = res;
            // console.log(res);
            dispatch(showSuccessToast(message));
            setSubmitting(false);
            handleClose();
            onSuccess();
            resetForm();
          }), (data) => {
            if (data && data.errors) {
              setErrors(data.errors)
            }
            setSubmitting(false)
          })
        }}
      >{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        setFieldError,
        handleReset,
        isSubmitting,
        isValid,
        resetForm,
        dirty
        /* and other goodies */
      }) => (
          <Modal show={show} onHide={() => {
            resetForm();
            handleClose();
          }}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Username</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              {/** row 2*/}
              <div className="row form-row">
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>Username</label>
                    <Input
                      onChange={e => {
                        setFieldTouched('username', true)
                        setFieldValue("username", e.target.value)
                        isUserValid(e.target.value, setFieldError)
                      }}
                      onBlur={e => {
                        handleBlur('username', e.target.value || '');
                        isUserValid(e.target.value, setFieldError)
                      }}
                      required
                      value={values.username}
                      hasError={touched.username && errors.username}
                      feedback={errors.username}
                      type="text"
                      name="username"
                      label="Username" />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-default" onClick={() => {
                handleReset();
                handleClose();
              }}>Close</button>
              <ButtonWithSpinner
                onClick={handleSubmit}
                isLoading={isSubmitting}
                disabled={isSubmitting || !isValid || !dirty}
                variant="primary"
                type="submit">Save Changes</ButtonWithSpinner>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
}
