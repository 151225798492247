import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup'

import { AccountTypes } from '../../../General/Form/SepcificSelect'
import { toastSuccess } from '../../../../common/util/toast';
import autoCatch from '../../../../common/util/autoCatch';
import ButtonWithSpinner from '../../../General/Form/ButtonWithSpinner';
import Input from './../../../General/Form/Input';
import laboratoryRegister from '../../../../common/api/laboratory/laboratoryRegister';
import {nameRegex, ifsc_code } from '../../../../common/util/formUtil'
import { useDispatch } from 'react-redux';
import { connect } from "react-redux"; 
import { useHistory } from 'react-router-dom';
import { showSuccessToast, setUserData } from '../../../../redux';

const defaultFormValues = {
    account_holder_full_name: '',
    bank_city: '',
    bank_name: '',
    account_number: '',
    ifsc_code: '',
    account_type: 'SAVINGS',
    iAgree: false
}

const mapStateToProps = state => {
    const { labId } = state;
    return { labId };
  };


const LabBankDetails = (props) => {
    console.log('props----------------------------------', props.labId.testId)
    const [loading, setLoading] = useState(true);

    let initialValues = defaultFormValues;
    const history = useHistory();
    const dispatch = useDispatch()

    const validationSchema = Yup.object({
        
        account_number: Yup.string()
                .required('The bank account numberfield is required.'),
        account_holder_full_name: Yup.string()
                .matches(nameRegex, 'Must be a valid name')
                .required('The bank account holder field is required.'),
        bank_name: Yup.string()
                .matches(nameRegex, 'Must be a valid bank name')
                .required('The bank name field is required.'),
        bank_city: Yup.string()
                .matches(nameRegex, 'Must be a valid bank city')
                .required('The bank city field is required.'),
        ifsc_code: Yup.string()
                .matches(ifsc_code, 'Must be a valid ifsc code')
                .required('The ifsc field is required.'),
        account_type: Yup.string()
                .matches(nameRegex, 'Must be a valid account type')
                .required('The account type field is required.')
    })

    return (
        <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
            setSubmitting(true);
            let formData = new FormData();
            const {
                account_number,
                account_holder_full_name,
                bank_name,
                bank_city,
                ifsc_code,
                account_type
            } = values

            if (account_number &&  account_holder_full_name && bank_name && ifsc_code 
                    && bank_city && account_type && props.labId.testId) {
                formData.append('bank_account_number', account_number)
                formData.append('bank_account_holder', account_holder_full_name)
                formData.append('bank_name', bank_name)
                formData.append('bank_ifsc', ifsc_code)
                formData.append('bank_city', bank_city)
                formData.append('bank_account_type', account_type)
                formData.append('data_id', props.labId.testId)
            }
           
            autoCatch(laboratoryRegister.labBankDetails(formData).then(res => {
            if (res.status === 200) {
                toastSuccess('registration successful')
                const { email, mobile_number } = res.data
                    dispatch(setUserData({ email, mobile_number }))
                    dispatch(showSuccessToast("Please complete OTP verification."))
                    setSubmitting(false);
                    history.push('/laboratory/confirm?via=WEB');
            }
            setLoading(true);
            setSubmitting(false);
            }), data => {
            if (data && data.errors) {
                setErrors(data.errors)
            }
            setSubmitting(false);
            })

        }}
        >{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        dirty,
        isValid,
        }) => (
            <form onSubmit={handleSubmit}>            
            {/** row 5 */}
            <h4 className="card-title my-4">Banking Details</h4>
            <div className="row form-row">
                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <label>Bank Account Number</label>
                        <Input
                        mandatory
                        name="account_number"
                        label="Account Number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.account_number}
                        touched={touched.account_number}
                        errors={errors.account_number}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <label>Account Holder Full Name</label>
                        <Input
                        mandatory
                        name="account_holder_full_name"
                        label="Account Holder Full Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.account_holder_full_name}
                        touched={touched.account_holder_full_name}
                        errors={errors.account_holder_full_name}
                        />
                    </div>
                </div>
            </div>     
            {/** row 6 */}
            <div className="row form-row">
                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <label>Bank Name</label>
                        <Input
                        mandatory
                        name="bank_name"
                        label="Bank Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.bank_name}
                        touched={touched.bank_name}
                        errors={errors.bank_name}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <label>Bank City</label>
                        <Input
                        mandatory
                        name="bank_city"
                        label="Bank City"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.bank_city}
                        touched={touched.bank_city}
                        errors={errors.bank_city}
                        />
                    </div>
                </div>
            </div>                   
            {/** row 7 */}
            <div className="row form-row">
                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <label>Bank IFSC Code</label>
                        <Input
                        mandatory
                        name="ifsc_code"
                        label="IFSC Code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.ifsc_code}
                        touched={touched.ifsc_code}
                        errors={errors.ifsc_code}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                <div className="form-group">
                        <label>Account Type<span className="text-danger">*</span></label>
                        <AccountTypes
                            value={values.account_type}
                            onChange={handleChange}
                            onBlur={handleBlur} />
                    </div>
                </div>
            </div>                   
            {/** row 8 */}
            <div className="row form-row">
                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <div className="form-check">
                        <div className="form-check form-check-inline mb-3">
                            <input
                                className="form-check-input"
                                onChange={handleChange}
                                type="checkbox"
                                value="agree"
                                name="iAgree"
                                id="agreeCheckbox" />
                        <label className="form-check-label" htmlFor="agreeCheckbox">Agree to terms and conditions</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="form-group text-right">
                        <ButtonWithSpinner
                        isLoading={isSubmitting}
                        type="submit"
                        disabled={!dirty || !values.iAgree || !isValid || isSubmitting} >
                        Register
                        </ButtonWithSpinner>
                    </div>
                </div>
            </div>

            </form>
        )}
        </Formik>
    )
}

export default connect(mapStateToProps, {})(LabBankDetails);