import React from 'react'
import { useParams, Link } from 'react-router-dom';
function ErrorPage(props) {
    const params = useParams()
    if(!props || !props.status || !props.message){
        props = params
    }
    let { status = 'occurred', message = 'Unknown error occurred' } = props
    if(!status){
        status = 'occurred'
    }
    if (!message && status) {
        switch (status) {
            case 404: message = 'Page not found.'; break;
            default: message = 'Unknown error'
        }
    }

    return (<div className="error-page">

        <div className="fof">
            <h1>Error {status || 'occurred.'}</h1>
            <p>{message || ''}</p>
            <Link className="mt-4" to="/">Home</Link>
        </div>

    </div>)
}

export default ErrorPage;