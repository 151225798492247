import React, { useState, useEffect } from 'react'
import RegisterSection from './RegisterSection/RegisterSection';
import LoginRegisterNav from '../LoginRegisterNav';
import CommonTemplate from '../../Template/CommonTemplate';

function SignupPage(props) {
    const [type,setType] = useState(props?.location?.state?.type ? props.location.state.type : "PATIENT");

    useEffect(() => {
      setType(props?.location?.state?.type ? props.location.state.type : "PATIENT");
    },[props?.location?.state?.type]);

    useEffect(() => {
      console.log(type);
    },[type]);
  
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    return (
        <>
            <CommonTemplate>
                <LoginRegisterNav />
                <RegisterSection type={type} />
            </CommonTemplate>
        </>
    )

}

export default SignupPage;