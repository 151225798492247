import React, { useEffect } from "react";

import MainUserActions from "./MainUserActions/MainUserActions";
import BannerSection from "./BannerSection/BannerSection";
import ClinicAndSpecialities from "./ClinicAndSpecializations/ClinicAndSpecialities";
import PharmaAndLab from "./PharmacyAndLaboratory/PharmaAndLab";
import CommonTemplate from "../Template/CommonTemplate";
import InfoSection from "./InfoSection";
import { useQuery } from '../../common/util/urlParse';

function HomePage() {
  const query = useQuery();
  const enquiry = query.get("enquiry");
  useEffect(() => {
    if(enquiry && window.Beacon){
      window.Beacon("open");
    }
  },[enquiry]);
  return (
    <div className="main-wrapper">
      <CommonTemplate>
        <div className="bg-0 home">
          <BannerSection />
          <PharmaAndLab/>
          <ClinicAndSpecialities />
          <MainUserActions />
          <InfoSection />
        </div>
      </CommonTemplate>
    </div>
  );
}

export default HomePage;
