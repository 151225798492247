import React from 'react';
import img2 from "./laboratrist.jpg";
import './PharmaAndLab.css';

export const PharmaAndLab = () => (
    <div className="bg-1 py-5 home-tile-section">
        <div className="container-fluid  home-width">
            <div className="row">
                <div className="col-12 col-lg-6 mr-auto d-flex">
                    <div
                        className="card w100 mb-3 info-card"
                    >
                        <div className="row no-gutters">
                            <div
                                className="col-12 col-sm-4 card-img-container"
                            >
                                <img
                                    src={process.env.PUBLIC_URL+"/assets/img/pharmacist.jpeg"}
                                    className="card-img"
                                    alt="doc"
                                />
                            </div>
                            <div className="col-12 col-sm-8 card-content-container">
                                <div className="card-body pl-5 pr-5">
                                    <h1 className="card-title text-primary b">250+</h1>
                                    <h3 className="card-title b-500">Pharmacies</h3>
                                    <p className="card-text mb-3">
                                        <a href={process.env.REACT_APP_PHARMALAB_URL + "/auth"} className="btn btn-primary">Login / Signup</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 ml-auto d-flex">
                    <div
                        className="card w100 mb-3 info-card"
                    >
                        <div className="row no-gutters">
                            <div
                                className="col-sm-4 col-12 card-img-container"
                            >
                                <img
                                    src={process.env.PUBLIC_URL+"/assets/img/laboratory.jpeg"}
                                    className="card-img"
                                    alt="doc"
                                />
                            </div>
                            <div className="col-sm-8 col-12 card-content-container">
                                <div className="card-body pl-5 pr-5">
                                    <h1 className="card-title text-primary b">120+</h1>
                                    <h3 className="card-title b-500">Laboratories</h3>
                                    <p className="card-text mb-4">
                                        <a href={process.env.REACT_APP_PHARMALAB_URL + "/auth"} className="btn btn-primary">Login / Signup</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
);

export default PharmaAndLab;