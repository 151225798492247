import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { mobileNumberRegex, gstinRegex } from '../../../../common/util/formUtil';
import { toastSuccess } from '../../../../common/util/toast';
import autoCatch from '../../../../common/util/autoCatch';
import ButtonWithSpinner from '../../../General/Form/ButtonWithSpinner';
import Input from './../../../General/Form/Input';
import pharmacyRegister from '../../../../common/api/pharmacy/pharmacyRegister'
import FileUpload from './../../../General/Form/FileUpload'
import CountryCode from './../../../General/Form/CountryCode';
import { PhoneNumberUtil } from 'google-libphonenumber'
import { useDispatch } from 'react-redux';
import {setPharmacyData}  from '../../../../redux';


const defaultFormValues = {
    pharmacy_name: '',
    email: '',
    gst_number: '',
    country_code: '+91',
    mobile_number: '',
    drug_license_number: '',
    issuing_authority: '',
    date_of_issue: '',
    valid_upto: '',
    upload_dl: '',
}
const FILE_SIZE = 1024 * 1024 * 2;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
   "application/pdf"
];

function daysInMonth(anyDateInMonth) {
    return new Date(anyDateInMonth.getFullYear(), 
                    anyDateInMonth.getMonth()+1, 
                    0).getDate();
                }

export const handleNumber = (input, countryCodeInt) => {

    // Get an instance of `PhoneNumberUtil`.
    const phoneUtil = PhoneNumberUtil.getInstance();
    if (!input || !countryCodeInt) return false;

    try {
        const number = phoneUtil.parseAndKeepRawInput(input, countryCodeInt);
        const isValid = phoneUtil.isValidNumber(number);
        return isValid;
    } catch (e) {
        console.log(e)
    }
    return false;
}

const PharmacyDetails = (props) => {
    const dispatch = useDispatch()
    const [currentCountry, setCurrentCountry] = useState()
    let initialValues = defaultFormValues;

    const validationSchema = Yup.object({
        pharmacy_name: Yup.string()
            .required('pharmacy name field is required.'),
        email: Yup.string()
            .email('The email must be a valid email address.')
            .required('The email field is required.'),
        gst_number: Yup.string()
            .matches(gstinRegex, "Must be a standard gst number.")
            .max(15, 'Must be 15 characters.')
            .required('GSTIN number field is required '),
        drug_license_number: Yup.string()
            .required('The drug license number field is required.'),
        issuing_authority: Yup.string()
            .required('The issuing authority field is required.'),
        country_code: Yup.string()
            .matches(mobileNumberRegex, "Invalid code")
            .required('Country code is required.'),
        mobile_number: Yup.number('Must be a number')
            .nullable()
            .test('mobile-num', 'Invalid mobile number', function (value) {
            if (!value) return false;
            const currentCountrycode = currentCountry && currentCountry.code ? currentCountry.code : null
            return handleNumber(value + "", currentCountrycode || "IN")
        })
            .required('Mobile Number is required.'),
        date_of_issue: Yup.date('Must be a valid date')
            .test('daysInMonth', 'Please enter a valid date',
                value => value && daysInMonth(value))
                    .nullable()
                    .max(new Date(), 'Date of issue cannot be future dates' )
                    .required('The Date of issue field is required.'),
        valid_upto: Yup.date('Must be a valid date')
                .test('daysInMonth', 'Please enter a valid date',
                    value => value && daysInMonth(value))
                    .nullable()
                    .min(new Date(), "Date must be in the future")
                    .required('The Date of validity field is required.'),
        upload_dl:   Yup.mixed()
        .required("DL File is required")
        .test(
          "fileSize",
          "File size is too large",
          value => value && value.size <= FILE_SIZE
        )
        .test(
          "fileFormat",
          "Invalid File Format",
          value => value && SUPPORTED_FORMATS.includes(value.type)
        )
    })

    return (
        <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setErrors, setSubmitting }) => {
            setSubmitting(true)
            let formData = new FormData();
            const {
                pharmacy_name,
                email,
                gst_number,
                country_code,
                mobile_number,
                drug_license_number,
                issuing_authority,
                date_of_issue,
                valid_upto,
                upload_dl 
            } = values
            if (pharmacy_name && email && gst_number && mobile_number && country_code) {
                formData.append('pharmacy_name',  pharmacy_name )
                formData.append('email', email )
                formData.append('gstin',  gst_number )
                formData.append('mobile_number', mobile_number )
                formData.append('country_code', country_code )
            }
            if (drug_license_number && issuing_authority && date_of_issue && valid_upto) {
                formData.append('dl_number', drug_license_number)
                formData.append('dl_issuing_authority', issuing_authority)
                formData.append('dl_date_of_issue', date_of_issue)
                formData.append('dl_valid_upto', valid_upto)
            }
            if (upload_dl) {
                formData.append('dl_file', upload_dl)
            }

            
            autoCatch(pharmacyRegister.pharmacyRegister(formData).then(res => {
                    const step1Id = res.data.data_id
                    dispatch(setPharmacyData(step1Id))
                    if (res.status === 200) {
                        toastSuccess('Registration successful')
                        props.onSubmit(2)
                    }
                    setSubmitting(false)
                }), data => {
                    if (data && data.errors) {
                        setErrors(data.errors)
                    }
                    setSubmitting(false)
                })

        }}
        >{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        setTouched,
        setFieldTouched,
        handleSubmit,
        isSubmitting,
        dirty,
        isValid,
        }) => (
            <form onSubmit={handleSubmit}>
            <h4 className="card-title my-4">Pharmacy Details</h4>
            <h5 className="card-title my-4">Basic Information</h5>
            <div className="row form-row">
                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <label>Pharmacy Name</label>
                        <Input
                        name="pharmacy_name"
                        mandatory
                        label="Pharmacy Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pharmacy_name}
                        touched={touched.pharmacy_name}
                        errors={errors.pharmacy_name}
                        />
                    </div>
                </div>

                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <label>Email id</label>
                        <Input
                        mandatory
                        name="email"
                        label="Email"
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        touched={touched.email}
                        errors={errors.email}
                        />
                    </div>
                </div>
            </div>
            <div className="row form-row">    
                <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                        <label>GSTIN Number</label>
                        <Input
                        mandatory
                        name="gst_number"
                        label="GSTIN Number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.gst_number}
                        touched={touched.gst_number}
                        errors={errors.gst_number}
                        />
                    </div>
                </div>
            
                <div className="col-lg-8 col-md-12">
                    <div className="form-group">
                        <label>Mobile Number <span className="text-danger">&nbsp;*</span></label>
                        <div className="phone-number">
                            <div>
                            <CountryCode
                                value={currentCountry}
                                onChange={setFieldValue}
                                defaultValue="+91"
                                onBlur={setFieldTouched}
                                setOptionValue={value => {
                                    setCurrentCountry(value)
                                                        }}
                                error={errors.country_code}
                                touched={touched.country_code}
                                />
                                {/* <PhoneCode
                                defaultValue="+91"
                                name="country_code"
                                onChange={setFieldValue}
                                value={values.country_code}
                                /> */}
                            </div>
                            <div>
                                <Input
                                name="mobile_number"
                                type="number"
                                label="Mobile Number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.mobile_number}
                                touched={touched.mobile_number}
                                errors={errors.mobile_number}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            {/** row 2 */}
            <h5 className="card-title my-4">Drug License(DL)</h5>
            <div className="row form-row">
                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <label>Drug License Number(DL)</label>
                        <Input
                        mandatory
                        name="drug_license_number"
                        label="Drug License Number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.drug_license_number}
                        touched={touched.drug_license_number}
                        errors={errors.drug_license_number}
                        />
                    </div>
                </div>

                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <label>Issuing Authority</label>
                        <Input
                        mandatory
                        name="issuing_authority"
                        label="Issuing Authority"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.issuing_authority}
                        touched={touched.issuing_authority}
                        errors={errors.issuing_authority}
                        />
                    </div>
                </div>
            </div>
            <div className="row form-row">    
                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                    <label>Date of issue<span className="text-danger">*</span></label>
                        <input
                        type="date"
                        className={"form-control" + (errors.date_of_issue && touched.date_of_issue ? " is-invalid" : "")}
                        name="date_of_issue"
                        onChange={e => {
                            setFieldValue('date_of_issue', e.target.value)
                            handleChange(e);
                        }}
                        onBlur={e => {
                            setFieldValue('date_of_issue', e.target.value)
                            handleBlur(e);
                        }}
                        value={values.date_of_issue}
                        />

                        {errors.valid_upto && (
                        <div style={{ margin: 0, position: "absolute" }} className="invalid-feedback">{errors.date_of_issue}</div>
                        )}
                    </div>
                </div>

                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <label>Drug License Valid Upto<span className="text-danger">*</span></label>
                        <input
                        type="date"
                        className={"form-control" + (errors.valid_upto && touched.valid_upto ? " is-invalid" : "")}
                        name="valid_upto"
                        onChange={e => {
                            setFieldValue('valid_upto', e.target.value)
                            handleChange(e);
                        }}
                        onBlur={e => {
                            setFieldValue('valid_upto', e.target.value)
                            handleBlur(e);
                        }}
                        value={values.valid_upto}
                        />

                        {errors.valid_upto && (
                        <div style={{ margin: 0, position: "absolute" }} className="invalid-feedback">{errors.valid_upto}</div>
                        )}
                    </div>
                </div>
            </div>

            {/** row 3 */}
        
            <div className="row form-row">
                <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                        <label>Upload DL<span className="text-danger">&nbsp;*</span></label>
                        <FileUpload
                            errors={errors.upload_dl}
                            name="upload_dl"
                            onChange={value => {
                                console.log(value)
                            setFieldValue("upload_dl", value)
                            }}
                        onBlur={setTouched}
              />
                    </div>
                </div>
            </div>    
                

            {/** row 5 */}
            
            
            <div className="row form-row">
                <div className="col-12">
                <div className="form-group text-right">
                    <ButtonWithSpinner
                    isLoading={isSubmitting}
                    type="submit"
                    disabled={!dirty || !isValid || isSubmitting} >
                    Next
                    </ButtonWithSpinner>
                </div>
                </div>
            </div>

            </form>
        )}
        </Formik>
    )
}

export default PharmacyDetails