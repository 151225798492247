import {showErrorToast} from '../../redux'
import {store} from '../../redux/store'

function autoCatch(fn, catchFn = () => { }, shouldDispatch = true) {
     

    fn.catch(error => {
        let { response: { data: {message} = {} } = {}, response = {} } = error
        // console.log(response)
        let catchInput
        if(!message){
            message = error 
        }
        if(error && response && response.data){
            catchInput = response.data
        }

        catchFn(catchInput)
        if (shouldDispatch && message) {
            store.dispatch(showErrorToast([message]))
        }
    })
}

export default autoCatch;