import { SET_PHARMACY_DATA } from "./pharmacyTypes";
import { SET_PHARMACY_ADDRESS_DATA } from './pharmacyTypes'

export const setPharmacyData = data =>{
    return {
        type: SET_PHARMACY_DATA,
        payload: data
    }
}
export const setPharmacyAddressData = data =>{
    return {
        type: SET_PHARMACY_ADDRESS_DATA,
        payload: data
    }
}

export default {
    setPharmacyData,
    setPharmacyAddressData
}