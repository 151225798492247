const isShadow = false;

const styles = {
   
    control: (base, state) => ({
        ...base,
        transition: 'none',
        // Overwrittes the different states of border
        border:"1px solid #FFF !important", // : "1px solid #ccc !important",
        // Removes weird border around container
        boxShadow: isShadow && state.isFocused ? "0 0 0 0.2rem rgba(0, 59, 207, 0.2)" : "none",
        
      }),
};

export default styles
