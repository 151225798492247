import React from "react";
import {html_content} from "./policy"
import {about_us_content} from "./aboutus"
import {patient_no_show_content} from "./PatientNoShow"
import {online_consultation_content} from "./OnlineConsultaions"
import {reschedule_policy_content} from "./ReschedulePolicy"

export function PolicyHtmlView () {
    return (
        <div dangerouslySetInnerHTML={{ __html: html_content }} />
    )
};

export function AboutHtmlView () {
    return (
        <div dangerouslySetInnerHTML={{ __html: about_us_content }} />
    )
};

export function PolicyHtml () {
    return (
        <div style={{margin:"40px 200px 40px 200px", display: "inline-block"}}>
        <div dangerouslySetInnerHTML={{ __html: html_content }} />
        </div>
    )
};

export function AboutHtml () {
    return (
        <div style={{margin:"40px 200px 40px 200px", display: "inline-block"}}>
        <div dangerouslySetInnerHTML={{ __html: about_us_content }} />
        </div>
    )
};

export function PatientNoShowPolicyHtml () {
    return (
        <div style={{margin:"40px 200px 40px 200px", display: "inline-block"}}>
        <div dangerouslySetInnerHTML={{ __html: patient_no_show_content }} />
        </div>
    )
};

export function OnlineConsultationHtml () {
    return (
        <div style={{margin:"40px 200px 40px 200px", display: "inline-block"}}>
        <div dangerouslySetInnerHTML={{ __html: online_consultation_content }} />
        </div>
    )
};

export function ReschedulePolicyHtml () {
    return (
        <div style={{margin:"40px 200px 40px 200px", display: "inline-block"}}>
        <div dangerouslySetInnerHTML={{ __html: reschedule_policy_content }} />
        </div>
    )
};