import axios from 'axios';
import { store } from '../../redux/store'
import { logout, showErrorToast } from '../../redux'
// import { useHistory } from 'react-router-dom';

const headers = {
  "Content-Type": "application/json",
  "Accept": "application/json",
};

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers
});

axiosApi.interceptors.request.use(config => {
  const state = store.getState();
  const { auth: {
    token = null,
  } = {}
  } = state

  if (state && token && token.access_token) {
    config.headers.Authorization = `Bearer ${token.access_token}`;
  }

  return config;
})

axiosApi.interceptors.response.use(response => response, error => {
   // network error
   console.log(error)
  if(!error || !error.response || !error.response.status){ 
    store.dispatch(showErrorToast(['Network error. Please check your connection']))
    window.location.href = '/error/Network error. Please check your connection'
    return Promise.reject({response : {data: {message:['Network error. Please check your connection']}}});
  } else if (error.response.status === 429) {
    window.location.href = '/error/429/Too Many Requests'
    return Promise.reject(error);
  }else if (error.response.status === 401) {
    store.dispatch(logout())
    // store.dispatch(showErrorToast(['Please login again']))
  }
  
  return Promise.reject(error);  
})

export default axiosApi