import api from './axios'
import { timezone } from './../util/timezone';

const LOGIN = '/oauth/login'
const LOGOUT = '/oauth/logout'
const ME = "/me";

export const login = ({ username, password }) => {
    return api.post(LOGIN, {
        username,
        password,
        timezone
    })
}

export const logout = () => {
    return api.get(LOGOUT)
}

export const me = () => {
    return api.get(ME)
}

export default {
    login,
    logout,
    me
}