import React from 'react';
import Select from 'react-select';

import { currencyCodeData as options } from '../../../common/util/currencyCodeMapping';

const colourStyles = {
    control: styles => ({ ...styles, lineHeight: "36px" }),
    menu: styles => ({...styles, zIndex:1000})
};

const CurrencyCode = ({
    onChange = () => { },
    onBlur = () => { },
    setOptionValue = () => { },
    value,
    defaultValue = null,
    name: fieldName = 'CurrencyCode',
    disabled: isDisabled = false,
    showValue = false,
    ...props
}) => {

    const handleChange = value => {
        // this is going to call setFieldValue and manually update values.topcis
        onChange(fieldName, value.value);
        setOptionValue(value)
    };

    const handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        onBlur(fieldName, true);
    };
    defaultValue = defaultValue || options[0]
    let val = options[0];
    if (value) {
        val = options.find(item => value === item.value)
    } else if (defaultValue) {
        val = options.find(item => defaultValue === item.value)
    }

    return (
        <div>
            <Select
                id={fieldName}
                options={showValue ? options.map(option => ({
                    ...option,
                    label: `${option.label} (${option.value})`
                })) : options}
                onChange={handleChange}
                onBlur={handleBlur}
                value={val}
                styles={colourStyles}
                defaultValue={val}
                isDisabled={isDisabled}
            />
            {!!props.error &&
                props.touched && (
                    <div style={{ color: 'red', marginTop: '.5rem' }}>{props.error}</div>
                )}
        </div>
    );
}
export default CurrencyCode
