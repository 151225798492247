import React, { useState } from 'react'
import { NavLink,useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Stepper from '@material-ui/core/Stepper';

import './styles.css';
import CommonTemplate from '../../Template/CommonTemplate';
import PharmacyDetails from './PharmacySteps/PharmacyDetails';
import PharmacyAddress from './PharmacySteps/PharmacyAddress';
import PharmacistDetails from './PharmacySteps/PharmacistDetails';
import LabDetails from './LaboratorySteps/LabDetails';
import LabAddress from './LaboratorySteps/LabAddress';
import LabTests from './LaboratorySteps/LabTests';
import LabBankDetails from './LaboratorySteps/LabBankDetails';
import LoginSection from '../LoginPage/LoginSection/LoginSection'

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));

const PharmacyRegister = ({ currentStep = 0 }) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(currentStep); 
    const [userType, setUserType] = useState((new URLSearchParams(useLocation().search)).get("type") || 'PHARMACIST');
    const action = new URLSearchParams(useLocation().search).get("action");
    // const { token } = useSelector(state => state.auth)
    // const pharmacist = token && token.user_type === 'PHARMACIST' ? true : false;
    const steps = (userType === 'PHARMACIST') ? ['Pharmacy Details', ' Pharmacy Address', 'Pharmacist Details']:['Laboratory Details', ' Laboratory Address', 'List of tests', 'Bank Details'];

    const handleStep = (step) => () => {
        setActiveStep(step);
    };
    console.log("userType -> ", userType);
    return (
        <>
            <CommonTemplate>
                <div className="container-fluid mt-3 mb-4">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <ul className="nav justify-content-center tabbed-login">
                                <li className="nav-item">
                                    <NavLink to={"/register?type=" + userType + "&action=login"} className="nav-link" style={action !== 'login' ? {borderBottom:'5px solid #fff'} : {}}>Login</NavLink>
                                </li>
                                <li className="nav-item ml-5">
                                    <NavLink to={"/register?type=" + userType + "&action=register"} className="nav-link" style={action !== 'register' ? { borderBottom: '5px solid #fff' }: {}}>Register</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {
                    action === 'register' ?
                <div className="row">
                    <div className="col-md-4 col-lg-4 login-left">
                        <img src={process.env.PUBLIC_URL+"/assets/img/login-banner.png"} className="img-fluid" alt="Doccure Register" />
                    </div> 
                            <div className="col-md-8 col-lg-8">
                                <Stepper nonLinear activeStep={activeStep}>
                                    {steps.map((label, index) => (
                                        <Step key={label}>
                                            <StepButton
                                                onClick={
                                                    handleStep(index)
                                                }
                                                disabled
                                            >
                                                {label}
                                            </StepButton>
                                        </Step>
                                    ))}
                                </Stepper>
                                <div className="registration-steps">
                                    {userType === 'PHARMACIST' ? (
                                        <>
                                            {activeStep === 0 && <PharmacyDetails onSubmit={handleStep(1)} />}
                                            {activeStep === 1 && <PharmacyAddress onSubmit={handleStep(2)} />}
                                            {activeStep === 2 && <PharmacistDetails />}
                                        </>
                                    ) : (
                                            <>
                                                {activeStep === 0 && <LabDetails onSubmit={handleStep(1)} />}
                                                {activeStep === 1 && <LabAddress onSubmit={handleStep(2)} />}
                                                {activeStep === 2 && <LabTests onSubmit={handleStep(3)} />}
                                                {activeStep === 3 && <LabBankDetails />}
                                            </>
                                        )}
                                </div>
                            </div> 
                    
                        </div> :
                        <LoginSection />
                }   
            </CommonTemplate>
        </>
    )
}    

export default PharmacyRegister