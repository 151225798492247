import { Button, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import { PhoneNumberUtil } from 'google-libphonenumber'
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup'
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import React, { useEffect, useState } from 'react'

import { passwordRegex, nameRegex, mobileNumberRegex } from '../../../../common/util/formUtil';
import { showSuccessToast, showErrorToast, setUserData } from '../../../../redux';
import API from '../../../../common/api'
// import config from '../../config'
import CountryCode from '../../../General/Form/CountryCode';
import Input from '../../../General/Form/Input';
import PasswordInput from '../../../General/Form/PasswordInput';
import { checkUsername } from '../../../../common/api/accountApi';
import { debounce } from '../../../../common/util/debounce';
import TermsAndConditions from "../../../TermsAndConditions/model"
import { countryCodeData as options } from '../../../../common/util/countyCodeMapping';



// Parse number with country code and keep raw input.
export const handleNumber = (input, countryCodeInt) => {

    // Get an instance of `PhoneNumberUtil`.
    const phoneUtil = PhoneNumberUtil.getInstance();
    if (!input || !countryCodeInt) return false;

    try {
        const number = phoneUtil.parseAndKeepRawInput(input, countryCodeInt);
        const isValid = phoneUtil.isValidNumber(number);
        return isValid;
    } catch (e) {
        console.log(e)
    }
    return false;
}
const REDIRECT_IF = ['The email has already been taken.', 'The mobile number has already been taken.']

const initialValues = {
    first_name: '',
    middle_name: '',
    last_name: '',
    password: '',
    password_confirmation: '',
    countryCode: '+91',
    mobile_number: '',
    email: '',
    iAgree: false,
    username: '',
    login_type: "WEB"
}


function RegisterSection(props) {
  
    React.useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const dispatch = useDispatch()
    const location = useLocation()
    const [userType, setUserType] = useState(props.type)
    const [currentCountry, setCurrentCountry] = useState()
    const debounceOnChange = React.useCallback(debounce(isUserValid, 400), []);
    const history = useHistory();

    const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
    const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID

    useEffect(() => {
        setUserType(props.type);
    },[props.type]);

    // console.log(GOOGLE_CLIENT_ID, FACEBOOK_APP_ID);

    function isUserValid(value, setFieldError){
        checkUsername(value).catch(err => {
            let message = 'The username has already been taken.';
            try {
                message = err.response.data.errors.username[0];
            } catch (e) {
                console.error(e)
            }
            setFieldError('username', message)
        })
    }

    const validationSchema = Yup.object({
        first_name: Yup.string()
            .max(50, 'Must be 50 characters or less.')
            .matches(nameRegex, "Only alphabetic characters are accepted.")
            .required('The first name field is required.'),
        middle_name: Yup.string()
            .matches(nameRegex, "Only alphabetic characters are accepted.")
            .max(50, 'Must be 50 characters or less.'),
        last_name: Yup.string()
            .max(50, 'Must be 50 characters or less.')
            .matches(nameRegex, "Only alphabetic characters are accepted.")
            .required('The last name field is required.'),
        username: Yup.string()
            .nullable()
            .max(15, 'Must be 15 characters or less.')
            .required('The username field is required.')
            /* .test('checkDuplUsername', 'The username has already been taken.', function (value) {
                return new Promise((resolve, reject) => {
                    console.log("checkUsername",value)
                    checkUsername(value).then(() => {
                        // exists
                        resolve(true)
                    }).catch(() => {
                        // note exists
                        resolve(false)
                    })
                })
            })*/,
        password: Yup.string()
            .min(8, 'Must be 8 characters or more.')
            .max(20, 'Minimum length of 20 characters.')
            .matches(passwordRegex, "Include at least  one upper case, lower case, numbers and symbols.")
            .required('The password field is required.'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], "Password Doesn't Match")
            .required('Confirm Password field is required.'),
        countryCode: Yup.string()
            .nullable()
            .matches(mobileNumberRegex, "Invalid code")
            .required('Country code is required.'),
        mobile_number: Yup.number('Must be a number')
            .nullable()
            .test('mobile-num', 'Invalid mobile number', function (value) {
                if (!value) return false;
                const currentCountrycode = currentCountry && currentCountry.code ? currentCountry.code : null
                return handleNumber(value + "", currentCountrycode || "IN")
            })
            .required('Mobile Number is required.'),
        email: Yup.string()
            .email('The email must be a valid email address.')
            .required('The email field is required.')

    })

    return (<>
        <div className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <div className="account-content">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-7 col-lg-6 login-left">
                                    <img src={process.env.PUBLIC_URL+"/assets/img/login-banner.png"} className="img-fluid" alt="Doccure Register" />
                                </div>
                                <div className="col-md-12 col-lg-6 login-right mt-2 mb-5">
                                    <div className="login-header">
                                        {userType !== 'DOCTOR' ? (
                                            <h3 className="d-flex justify-content-between align-items-center">Patient Registration <span
                                                className="btn btn-link"
                                                onClick={() => setUserType('DOCTOR')}
                                            >Are you a Doctor?</span></h3>
                                        ) : (
                                                <h3 className="text-danger d-flex justify-content-between align-items-center">Doctor Registration <span
                                                    className="btn btn-link"
                                                    onClick={() => setUserType('PATIENT')}
                                                >Are you a Patient?</span></h3>
                                            )}
                                    </div>

                                    <Formik
                                        initialValues={initialValues}

                                        validationSchema={validationSchema}

                                        onSubmit={(values, { setErrors, setSubmitting }) => {
                                            setSubmitting(true);
                                            sessionStorage.setItem('otp', null)
                                            const inputData = {
                                                first_name: values.first_name,
                                                middle_name: values.middle_name,
                                                last_name: values.last_name,
                                                username: values.username,
                                                password: values.password,
                                                password_confirmation: values.password_confirmation,
                                                country_code: values.countryCode ? values.countryCode : '+91',
                                                mobile_number: values.mobile_number + "",
                                                email: values.email,
                                                user_type: userType,
                                                login_type: values.login_type
                                            }

                                            API.registerUser(inputData).then(res => {

                                                const { email, mobile_number } = res.data;
                                                dispatch(showSuccessToast("Please complete OTP verification."))
                                                setSubmitting(false);
                                                let via = "WEB"
                                                if (values && values.login_type) {
                                                    via = values.login_type
                                                }

                                                const redirectTo = new URLSearchParams(location.search).get('to')
                                                if (redirectTo) {
                                                    history.push({
                                                        pathname: '/signup/confirm',
                                                        search: '?to=' + redirectTo + "&via=" + via,
                                                        state: { email, mobile_number }
                                                    });
                                                } else {
                                                    history.push({
                                                        pathname: '/signup/confirm',
                                                        search: "?via=" + via,
                                                        state: { email, mobile_number }
                                                    });
                                                }
                                            }).catch(error => {
                                                let {
                                                    response: {
                                                        data: {
                                                            message, errors = {}
                                                        } = {}
                                                    } } = error



                                                let errorMessages = [...Object.values(errors)]
                                                if (errorMessages && errorMessages.length > 0) {
                                                    setErrors(errors)
                                                }
                                                // dispatch(showErrorToast([message]))
                                                setSubmitting(false)
                                                console.log(errors, "errors")
                                                // try {
                                                //     if (errors.mobile_number && errors.mobile_number[0] === 'The mobile number has already been taken.') {
                                                //         if (errors.email && errors.email[0] === 'The email has already been taken.') {
                                                //             let via = "WEB"
                                                //             if (values && values.login_type) {
                                                //                 via = values.login_type
                                                //             }
                                                //             sessionStorage.setItem('otp', JSON.stringify(inputData))
                                                //             const redirectTo = new URLSearchParams(location.search).get('to')
                                                //             if (redirectTo) {
                                                //                 history.push('/signup/confirm?step=continue&to=' + redirectTo + "&via=" + via);
                                                //             } else {
                                                //                 history.push("/signup/confirm?step=continue&via=" + via);
                                                //             }
                                                //             return;
                                                //         }
                                                //     }

                                                // } catch (e) {
                                                //     console.error(e)
                                                // }
                                            })


                                        }}
                                    >{({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        setFieldTouched,
                                        setFieldValue,
                                        setFieldError,
                                        isSubmitting,
                                        isValid,
                                        dirty
                                        /* and other goodies */
                                    }) => (<>
                                        <form onSubmit={handleSubmit} autocomplete="off">
                                            <div className="row">
                                                <div className="col">
                                                    <Input
                                                        value={values.username}
                                                        name="username"
                                                        label="Username"
                                                        onChange={e => {
                                                            setFieldTouched('username', true)
                                                            setFieldValue('username', e.target.value || '');
                                                            debounceOnChange(e.target.value, setFieldError)
                                                        }}
                                                        touched={touched.username}
                                                        errors={errors.username}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">

                                                    <Input
                                                        value={values.first_name}
                                                        name="first_name"
                                                        label="First Name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        hasError={touched.first_name && errors.first_name}
                                                        feedback={errors.first_name || 'field is required'}
                                                    />
                                                </div>
                                                <div className="col">

                                                    <Input
                                                        value={values.middle_name}
                                                        name="middle_name"
                                                        label="Middle Name"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        hasError={touched.middle_name && errors.middle_name}
                                                        feedback={errors.middle_name || 'field is required'}
                                                    />

                                                </div>
                                            </div>



                                            <Input
                                                value={values.last_name}
                                                name="last_name"
                                                label="Last Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                hasError={touched.last_name && errors.last_name}
                                                feedback={errors.last_name || 'field is required'}
                                            />

                                            <PasswordInput
                                                value={values.password}

                                                name="password"
                                                label="Password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                hasError={touched.password && errors.password}
                                                feedback={errors.password || 'field is required'}
                                            />

                                            <PasswordInput
                                                value={values.password_confirmation}
                                                name="password_confirmation"
                                                label="Confirm Password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                hasError={touched.password_confirmation && errors.password_confirmation}
                                                feedback={errors.password_confirmation || 'field is required'}
                                            />
                                            <div className="row">
                                                <div className="col-12 col-lg-4 col-md-6">
                                                    {/* <CountryCode
                                                        value={currentCountry}
                                                        onChange={setFieldValue}
                                                        defaultValue="+91"
                                                        onBlur={setFieldTouched}
                                                        setOptionValue={value => {
                                                            setCurrentCountry(value)
                                                        }}
                                                        error={errors.countryCode}
                                                        touched={touched.countryCode}
                                                    /> */}


                                                    {/* 
                                                        <Input
                                                            value={values.countryCode}
                                                            name="countryCode"
                                                            label="Code"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            hasError={touched.countryCode && errors.countryCode}
                                                            feedback={errors.countryCode || 'field is required'}
                                                        />
                                                        */}
                                                        <div>
                                                            <select className='form-control'
                                                            value={currentCountry}
                                                            onChange={setFieldValue}
                                                            defaultValue="+91"
                                                            onBlur={setFieldTouched}
                                                            >
                                                            {
                                                                options.map((option, index) => {
                                                                return <option key={index} value={option.value}>{option.label}</option>
                                                                })
                                                            }
                                                            </select>
                                                        </div>
                                                </div>
                                                <div className="col-12 col-lg-8 col-md-6">

                                                    <Input
                                                        value={values.mobile_number}
                                                        name="mobile_number"
                                                        type="number"
                                                        label="Mobile Number"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        hasError={touched.mobile_number && errors.mobile_number}
                                                        feedback={errors.mobile_number || 'field is required'}
                                                    />

                                                </div>
                                            </div>

                                            <Input
                                                value={values.email}
                                                name="email"
                                                label="Email"
                                                disabled={values.login_type !== 'WEB'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                hasError={touched.email && errors.email}
                                                feedback={errors.email || 'field is required'}
                                            />

                                            <div className="form-check form-check-inline mb-3">
                                                <input
                                                    className="form-check-input"
                                                    onChange={handleChange}
                                                    type="checkbox"
                                                    value="agree"
                                                    name="iAgree"
                                                    id="agreeCheckbox" />
                                                <label className="form-check-label" htmlFor="agreeCheckbox">I agree to the<TermsAndConditions color="blue" /></label>
                                            </div>
                                            <Button
                                                disabled={!dirty || !isValid || !values.iAgree || isSubmitting}
                                                variant="primary"
                                                size="lg"
                                                style={{ height: "52px" }}
                                                block
                                                type="submit"
                                            >
                                                {isSubmitting ? (
                                                    <Spinner animation="border" />
                                                ) : "Register"}
                                            </Button>
                                        </form>


                                        <div className="mt-4 row">
                                            <div className="col-6">
                                                <FacebookLogin
                                                    appId={FACEBOOK_APP_ID}
                                                    cssClass="btn btn-facebook btn-block _40px"
                                                    fields="email,picture,first_name,last_name,middle_name"
                                                    textButton="FACEBOOK"
                                                    callback={(response = {}) => {
                                                        console.log(response);
                                                        if (!response) {
                                                            response = {}
                                                        }
                                                        setFieldValue('email', response.email || '');
                                                        setFieldValue('login_type', response.email ? 'FACEBOOK' : 'WEB');
                                                        setFieldValue('first_name', response.first_name || '');
                                                        setFieldValue('last_name', response.last_name || '');
                                                        setFieldValue('middle_name', response.middle_name || '');

                                                    }} />
                                            </div>
                                            <div className="col-6">
                                                <GoogleLogin
                                                    className="btn-block btn-sm _40px"
                                                    clientId={GOOGLE_CLIENT_ID} //CLIENTID NOT CREATED YET
                                                    buttonText="GOOGLE"
                                                    onSuccess={(response = {}) => {

                                                        const { profileObj: {
                                                            email = '',
                                                            familyName = '',
                                                            givenName = '',
                                                            middle_name = '',
                                                            name = ''
                                                        } } = response
                                                        console.log(response);

                                                        setFieldValue('email', email || '');
                                                        setFieldValue('login_type', 'GOOGLE');
                                                        setFieldValue('first_name', givenName || '');
                                                        setFieldValue('last_name', familyName || '');
                                                        setFieldValue('middle_name', middle_name || '');

                                                    }}
                                                    onFailure={error => {
                                                        console.log(error)
                                                        setFieldValue('login_type', 'WEB');
                                                        showErrorToast(["Error while accessing google"])
                                                    }}
                                                    cookiePolicy={'single_host_origin'}
                                                />
                                            </div>

                                        </div>
                                    </>

                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>)
}

export default RegisterSection;