import { CLEAR, ERROR, SUCCESS, DEFAULT } from "./toastTypes";

const initialState = {
  messages: null,
  type: null, // error
  autoHideDelay: 5000, // in ms
};

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR:
      console.log("Clearing toast messages");
      return {
        ...state,
        messages: null,
        type: null,
      };
    case ERROR:
      return {
        ...state,
        messages: action.payload,
        type: "error",
      };

    case SUCCESS:
      return {
        ...state,
        messages: action.payload,
        type: "success",
      };

    case DEFAULT:
      return {
        ...state,
        messages: action.payload,
        type: "default",
      };
    default:
      return state;
  }
};

export default toastReducer;
