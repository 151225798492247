import { Formik } from "formik";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Spinner, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as Yup from 'yup' 
import React from "react";

import { showErrorToast, login } from "../../../../redux";
import API from "../../../../common/api";
import Input from "../../../General/Form/Input";
import PasswordInput from "../../../General/Form/PasswordInput";

const validationSchema = Yup.object({
  password: Yup.string()
    .nullable()
    .required('The password field is required.'),
  username: Yup.string()
    .nullable()
    .required('The Email/Username field is required.')
})

function LoginSection() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation()
  const redirectTo = new URLSearchParams(location.search).get('to')
  return (
    <div className="content mt-3 mb-4" style={{ minHeight: "250px", padding: "1rem 0" }}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="account-content">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-7 col-lg-6 login-left">
                  <img
                    src={process.env.PUBLIC_URL+"/assets/img/login-banner.png"}
                    className="img-fluid"
                    alt="Doccure Login"
                  />
                </div>
                <div className="col-md-12 col-lg-6 login-right">
                  <div className="login-header">
                    <h3>
                      Login <span>emedicare</span>
                    </h3>
                  </div>
                  <Formik
                    initialValues={{
                      username: "",
                      password: "",
                    }}

                    validationSchema={validationSchema}

                    onSubmit={(
                      { username, password },
                      { setErrors, setSubmitting }
                    ) => {
                      setSubmitting(true);

                      API.login({ username, password })
                        .then((res) => {
                          const { data } = res;
                          dispatch(login(data));
                               
                        if (res.data.user_type === "PHARMACIST"){
                          if (res.data.first_login === 0) {
                          history.push(redirectTo  ? redirectTo : "/pharmacy-lab-reset-password");
                          } else {
                            history.push(redirectTo  ? redirectTo : "/pharmacy-lab-dashboard");
                                }
                          } else if (res.data.user_type === "LABORATORY"){
                          if(res.data.first_login === 0) {
                          history.push(redirectTo ? redirectTo : '/pharmacy-lab-reset-password')
                          } else {
                          history.push(redirectTo ? redirectTo : '/pharmacy-lab-dashboard')
                              }
                          }
                        }).catch(error => {
                          console.log(error.response)
                          const {
                            response: {
                              data: {
                                message, errors = {}
                              } = {}
                            } = {},
                          } = error;

                          let errorMessages = [...Object.values(errors)];
                          if (errorMessages && errorMessages.length > 0) {
                            setErrors(errors);
                          }
                          dispatch(showErrorToast([message]));
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      dirty
                    }) => (<>
                      <form onSubmit={handleSubmit}>
                        <Input
                          value={values.username}
                          name="username"
                          label="Email/Username"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          hasError={touched.username && errors.username}
                          feedback={errors.username}
                        />
                        <PasswordInput
                          value={values.password}
                          name="password"
                          noHint
                          onChange={handleChange}
                          onBlur={handleBlur}
                          hasError={touched.password && errors.password}
                          feedback={errors.password}
                        />
                        {/* <div className="text-right">
                          <NavLink
                            className="forgot-link"
                            to="/forgot-password"
                          >
                            Forgot Password ?
                          </NavLink>
                        </div> */}
                        <Button
                          disabled={!dirty || !isValid || isSubmitting}
                          variant="primary"
                          size="lg"
                          style={{ height: "52px" }}
                          block
                          type="submit"
                        >
                          {isSubmitting ? (
                            <Spinner animation="border" />
                          ) : (
                              "Login"
                            )}
                        </Button>

                      </form>
                      <div className="login-or">
                        <span className="or-line"></span>
                        <span className="span-or">or</span>
                      </div> 
                      <div className="text-center dont-have">
                        Don’t have an account?{" "}
                        <NavLink className="btn-link" to="/register">
                          Register
                          </NavLink>
                      </div>
                    </>
                      )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginSection;
