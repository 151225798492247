import api from '../axios';
import { timezone } from '../../util/timezone';

var qs = require('qs')

const SEARCH_DOCTOR = '/guest/search/doctor'
const SPECIALIZATION_LIST = '/guest/search/specialization'
const DOCTOR_APPOINTMENT_RESCHEDULE = 'doctor/appointments/reschedule';
const PATIENT_APPOINTMENT_RESCHEDULE = 'patient/appointments/reschedule';

export const getDoctorsList = data => {
    return api.get(SEARCH_DOCTOR, {
        params: data,
        paramsSerializer: p => {
            return qs.stringify(p, { arrayFormat: 'brackets' })
        }
    })
}

export const getSpecializationList = data => {
    return api.get(SPECIALIZATION_LIST, {
        params: { specialization: (data || '') }
    })
}


export const getSpecializationData = data => {
    return api.get(`/admin/specialization?paginate=0`)
}

export const getDoctorOverview = id => {
    return api.get(`${SEARCH_DOCTOR}/${id}/overview`)
}
///location 
export const getDoctorLocation = id => {
    return api.get(`${SEARCH_DOCTOR}/${id}/location`,{
        params:{
            timezone
        }
    })
}

export const getDoctorDetails = id => {
    return api.get(`${SEARCH_DOCTOR}/${id}`)
}
//getDoctorBusinesshours

export const getDoctorBusinesshours = id => {
    return api.get(`${SEARCH_DOCTOR}/${id}/businesshours`,{
        params:{
            timezone
        }
    })
}

export const getDoctorBusinessHoursForReschedule = (data) => api.post(DOCTOR_APPOINTMENT_RESCHEDULE, data);
export const getDoctorBusinesshoursForPatientReschedule = (data) => api.post(PATIENT_APPOINTMENT_RESCHEDULE,data)

// api/guest/search/doctor/{id}/businesshours/{day?}/{type?}

export const getDoctorBusinesshoursByDayAndType = (id, params) => {
    return api.get(`${SEARCH_DOCTOR}/${id}/schedule`, {
        params: {
            ...params,
            timezone
        },
        paramsSerializer: p => {
            return qs.stringify(p, { arrayFormat: 'brackets' })
        }
    })  
}

export const getFollowupsTimeslots = (id, date) => api.get("patient/followups/timeslots/" + id + "?date="+ date)

export default {
    getDoctorsList,
    getSpecializationList,
    getDoctorOverview,
    getDoctorDetails,
    getDoctorLocation,
    getDoctorBusinesshours,
    getDoctorBusinesshoursByDayAndType,
    getFollowupsTimeslots
}