import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import './style.css'


export default function AutocompletePlaces(props) {
  const { onChange = () => { }, placeholder = 'Type address', value ='', inputClassName, inputStyle = {}, ...rest } = props
  const [address, setAddress] = React.useState(value);
  // const [addr, setGeoLocation] = React.useState(value);
  const handleChange = value =>{
  
    // geocodeByAddress(value)
    //   .then(results => getLatLng(results[0]))
    //   .then(latLng => setGeoLocation(latLng))
    //   .catch(error => console.error('Error', error));
    //   let object = {'name':value,'latitude':addr.lat,'longitude':addr.lng}
      setAddress(value)
      onChange(value)
  }
  // const handleSelect = value => {
  //   geocodeByAddress(value)
  //     .then(results => getLatLng(results[0]))
  //     .then(latLng => console.log('Success', latLng))
  //     .catch(error => console.error('Error', error));
  // };
  return (
    <div className="input-group-prepend" style={{ position: "relative" }}>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleChange}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>

            <input
              style={inputStyle}
              className={inputClassName?  inputClassName : "form-control"}
              {...getInputProps({ placeholder })} />

            <div className={"suggestions" + ((suggestions && suggestions.length > 0 ) || loading ? "" : "d-none")}>
              {loading ? <div>...loading</div> : null}

              {suggestions.map((suggestion, index) => {
               

                return (
                <div className="suggestion" {...getSuggestionItemProps(suggestion)} key={index + "-"} >
                    {suggestion.description} 
                  </div>
                );
              })}
            </div>
          </>
        )}
      </PlacesAutocomplete>
    </div>
  );
}