export const online_consultation_content = `
<p><strong>Online Consultation&rsquo;s Terms and Conditions</strong></p>
<p>&nbsp;</p>
<p>Fogaat Healthcare Private Limited (&ldquo;<strong>FHPL</strong>&rdquo;) is the service provider of services mentioned below. FHPL under the brand name &lsquo;emedicare&rsquo; operates the said services through https://www.emedicare.in and the mobile application &lsquo;emedicare&rsquo; (together, &ldquo;Website&rdquo;). Reference to (&ldquo;<strong>FHPL</strong>&rdquo;) includes, Fogaat Healthcare Private Limited, its subsidiaries, group companies and their affiliates.</p>
<ol>
<li><strong>NATURE AND APPLICABILITY OF TERMS</strong>:</li>
</ol>
<p>Please carefully go through these terms and conditions (&ldquo;<strong>Terms</strong>&rdquo;) and the privacy policy available at https://www.emedicare.in/company/privacy (&ldquo;<strong>Privacy</strong> <strong>Policy</strong>&rdquo;) before you decide to access the Website or avail the services. These Terms and the Privacy Policy together constitute a legal agreement (&ldquo;<strong>Agreement</strong>&rdquo;) between you and FHPL (on its and its affiliates behalf) in relation to the Services (as defined below).</p>
<p><strong>The Agreement applies to:</strong></p>
<ol>
<li>a medical Practitioner or health care provider (whether an individual professional or an organization) or similar institution providing the services on the emedicare platform, as the case may be (&ldquo;<strong>Doctor</strong>(s)&rdquo;, &ldquo;<strong>you</strong>&rdquo; or &ldquo;<strong>User</strong>&rdquo;); or</li>
<li>A patient, his/her representatives or affiliates, searching for Doctors through the Website (&ldquo;<strong>End</strong>-User&rdquo;, &ldquo;<strong>you</strong>&rdquo; or &ldquo;<strong>User</strong>&rdquo;); or</li>
</ol>
<ul>
<li>Otherwise a user of the Website (&ldquo;<strong>you</strong>&rdquo; or &ldquo;<strong>User</strong>&rdquo;).</li>
</ul>
<p>This Agreement applies to those services provided by FHPL on the Website, (&ldquo;<strong>Services</strong>&rdquo;),</p>
<p>The Services may change from time to time, at the sole discretion of FHPL, and the Agreement will apply to you providing the Service.</p>
<p>FHPL reserves the right to modify or terminate any portion of the Agreement for any reason and at any time, and such modifications shall be informed to you in writing. You should read the Agreement at regular intervals. Your providing of Services following any such modification constitutes your agreement to follow and be bound by the Agreement so modified.</p>
<p>You acknowledge that you will be bound by this Agreement for providing any of the Services offered by us. If you do not agree with any part of the Agreement, please do not use the Website or avail any Services.</p>
<p>The Agreement is published in compliance of, and is governed by the provisions of Indian law, including but not limited to:</p>
<ul>
<li>the Indian Contract Act, 1872,</li>
<li>the (Indian) Information Technology Act, 2000, and</li>
</ul>
<p>&nbsp;</p>
<ol start="2">
<li><strong>ONLINE CONSULTATION (Domestic - India)</strong></li>
<li><strong>Definition:</strong></li>
</ol>
<p>Online Consultation is a Service provided by FHPL that allows Users &amp; Doctors to consult, on paid mode. Further, Users can book online appointment with Doctor/s on our Website/ platform, for the purposes of consultation, to a Doctor. Doctors and Users can use any telecommunication tool/s or messaging app/s agreed mutually or FHPL can suggest specific tool/s in future. The scope of this feature as detailed herein is collectively referred to as "<strong>Online</strong> <strong>Consultation</strong>".</p>
<ol>
<li><strong>Terms for Users:</strong></li>
</ol>
<p>The Users expressly understand, acknowledge and agree to the following set forth herein below:</p>
<ol>
<li>Users can book online appointment with registered Doctor with emedicare platform. The system uses an algorithm/software-program to list the Doctors. User have to use the telecommunication tools/apps suggested by emedicare for Online Consultation.</li>
<li>Prescription will be provided to User by the Doctor on the emedicare Platform , the same is being provided basis the online consultation, however it may vary when examined in person, hence, in no event shall the prescription provided by Doctors be relied upon as a final and conclusive solution.</li>
</ol>
<ul>
<li>The Users agree to use the advice from Doctor on the Website pursuant to:</li>
<li>an ongoing treatment with their medical Doctor;</li>
<li>a condition which does not require emergency treatment, physical examination or medical attention;</li>
<li>medical history available as records with them for reference;</li>
<li>a record of physical examination and report thereof with them, generated through their local medical Doctor;</li>
<li>consultation with their medical Doctor before abandoning or modifying their ongoing treatment.</li>
</ul>
<ol>
<li>The User agrees that by using Online Consultation, the Doctors on Consult will not be conducting physical examination of the Users, hence, they may not have or be able to derive important information that is usually obtained through a physical examination. User acknowledges and agrees that the User is aware of this limitation and agrees to assume the complete risk of this limitation.</li>
<li>The User understands that Consultation shall not form a substitute for treatment that otherwise needs physical examination/immediate consultation. Further, the User understands that the advice provided by the Doctor is based on general medical conditions and practices prevalent in India, to the best of his knowledge and ability, and not for conditions which are territory specific for regions other than India, irrespective of where the User is procuring medical services or engaging in communication with the Doctor.</li>
<li>During the consultation and thereafter, the Doctor may upload the prescription/health records of the User on the account of the User on the Website for access of the User. However, it is expressly clarified that for Users not located within India and using Online Consultation, the Doctor may or may not issue a prescription, at his sole discretion.</li>
</ol>
<ul>
<li>Notwithstanding anything contained herein, FHPL is not in any manner responsible for any drug/medicines prescribed or the therapy prescribed by the Doctor.</li>
<li>emedicare system could trigger communications to the User, in the form of notification/text/email/others. The User further understands that FHPL may send such communications like text messages/email/calls before and/or after Doctor&rsquo;s consultation (physical or online) to User&rsquo;s mobile number, based on the Doctor&rsquo;s settings (through the Website). However, and notwithstanding anything to the contrary in this Agreement, FHPL does not take responsibility for timeliness of such communications.</li>
</ul>
<ol>
<li>The User hereby agrees to FHPL's medical team carrying out an audit of his/her consultations on the Online Consultation platform for the purpose of improving treatment quality, user experience, and other related processes. The User acknowledges that the subject matter of audit may include texts, messages, photographs, reports, audio or video recordings or any other material exchanged between the User and the Doctor which could inter alia include User's personal information, including sensitive personal information. This personal information will be processed in accordance with Privacy Policy.</li>
<li>User shall refrain from raising any personal queries or advice on the Online Consultation platform which are not related to a specific disease / medicine.</li>
<li>Users shall not use abusive language on the Online Consultation platform. In the event of an abuse from the User is reported by a Doctor, FHPL reserves the right to block such Users from the Online Consultation platform and FHPL is not responsible for honouring any refund request towards his/her consultation on the Online Consultation platform.</li>
</ol>
<ul>
<li>Users may share images or videos of the affected areas of their body parts with the Doctor only if it is absolutely necessary for diagnosing his/her condition and if he/she is personally comfortable in sharing such images or videos. FHPL shall not be responsible for any such images or videos shared by the Users with the Doctors.</li>
<li>Users shall ensure that any interaction/communication with the Doctors, including sharing images or videos of the body parts, shall be through the external modes of communication. FHPL will not be held responsible for using any external modes of communication for interacting/communicating with the Doctors.</li>
<li>Users shall be prepared to share all relevant documents or reports to the Doctor promptly upon request.</li>
</ul>
<ol>
<li>For every paid consultation on the Online Consultation platform, the Users shall not obtain consultation for more than one User. In the event, the Users attempt to obtain consultation for more than one User through a single paid consultation on the Online Consultation platform, such consultations will not be addressed by the relevant Doctor.</li>
</ol>
<ul>
<li>Users shall not persuade Doctors to prescribe drugs (including higher dose strength) that do not conform to the emedicare prescription policy. The restricted drugs are as follows:</li>
<li>Medication for Medical Termination Pregnancy (MTP)</li>
<li>Drugs under the following pharmaceutical classifications such as; sedatives, hypnotics, opioids, schedule X drugs, or fourth generation antibiotics.</li>
<li>If restricted drugs are indicated for treatment or management of a disease or condition by a Doctor, the User shall physically visit the Doctor of their choice to confirm the requirements/necessity for prescribing such restricted drugs.</li>
<li>User understands and agrees to provide accurate information and will not use the Online Consultation platform for any acts that are considered to be illegal in nature.</li>
<li>If User decides to engage with a Doctor to procure medical services or engages in communication, exchange of money for services outside of emedicare platform, User shall do so at their own risk. FHPL shall not be responsible for any breach of Service or Service deficiency by any Doctor.</li>
</ul>
<ol>
<li>The User agrees and understands that the transaction with the Doctor are subject to jurisdiction of Indian laws and that any claim, dispute or difference arising from it shall be subject to the jurisdiction provision as contained in the Terms and Conditions hereunder, at all times. The User further agrees and understands that the Doctor is a medical Practitioner &nbsp;who is licensed to practice medicine in India and the onus is on the User to determine if he/she is eligible to consult with the Doctors via the Website. It is expressly clarified that at no point in time can it be construed that the Doctor is practicing medicine in a territory other than India, irrespective of where the User is located and procures medical services or engages in communication with the Doctor, in any manner whatsoever.</li>
</ol>
<ul>
<li>The User shall indemnify and hold harmless FHPL and its affiliates, subsidiaries, directors, officers, employees and agents from and against any and all claims, proceedings, penalties, damages, loss, liability, actions, costs and expenses (including but not limited to court fees and attorney fees) arising due to or in relation to the use of Website by the User, by breach of the Terms or violation of any law, rules or regulations by the User, or due to such other actions, omissions or commissions of the User that gave rise to the claim.</li>
<li>The User shall make payment using the payment gateway to make payments online, solely at User's discretion. Should there be any issues with regard to the payment not reaching the FHPL account, the User may contact FHPL's support team via online chat: https://www.emedicare.in/consult/direct/chat-support.</li>
</ul>
<p>&nbsp;</p>
<ol start="3">
<li><strong>Terms for Doctors:</strong></li>
<li>The Doctor shall communicate using telecommunication tools/ apps suggested by emedicare for communication with Users for Online Appointments. emedicare In case of non-compliance with regard to adhering to the applicable laws/rules/regulations/guidelines by the Doctor, FHPL shall have the right to stop such Doctors for booking of Online Appointments.</li>
<li>The Doctor further understands that, there is a responsibility on the Doctor to treat the User, as the Doctor would have otherwise treated the User on a physical one-on-one consultation model.</li>
</ol>
<ul>
<li>The Doctor has the discretion to cancel any online consultation at any point of time in cases where the Doctor feels, it is beyond his/her expertise or his/her capacity to treat the User. In such cases, it may trigger a refund to the User and the User has the option of choosing other Doctors. However, it is strongly recommended that the Doctor advise the User and explain appropriately for next steps which may include referring the User for further evaluation. emedicare&rsquo;s cancellation policy will be applicable in any case.</li>
</ul>
<ol>
<li>The Doctor is and shall be duly registered, licensed and qualified to practice medicine/ provide health care, wellness services, as per applicable laws/regulations/guidelines set out by competent authorities and the Doctor shall not be part of any arrangement which will prohibit him/her from practicing medicine within the territory of India. The Doctor shall at all times ensure that all the applicable laws that govern the Doctor shall be followed and utmost care shall be taken in terms of the consultation/ services being rendered.</li>
<li>Doctor shall ensure that, the consultation online is treated as an in-clinic consultation and provide advice to the best of Doctors&rsquo; knowledge.</li>
<li>Doctors should provide prescriptions to the Users only via the emedicare prescription module. However, when the Doctor creates an e-prescription, the Doctor will be required to confirm the e-prescription with their electronic signature explicitly or implicitly by clicking on the signature option made available or any other form of opt-in methods as provided therein. The Doctor hereby agrees and covenants to be responsible and liable for the content of e-prescription and the authenticity of his signature signed electronically. In addition to any indemnity warranties provided else-where in the Agreement, the Doctor hereby agrees to hold FHPL, its officers, employees, agents and affiliates harmless from any claims, damages, losses or penalties arising out of any third party claims in connection with the validity of the e-prescription, its content and/or electronic signature.</li>
</ol>
<ul>
<li>For a Doctor to complete a online consultation, it is mandatory to provide a consultation summary via the e-prescription module to all Users. The recommended contents (at least one) of the said consultation summary are as follows:</li>
<li>Summary of presenting illness</li>
<li>Provisional diagnosis</li>
<li>Medicine posology including side effects if any</li>
<li>Diagnostics</li>
<li>Lifestyle changes</li>
<li>Other instructions if any</li>
<li>Referral for physical consultation (if necessary)</li>
<li>Where the Doctor learns that a physical consultation is mandatory for accurate diagnosis and resolution of the case, the Doctor shall mandatorily be required to provide a patient referral for physical evaluation along with required information via the prescription module.</li>
</ul>
<ol>
<li>Doctors shall not prescribe, medical termination pregnancy medication, sedatives, Hypnotics, opioids, schedule X drugs, or fourth generation antibiotics on the Consult platform. If any such drugs are indicated for treatment for a given consultation, the Doctor shall refer the User for a physical consultation.</li>
<li>In the event the Doctor learns about physical abuse, sexual misconduct (especially in minors), or User self-harm (suicide: planned, attempted or completed), the Doctor agrees to report such events to FHPL via the email: medicolegal-team@emedicare.in immediately</li>
<li>The Doctors agrees not to request see for images or video of the User's private body parts in any manner whatsoever until and unless all other options have been exhausted to diagnose the User&rsquo;s condition and it is absolutely necessary to arrive at a probable diagnosis.</li>
</ol>
<ul>
<li>The Doctor acknowledges that should FHPL find the Doctor to be in violation of any of the applicable laws/rules/ regulations/guidelines set out by the authorities then FHPL shall be entitled to cancel the consultation with such Doctor or take such other legal action as may be required.</li>
<li>In case of there being any technical failure, at the time of transaction and there is a problem in making payment, User may contact FHPL's support team via online chat: https://www.emedicare.in/consult/direct/chat-support.</li>
<li>It is further understood by the Doctor that the information that is disclosed by the User at the time of consultation is personal information and is subject to all applicable privacy laws, shall be confidential in nature and subject to User and Doctor privilege.</li>
</ul>
<ol>
<li>The Doctor understands that FHPL makes no promise or guarantee for any uninterrupted communication and the Doctor shall not hold FHPL liable, if for any reason the communication is not delivered to the User(s), or are delivered late or not accessed, despite the efforts undertaken by FHPL</li>
</ol>
<ul>
<li>It shall be the responsibility of the Doctor to ensure that the information provided by User is accurate and not incomplete and understand that FHPL shall not be liable for any errors in the information included in any communication between the Doctor and User.</li>
<li>The Doctor shall indemnify and hold harmless FHPL and its affiliates, subsidiaries, directors, officers, employees and agents from and against any and all claims, proceedings, penalties, damages, loss, liability, actions, costs and expenses (including but not limited to court fees and attorney fees) arising due to the Services provided by Doctor, violation of any law, rules or regulations by the Doctor or due to such other actions, omissions or commissions of the Doctor that gave rise to the claim.</li>
</ul>
<p>&nbsp;</p>
<ol start="4">
<li><strong>Refund policy for Patients:</strong></li>
<li>In the event it is proved that the Doctors have acted in contravention of any applicable laws, FHPL shall provide complete refund to the User, subject to investigation undertaken by FHPL.</li>
<li>If the cancellation is due to the abusive nature of the User, such User shall not be eligible for any refund and FHPL/Doctor shall be entitled to take any legal action, depending upon the gravity of the matter.</li>
</ol>
<ul>
<li>User shall refrain from raising any personal queries or advise on the Online Consultation platform which are not related to a specific disease / medicine. In the event the User raises any such personal queries or advice on the Online Consultation platform, FHPL reserves the right to terminate the consultation of such Users and further, such Users will not be entitled to any refund.</li>
</ul>
<ol>
<li>In case a Doctor does not respond to a paid consultation within ten (10) minutes from the time of starting a Online Consultation, or does not respond for more than fifteen (15) minutes during an active consultation, the User shall have the right to request for a refund and any amounts paid by the User with respect to the such consultations will be refunded.</li>
<li>In case a Doctor does not provide a consultation summary prescription for a particular consultation, then the User shall have the right to request for a refund and any amounts paid by the User with respect to the such consultations will be refunded. Refunds will not be provided if the Doctor has provided a consultation summary prescription to the User.</li>
<li>In case a Doctor is unreasonably abrupt or quick to complete a particular consultation on the Consult platform, then the User shall have the right to request for a refund. FHPL shall provide complete refund to the User, subject to investigation undertaken by FHPL.</li>
</ol>
<ul>
<li>FHPL reserves the right to permanently block Users from Online Consultation in the event FHPL receives multiple cancellation request from such Users for reasons which do not form part of the cancellation policy of FHPL.</li>
<li>Users are allowed a period of three (3) days to flag any consultation as inadequate, and request for a refund. No refund requests shall be considered thereafter.</li>
</ul>
<ol>
<li>Users can request a refund by contacting FHPL&rsquo;s online chat support: https://www.emedicare.in/consult/direct/chat-support</li>
<li>FHPL shall check the details and process the refund where applicable, solely at its discretion. After a refund request is processed, the money will be refunded to the User in seven (7) working days from the day refund has been approved from FHPL.</li>
<li>In the event a User raises any concerns regarding the inappropriateness of a particular consultation, the User agrees that the refund or any other outcome for any such concerns raised by the User will be subject to a detailed review of the said concerns by FHPL as per FHPL&rsquo;s internal policies.</li>
</ol>
<ul>
<li>In all matters related to refund and settlement under this Agreement, FHPL shall decide so at its sole and absolute discretion after detailed review of the matter and taking into account all the involved parties&rsquo; information. The decision of FHPL shall be final in this regard.</li>
</ul>
<p>&nbsp;</p>
<ol start="5">
<li><strong>Express Disclaimers:</strong></li>
<li>Online Consultation is intended for general purposes only and is not meant to be used in emergencies/serious illnesses requiring physical consultation. Further, if the Doctor adjudges that a physical examination would be required and advises &lsquo;in-person consultation&rsquo;, it is the sole responsibility of the User, to book an appointment for physical examination and in-person consultation whether the same is with the Doctor listed on the Website or otherwise. In case of any negligence on the part of the User in acting on the same and the condition of the User deteriorates, FHPL shall not be held liable.</li>
<li>Online Consultation is a service being made available to Users to assist them to obtain consultation from Doctors and does not intend to replace the physical consultation with the Doctor.</li>
<li><strong>CONFIDENTIALITY</strong></li>
</ol>
<p>The Doctor using the Online Consultation, as the case may be, shall have the following confidentiality obligations:</p>
<ol>
<li>Doctor agrees to keep confidential all deliverables and all data, technical, product, business, financial, and other information regarding the business and software programs of FHPL, its affiliates, customers, employees, investors, contractors, vendors and suppliers (the &ldquo;Confidential Information&rdquo;), including but not limited to programming techniques and methods, research and development, computer programs, documentation, marketing plans, customer identity, user engagement models and business methods. Without limiting the generality of the foregoing, Confidential Information includes all information and materials disclosed orally or in any other form, regarding FHPL&rsquo;s and/or its affiliates&rsquo; software products or software product development including, but not limited to, the configuration techniques, data classification techniques, user interface, applications programming interfaces, data modelling and management techniques, data structures, and other information of or relating to FHPL&rsquo;s and/or its affiliates&rsquo; software products or derived from testing or other use thereof.</li>
<li>Doctor shall at all times protect and safeguard the Confidential Information and agrees not to disclose, give, transmit or otherwise convey any Confidential Information, in whole or in part, to any other person.</li>
</ol>
<ul>
<li>Doctor agrees that it will not use any Confidential Information for its own purpose or for the benefit of any third party and shall honor the copyrights and other intellectual property rights of FHPL and will not copy, duplicate, or in any manner reproduce any such copyrighted materials.</li>
</ul>
<ol>
<li>Upon request of FHPL or upon termination of the Agreement, Consultant shall promptly deliver to FHPL any and all documents, notes, or other physical embodiments of or reflecting the Confidential Information (including copies thereof), if any that are in possession or control of the Doctor.</li>
<li>Nothing in the Agreement will be construed as conveying to the Doctor any right, title or interests or copyright in or to any Confidential Information of FHPL; or to convey any license as to use, sell, exploit, copy or further develop any such Confidential Information.</li>
<li>The confidentiality obligations of the Doctor as mentioned herein will survive termination or expiration of the Agreement. FHPL has the right to take such action it deems necessary to protect its rights hereunder, including, without limitation, injunctive relief and any other remedies as may be available at law or equity.</li>
</ol>
<ul>
<li>Any non-disclosure agreement signed between FHPL and the Doctor will remain effective, provided that its duration shall be extended coterminous with the Agreement. If there is a conflict between the confidentiality obligations of the Agreement and any non-disclosure agreement, the most restrictive obligation will prevail.</li>
</ul>
<ol start="4">
<li><strong> RIGHT TO USE LOGOS AND TRADEMARKS</strong></li>
</ol>
<p>FHPL is entitled to use the Doctor&rsquo;s name and logo, including trademarks (collectively, &ldquo;Marks&rdquo;) on the Website and FHPL&rsquo;s marketing materials. FHPL will be permitted to issue press releases of any kind referencing the Doctor and the Marks. However, the Doctor shall seek a written permission from FHPL to participate in any press releases or for using trade names, trademarks, or service marks of FHPL in any of its advertisement, publicity, or promotion.</p>
<p>&nbsp;</p>
<ol start="5">
<li><strong>5</strong>. <strong>TERMINATION</strong></li>
</ol>
<p>FHPL/emedicare (as the context warrants) reserves the right to suspend or terminate services provided through the Website and under this Agreement, with or without notice and to exercise any other remedy available under law, in case of the occurrence of the following events:</p>
<ul>
<li>Doctor breaches any terms and conditions of the Agreement or contravenes applicable laws; and</li>
<li>A third-party reports violation of any of its right as a result of your use of the Services.</li>
</ul>
<ol start="6">
<li><strong>LIMITATION OF LIABILITY</strong></li>
</ol>
<p>In no event, including but not limited to negligence, shall FHPL, or any of its directors, officers, employees, agents or content or service providers, affiliates and group companies (collectively, the &ldquo;Protected Entities&rdquo;) be liable for any direct, indirect, special, incidental, consequential, exemplary or punitive damages arising from, or directly or indirectly related to, the use of, or the inability to use, the Website or the content, materials and functions related thereto, the Services, User&rsquo;s provision of information via the Website, lost business or lost End-Users, even if such Protected Entity has been advised of the possibility of such damages. In no event shall the Protected Entities be liable for:</p>
<ul>
<li>provision of or failure to provide all or any Service by Doctors to End- Users contacted or managed through the Website;</li>
<li>any content posted, transmitted, exchanged or received by or on behalf of any User or other person on or through the Website;</li>
<li>any unauthorized access to or alteration of your transmissions or data; or</li>
<li>any other matter relating to the Website or the Service.</li>
</ul>
<p>In no event shall the total aggregate liability of the Protected Entities to a User for all damages, losses, and causes of action (whether in contract or tort, including, but not limited to, negligence or otherwise) arising from this Agreement or a User&rsquo;s use of the Website or the Services exceed, in the aggregate Rs. 1000/- (Rupees One Thousand Only).</p>
<p>&nbsp;</p>
<ol start="7">
<li><strong>SEVERABILITY</strong></li>
</ol>
<p>If any provision of the Agreement is invalid as per applicable law, held by a court of competent jurisdiction or arbitral tribunal to be unenforceable under applicable law, then such provision shall be excluded from this Agreement and the remainder of the Agreement shall be interpreted as if such provision were so excluded and shall be enforceable in accordance with its terms; provided however that, in such event, the Agreement shall be interpreted so as to give effect, to the greatest extent consistent with and permitted by applicable law, to the meaning and intention of the excluded provision as determined by such court of competent jurisdiction or arbitral tribunal.</p>
<ol start="8">
<li><strong>WAIVER</strong></li>
</ol>
<p>No provision of this Agreement shall be deemed to be waived and no breach excused, unless such waiver or consent shall be in writing and signed by FHPL. Any consent by FHPL to, or a waiver by FHPL of any breach by you, whether expressed or implied, shall not constitute consent to, waiver of, or excuse for any other different or subsequent breach.</p>
<ol start="9">
<li><strong>NO DISPARAGEMENT</strong></li>
</ol>
<p>Notwithstanding anything herein, neither party will make any claims, representations or warranties on behalf of the other party or bind the other party, and neither party is authorized to do so by this Agreement. The relationship between the parties will be that of independent contractors. Nothing contained herein will be construed to imply a joint venture, principal or agent relationship, or other joint relationship, and neither party will have the right, power or authority to bind or create any obligation, express or implied, on behalf of the other party. Doctor shall not make any public statement disparaging the other party&rsquo;s brand, marks, products or services. Each party will retain all right, title and interest in and to its products, services, marks, and all content, information and other materials, and nothing contained in this Agreement will be construed as conferring upon such party, by implication, operation of law or otherwise, any other license or other right.</p>
<ol start="10">
<li><strong>APPLICABLE LAW AND DISPUTE SETTLEMENT</strong></li>
</ol>
<p>The parties agree that this Agreement and any contractual obligation between emedicare and User will be governed by the laws of India.</p>
<p>The courts at Gurugram (HR) shall have exclusive jurisdiction over any disputes arising out of or in relation to this Agreement, User&rsquo;s use of the Website or the Services or the information to which it gives access.</p>
`