import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { SimpleSpinner } from '../../General/CustomSpinner';
import DashboardTemplate from "../../Template/DashboardTemplate";
import AddListHistory from './AddListHistory';
import ListPrescriptions from "./ListPrescriptions";
import ProfileApi from "../../../common/api/doctor/profileApi";
import { Button } from 'react-bootstrap';

const breadcrumbs = [
  {
    url: "/",
    text: "Home",
  },
  {
    url: "/dashboard",
    text: "Doctor",
  },
  {
    url: "/doctor/appointments",
    text: "Appointments",
  },
  {
    url: "/doctor/patient/medical-history",
    text: "Medical history",
  },
];

export default function MedicalHistory(props) {
  const [doctor, setDoctor] = useState([])

  const [loading, setLoading] = useState(true)

  const { patientId, appointmentId } = useParams();

  const backToStoresList = () => props.history.goBack()

  useEffect(() => {
    setLoading(true);
    ProfileApi.getProfile()
      .then(res => {
        setDoctor(res.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, []);

  return (
    <DashboardTemplate
      breadcrumbItems={breadcrumbs}
      title="Medical History"
    >
      <nav className="user-tabs">
        <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
          <li className="text-center" style={{ width: "33%" }}>
            <Link
              disabled
              className="nav-link active"
              to="#"
            >
              Medical History
            </Link>
          </li>
        </ul>
      </nav>
      {
        loading && <div className="p-3"><SimpleSpinner /></div>
      }
      { 
        !loading && 
        <div className="w-100 pt-4 pb-5 pr-3 pl-3">
          <div className="w-100">
          <div style={{
                  position: 'relative',
                  float: 'right',
                }}>
            <Button style={{height:'40px'}} onClick={backToStoresList} variant="primary">Back</Button>
          </div>
            <div className="w-100 pt-2 pb-2">
              <AddListHistory patientId={patientId} appointmentId={appointmentId} type="socialHistory" />
            </div>
            <div className="w-100 pt-2 pb-2">
              <AddListHistory patientId={patientId} appointmentId={appointmentId} type="familyHistory" />
            </div>
            <div className="w-100 pt-2 pb-2">
              <AddListHistory patientId={patientId} appointmentId={appointmentId} type="allergicHistory" />
            </div>
          </div>
          <ListPrescriptions doctor={doctor} patientId={patientId} appointmentId={appointmentId} />
        </div>
      }
    </DashboardTemplate>
  )
}    