const base_url = process.env.REACT_APP_URL
const patient_no_show_url = base_url+"patient-no-show-policy"
const online_consultation_url = base_url+"online-consultaion-policy"
const policy_url = base_url+"policy"

export const html_content = `<h2 style="text-align: center;"><b>emedicare Terms and Conditions</b></h2>
<p><strong>Fogaat Healthcare Private Limited</strong>, on behalf of itself and its affiliates/group companies under the brand "emedicare" (<strong>&ldquo;emedicare.in&rdquo;</strong>), is the author and publisher of the internet resource www.emedicare.in and the mobile application &lsquo;emedicare&rsquo; (together, &ldquo;Website&rdquo;). emedicare owns and operates the services provided through the Website.</p>
<ul>
<li><strong>NATURE AND APPLICABILITY OF TERMS</strong></li>
</ul>
<p>Please carefully go through these terms and conditions&nbsp;<strong>(&ldquo;Terms&rdquo;)</strong>&nbsp;and the privacy policy available at https://www.emedicare.in/company/privacy&nbsp;<strong>(&ldquo;Privacy Policy&rdquo;)</strong>&nbsp;before you decide to access the Website or avail the services made available on the Website by emedicare. These Terms and the Privacy Policy together constitute a legal agreement&nbsp;<strong>(&ldquo;Agreement&rdquo;)</strong>&nbsp;between you and emedicare in connection with your visit to the Website and your use of the Services (as defined below).</p>
<p>The Agreement applies to you whether you are -</p>
<ol>
<li>A medical Doctor or health care provider, pharmacies &amp; laboratories (whether an individual professional or an organization) or similar institution wishing to be listed, or already listed, on the Website, including designated, authorized associates of such Doctors or institutions&nbsp;<strong>(&ldquo;Doctor(s)&rdquo;, &ldquo;you&rdquo; or &ldquo;User&rdquo;);</strong>or</li>
<li>A patient, his/her representatives or affiliates, searching for Doctors, pharmacies &amp; laboratories through the Website&nbsp;<strong>(&ldquo;End-User&rdquo;, &ldquo;you&rdquo; or &ldquo;User&rdquo;);</strong>or</li>
</ol>
<ul>
<li>Otherwise a user of the Website&nbsp;<strong>(&ldquo;you&rdquo; or &ldquo;User&rdquo;)</strong>.</li>
</ul>
<p>This Agreement applies to those services made available by emedicare on the Website, which are offered free of charge to the Users&nbsp;<strong>(&ldquo;Services&rdquo;)</strong>, including the following:</p>
<ol>
<li>For Doctors, pharmacies &amp; laboratories: Listing of Doctors and their profiles and contact details, to be made available to the other Users and visitors to the Website;</li>
<li>For other Users: Facility to (i) create and maintain &lsquo;Health Accounts&rsquo;, (ii) search for Doctors by name, specialty, and geographical area, or any other criteria that may be developed and made available by emedicare, and (iii) to make appointments with Doctors.</li>
</ol>
<p>The Services may change from time to time, at the sole discretion of emedicare, and the Agreement will apply to your visit to and your use of the Website to avail the Service, as well as to all information provided by you on the Website at any given point in time.</p>
<p>This Agreement defines the terms and conditions under which you are allowed to use the Website and describes the manner in which we shall treat your account while you are registered as a member with us. If you have any questions about any part of the Agreement, feel free to contact us at support@emedicare.in.</p>
<p>By downloading or accessing the Website to use the Services, you irrevocably accept all the conditions stipulated in this Agreement, the&nbsp;<a href=${online_consultation_url} target="_blank">Subscription Terms of Service</a>&nbsp;and&nbsp;<a href=${policy_url} target="_blank">Privacy Policy</a>, as available on the Website, and agree to abide by them. This Agreement supersedes all previous oral and written terms and conditions (if any) communicated to you relating to your use of the Website to avail the Services. By availing any Service, you signify your acceptance of the terms of this Agreement.</p>
<p>We reserve the right to modify or terminate any portion of the Agreement for any reason and at any time, and such modifications shall be informed to you in writing You should read the Agreement at regular intervals. Your use of the Website following any such modification constitutes your agreement to follow and be bound by the Agreement so modified.</p>
<p>You acknowledge that you will be bound by this Agreement for availing any of the Services offered by us. If you do not agree with any part of the Agreement, please do not use the Website or avail any Services.</p>
<p>Your access to use of the Website and the Services will be solely at the discretion of emedicare.</p>
<p>The Agreement is published in compliance of, and is governed by the provisions of Indian law, including but not limited to:</p>
<ol>
<li>the Indian Contract Act, 1872,</li>
</ol>
<ul>
<li>the (Indian) Information Technology Act, 2000, and</li>
<li>the rules, regulations, guidelines and clarifications framed there under, including the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (the&nbsp;<strong>&ldquo;SPI Rules&rdquo;</strong>), and the (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011 (the&nbsp;<strong>&ldquo;IG Rules&rdquo;</strong>).</li>
</ul>
<p><strong>2.CONDITIONS OF USE</strong></p>
<p>You must be 18 years of age or older to register, use the Services, or visit or use the Website in any manner. By registering, visiting and using the Website or accepting this Agreement, you represent and warrant to emedicare that you are 18 years of age or older, and that you have the right, authority and capacity to use the Website and the Services available through the Website, and agree to and abide by this Agreement.</p>
<p><strong>3.TERMS OF USE APPLICABLE TO ALL USERS OTHER THAN DOCTORS, PHARMACIES &amp; LABORATORIES </strong></p>
<p>The terms in this Clause 3 are applicable only to Users other than Doctors, Pharmacies &amp; Laboratories.</p>
<p><strong>3.1 END-USER ACCOUNT AND DATA PRIVACY</strong></p>
<p><strong>3.1.1</strong>&nbsp;The terms &ldquo;personal information&rdquo; and &ldquo;sensitive personal data or information&rdquo; are defined under the SPI Rules, and are reproduced in the Privacy Policy.</p>
<p><strong>3.1.2</strong>&nbsp;emedicare may by its Services, collect information relating to the devices through which you access the Website, and anonymous data of your usage. The collected information will be used only for improving the quality of emedicare&rsquo;s services and to build new services.</p>
<p><strong>3.1.3</strong>&nbsp;The Website allows emedicare to have access to registered Users&rsquo; personal email or phone number, for communication purpose so as to provide you a better way of booking appointments and for obtaining feedback in relation to the Doctors, Pharmacies &amp; Laboratories and their practice.</p>
<p><strong>3.1.4</strong>&nbsp;The Privacy Policy sets out,&nbsp;<em>inter-alia</em>:</p>
<p>The type of information collected from Users, including sensitive personal data or information;</p>
<ul>
<li>The purpose, means and modes of usage of such information;</li>
<li>How and to whom emedicare will disclose such information; and,</li>
<li>Other information mandated by the SPI Rules.</li>
</ul>
<p><strong>3.1.5</strong>&nbsp;The User is expected to read and understand the Privacy Policy, so as to ensure that he or she has the knowledge of,&nbsp;<em>inter-alia</em>:</p>
<ul>
<li>the fact that certain information is being collected;</li>
<li>the purpose for which the information is being collected;</li>
<li>the intended recipients of the information;</li>
<li>the nature of collection and retention of the information; and</li>
<li>the name and address of the agency that is collecting the information and the agency that will retain the information; and</li>
<li>the various rights available to such Users in respect of such information.</li>
</ul>
<p><strong>3.1.6</strong>&nbsp;emedicare shall not be responsible in any manner for the authenticity of the personal information or sensitive personal data or information supplied by the User to emedicare or to any other person acting on behalf of emedicare.</p>
<p><strong>3.1.7</strong>&nbsp;The User is responsible for maintaining the confidentiality of the User&rsquo;s account access information and password, if the User is registered on the Website. The User shall be responsible for all usage of the User&rsquo;s account and password, whether or not authorized by the User. The User shall immediately notify emedicare of any actual or suspected unauthorized use of the User&rsquo;s account or password. Although emedicare will not be liable for your losses caused by any unauthorized use of your account, you may be liable for the losses of emedicare or such other parties as the case may be, due to any unauthorized use of your account.</p>
<p><strong>3.1.8 </strong>If a User provides any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or emedicare has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, emedicare has the right to discontinue the Services to the User at its sole discretion.</p>
<p><strong>3.1.9 </strong>emedicare may use such information collected from the Users from time to time for the purposes of debugging customer support related issues.</p>
<p>&nbsp;</p>
<p><strong>3.2 RELEVANCE ALGORITHM</strong></p>
<p>emedicare&rsquo;s relevance algorithm for the Doctors is a fully automated system that lists the Doctors, their profile and information regarding their practice on its Website. These listings of Doctors do not represent any fixed objective ranking or endorsement by emedicare. emedicare will not be liable for any change in the relevance of the Doctors on search results, which may take place from time to time. The listing of Doctors will be based on automated computation of the various factors including inputs made by the Users including their comments and feedback. Such factors may change from time to time, in order to improve the listing algorithm. emedicare in no event will be held responsible for the accuracy and the relevancy of the listing order of the Doctors on the Website.</p>
<p><strong>3.3 LISTING CONTENT AND DISSEMINATING INFORMATION</strong></p>
<ul>
<li><strong>3.1 </strong>emedicare collects, directly or indirectly, and displays on the Website, relevant information regarding the profile and practice of the Doctors listed on the Website, such as their specialization, qualification, fees, location, visiting hours, and similar details. emedicare takes reasonable efforts to ensure that such information is updated at frequent intervals. Although emedicare screens and vets the information and photos submitted by the Doctors, it cannot be held liable for any inaccuracies or incompleteness represented from it, despite such reasonable efforts.</li>
<li><strong>3.2 </strong>The Services provided by emedicare or any of its licensors or service providers are provided on an "as is" and &ldquo;as available&rsquo; basis, and without any warranties or conditions (express or implied, including the implied warranties of merchantability, accuracy, fitness for a particular purpose, title and non-infringement, arising by statute or otherwise in law or from a course of dealing or usage or trade). emedicare does not provide or make any representation, warranty or guarantee, express or implied about the Website or the Services. emedicare does not guarantee the accuracy or completeness of any content or information provided by Users on the Website. To the fullest extent permitted by law, emedicare disclaims all liability arising out of the User&rsquo;s use or reliance upon the Website, the Services, representations and warranties made by other Users, the content or information provided by the Users on the Website, or any opinion or suggestion given or expressed by emedicare or any User in relation to any User or services provided by such User.</li>
<li><strong>3.3 </strong>The Website may be linked to the website of third parties, affiliates and business partners. emedicare has no control over, and not liable or responsible for content, accuracy, validity, reliability, quality of such websites or made available by/through our Website. Inclusion of any link on the Website does not imply that emedicare endorses the linked site. User may use the links and these services at User&rsquo;s own risk.</li>
<li><strong>3.4 </strong>emedicare assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect User&rsquo;s equipment on account of User&rsquo;s access to, use of, or browsing the Website or the downloading of any material, data, text, images, video content, or audio content from the Website. If a User is dissatisfied with the Website, User&rsquo;s sole remedy is to discontinue using the Website.</li>
<li><strong>3.5 </strong>If emedicare determines that you have provided fraudulent, inaccurate, or incomplete information, including through feedback, emedicare reserves the right to immediately suspend your access to the Website or any of your accounts with emedicare and makes such declaration on the website alongside your name/your clinic&rsquo;s name as determined by emedicare for the protection of its business and in the interests of Users. You shall be liable to indemnify emedicare for any losses incurred as a result of your misrepresentations or fraudulent feedback that has adversely affected emedicare or its Users.</li>
</ul>
<p><strong>3.4 BOOK APPOINTMENT AND CALL FACILITY</strong></p>
<p>emedicare enables Users to connect with Doctors through Book facility that allows Users book an appointment through the Website.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p><strong>3.4.1 </strong>emedicare will ensure Users are provided confirmed appointment on the Book facility. However, emedicare has no liability if such an appointment is later cancelled by the Doctor, or the same Doctor is not available for appointment.</p>
<p><strong>3.4.2 </strong>If a User has utilized the telephonic services, emedicare reserves the right to share the information provided by the User with the Doctor and store such information and/or conversation of the User with the Doctor, in accordance with our&nbsp;<a href=${policy_url} target="_blank">Privacy Policy</a>.</p>
<p><strong>3.4.3 </strong>The results of any search Users perform on the Website for Doctors should not be construed as an endorsement by emedicare of any such particular Doctor. If the User decides to engage with a Doctor to seek medical services, the User shall be doing so at his/her own risk.</p>
<p><strong>3.4.4</strong>&nbsp;Without prejudice to the generality of the above, emedicare is not involved in providing any healthcare or medical advice or diagnosis and hence is not responsible for any interactions between User and the Doctor. User understands and agrees that emedicare will not be liable for:</p>
<p>User interactions and associated issues User has with the Doctor;</p>
<ul>
<li>the ability or intent of the Doctor(s) or the lack of it, in fulfilling their obligations towards Users;</li>
<li>any wrong medication or quality of treatment being given by the Doctor(s), or any medical negligence on part of the Doctor(s);</li>
<li>inappropriate treatment, or similar difficulties or any type of inconvenience suffered by the User due to a failure on the part of the Doctor to provide agreed Services;</li>
<li>any misconduct or inappropriate behavior by the Doctor or the Doctor&rsquo;s staff;</li>
<li>cancellation or no show by the Doctor or rescheduling of booked appointment or any variation in the fees charged, provided these have been addressed to as per company policy. &nbsp;</li>
</ul>
<p><strong>3.4.5 </strong>Users are allowed to provide feedback about their experiences with the Doctor, however, the User shall ensure that, the same is provided in accordance with applicable law. User however understands that, emedicare shall not be obliged to act in such manner as may be required to give effect to the content of Users feedback, such as suggestions for delisting of a particular Doctor from the Website.</p>
<p><strong>3.4.6 </strong>In case of a &lsquo;Patient-No-Show (P.N.S)&rsquo; (defined below), where the User does not show-up at the concerned Doctor&rsquo;s clinic: User&rsquo;s account will be temporarily disabled from booking further online appointments on emedicare.in for next four (4) months, in case of, three(3) Valid PNS, as per the&nbsp;<a href=${patient_no_show_url} target="_blank">Patient-No-Show Policy</a>.</p>
<p>Patient- No-Show (P.N.S) for the purposes of these Terms and Conditions, is defined as, any instance where a User, who booked an appointment on the Website using the Book Appointment facility , has not turned up for the appointment without cancelling, rescheduling, or informing the Doctor in advance about the same. When Doctor informs emedicare of the incident or marks a particular appointment as P.N.S. using the emedicare Ray software or emedicare Pro App within five (5) days of the scheduled appointment, an email and SMS (&ldquo;PNS Communication&rdquo;) will be sent to the User to confirm on the incident with reasons. Where the User is not able to establish that the User had a legitimate reason as per Clause 3.4.6(c), for not showing up, emedicare shall be entitled to take actions as under Clause 3.4.6 (a). However Users understand that, actions such as ones mentioned under Clause 3.4.6(a) are included as a deterrent to stop Users from misusing the Website, and the loss of business hours incurred by the Doctor.</p>
<p>Following instances, solely at the discretion of emedicare, would be construed as valid cases of PNS (&ldquo;Valid PNS&rdquo;), in which case the User shall be penalized as per Clause 3.4.6 (a):</p>
<p>User does not reply within seven (7) days, with reasons to PNS Communication, from the date of receipt of such PNS Communication;</p>
<p>In case User responds to the PNS Communication with below reasons:</p>
<p>Forgot the appointment</p>
<p>Chose to visit another Doctor/consulted online;</p>
<p>Busy with other work; or such other reasons (which emedicare at its discretion decides to be a valid reason to not show up).</p>
<p>Where the User has booked a paid appointment and is unable to visit the Doctor, due to such genuine reasons of sickness etc. at the sole discretion of emedicare, pursuant to conducting of investigation, the User shall be provided with a refund of such payment made by User, at the time of booking. However, where cancellation charges have been levied, you would not be entitled to complete refund.</p>
<ul>
<li>emedicare reserves the right to make the final decision in case of a conflict. The total aggregate liability of emedicare with respect to any claims made herein shall be INR 200.</li>
</ul>
<p>&nbsp;</p>
<p><strong>3.4.7 Cancellation, Reschedule and Refund Policy</strong></p>
<ul>
<li>All the details regarding Cancellation, Reschedule and Refund of appointment for the Doctor will be displayed to the User while booking the appointment with Doctor and User will be asked to accept those conditions.</li>
<li>In the event that, the Doctor with whom User has booked a paid appointment via the Website, has not been able to meet the User or Doctor cancelled the appointment, User will need to write to us within five (5) days from the occurrence of such event; in which case, the entire consultation amount as mentioned on the Website will be refunded to the User within the next five (5) to six (6) business days in the original mode of payment done by the User while booking. In case where the User, does not show up for the appointment booked with a Doctor, without cancelling the appointment beforehand, the amount will not be refunded, and treated as under Clause 3.4.6. However, where cancellation charges have been levied (as charged by the Doctor/Practice), you would not be entitled to complete refund even if you have cancelled beforehand.</li>
<li>Users will not be entitled for any refunds in cases where, the Doctor is unable to meet the User at the exact time of the scheduled appointment time and the User is required to wait, irrespective of the fact whether the User is required to wait or choose to not obtain the medical services from the said Doctor.</li>
</ul>
<p><strong>3.5 NO DOCTOR-PATIENT RELATIONSHIP; NOT FOR EMERGENCY USE</strong></p>
<ul>
<li><strong>5.1 </strong>Please note that some of the content, text, data, graphics, images, information, suggestions, guidance, and other material (collectively, &ldquo;Information&rdquo;) that may be available on the Website (including information provided in direct response to your questions or postings) may be provided by individuals in the medical profession. The provision of such Information does not create a licensed medical professional/patient relationship, between emedicare and you and does not constitute an opinion, medical advice, or diagnosis or treatment of any particular condition, but is only provided to assist you with locating appropriate medical care from a qualified Doctor.</li>
<li><strong>5.2 </strong>It is hereby expressly clarified that, the Information that you obtain or receive from emedicare, and its employees, contractors, partners, sponsors, advertisers, licensors or otherwise on the Website is for informational purposes only. We make no guarantees, representations or warranties, whether expressed or implied, with respect to professional qualifications, quality of work, expertise or other information provided on the Website. In no event shall we be liable to you or anyone else for any decision made or action taken by you in reliance on such information.</li>
</ul>
<ul>
<li><strong>3.5.3</strong>The Services are not intended to be a substitute for getting in touch with emergency healthcare. If you are an End-User facing a medical emergency (either on your or a another person&rsquo;s behalf), please contact an ambulance service or hospital directly.</li>
</ul>
<p><strong>&nbsp;</strong></p>
<p><strong>3.6 emedicare HEALTH FEED</strong></p>
<p>These terms &amp; conditions governing emedicare Health feed are applicable to Users (being both end-users/ Doctor). However, it is clarified that the terms and conditions herein applicable only to Doctors and applicable to Users are called out separately, as the context warrants.</p>
<p><strong>3.6.1 </strong>emedicare Health feed is an online content platform available on the Website, wherein Doctors who have created a emedicare profile can login and post health and wellness related content.</p>
<p><strong>3.6.2 </strong>Doctors can use emedicare Health feed by logging in from their health account, creating original content comprising text, audio, video, images, data or any combination of the same&nbsp;<strong>(&ldquo;Content&rdquo;)</strong>, and uploading said Content to emedicare&rsquo;s servers. emedicare will make available to the User a gallery of images licensed by emedicare from a third party stock image provider&nbsp;<strong>(&ldquo;emedicare Gallery&rdquo;)</strong>. The User can upload their own images or choose an image from the emedicare Gallery. emedicare does not provide any warranty as to the ownership of the intellectual property in the emedicare Gallery and the User acknowledges that the User will use the images from the emedicare Gallery at their own risk. emedicare shall post such Content to emedicare Health feed at its own option and subject to these Terms and Conditions. The Content uploaded via emedicare Health feed does not constitute medical advice and may not be construed as such by any person.</p>
<p><strong>3.6.3 </strong>Doctors acknowledges that they are the original authors and creators of any Content uploaded by them via emedicare Health feed and that no Content uploaded by them would constitute infringement of the intellectual property rights of any other person. emedicare reserves the right to remove any Content which it may determine at its own discretion as violating the intellectual property rights of any other person, including but not limited to patent, trademark, copyright or other proprietary rights. Doctor agrees to absolve emedicare from and indemnify emedicare against all claims that may arise as a result of any third party intellectual property right claim that may arise from the Doctor&rsquo;s uploading of any Content on the emedicare Health feed. The Doctor may not use the images in the emedicare Gallery for any purpose other than those directly related to the creation and uploading of Content to emedicare Health feed. The Doctor also agrees to absolve emedicare from and indemnify emedicare against all claims that may arise as a result of any third party intellectual property claim if the Doctor downloads, copies or otherwise utilizes an image from the emedicare Gallery for his/her personal or commercial gain.</p>
<p><strong>3.6.4</strong>Doctor hereby assigns to emedicare, in perpetuity and worldwide, all intellectual property rights in any Content created by the User and uploaded by the User via emedicare Health feed.</p>
<p><strong>3.6.5 </strong>emedicare shall have the right to edit or remove the Content and any comments in such manner as it may deem emedicare Health feed at any time.</p>
<p><strong>3.6.6 </strong>Doctor shall ensure that the Content or any further responses to the Content (including responses to Users) is not harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic or libelous in any manner. Further, Doctor should ensure that the Content is not invasive of any other person&rsquo;s privacy, or otherwise contains any elements that is hateful, racially or ethnically objectionable, disparaging, or otherwise unlawful in any manner whatever. emedicare reserves the right to remove any Content which it may determine at its own discretion is violative of these Terms and Conditions or any law or statute in force at the time. Also, the Doctor agrees to absolve emedicare from and indemnify emedicare against all claims that may arise as a result of any legal claim arising from the nature of the Content posted by the Doctor on emedicare Health Feed.</p>
<p><strong>3.6.7 </strong>Doctor shall ensure that no portion of the Content is violative of any law for the time being in force.</p>
<p><strong>3.6.8 </strong>Doctor shall ensure that the Content is not threatening the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order. Further the Doctor shall ensure that the Content will not cause incitement to the commission of any cognizable offence or prevent investigation of any offence or is insulting to any other nation.</p>
<p><strong>3.6.9 </strong>User may also use emedicare Health feed in order to view original content created by Doctors and to create and upload comments on such Content, where allowed&nbsp;<strong>(&ldquo;User Comment&rdquo;)</strong>.</p>
<p><strong>3.6.10 </strong>User acknowledges that the User Comment reflects the views and opinions of the authors of such Content and do not necessarily reflect the views of emedicare.</p>
<p><strong>3.6.11 </strong>User agrees that the Content they access on emedicare Health feed does not in any way constitute medical advice and that the responsibility for any act or omission by the User arising from the User&rsquo;s interpretation of the Content, is solely attributable to the User. The User agrees to absolve emedicare from and indemnify emedicare against all claims that may arise as a result of the User&rsquo;s actions resulting from the User&rsquo;s viewing of Content on emedicare Health feed.</p>
<p><strong>3.6.12 </strong>User acknowledges that all intellectual property rights in the User Comment on emedicare Health feed vests with emedicare. The User agrees not to infringe upon emedicare&rsquo;s intellectual property by copying or plagiarizing content on emedicare Health feed. emedicare reserves its right to initiate all necessary legal remedies available to them in case of such an infringement by the User. Also, User Comment will be the sole intellectual property of emedicare. The User agrees not to post User Comment that would violate the intellectual property of any third party, including but not limited to patent, trademark, copyright or other proprietary rights. emedicare reserves the right to remove any User Comment which it may determine at its own discretion as violating the intellectual property rights of any third party. The User agrees to absolve emedicare from and indemnify emedicare against all claims that may arise as a result of any third party intellectual property right claim that may arise from the User Comment.</p>
<p><strong>3.6.13 </strong>User shall ensure that the User Comment is not harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic or libelous in any manner. Further, User should ensure that the User Comment is not invasive of any other person&rsquo;s privacy, or otherwise contains any elements that is hateful, racially or ethnically objectionable, disparaging, or otherwise unlawful in any manner whatever. emedicare reserves the right to remove any Content which it may determine at its own discretion is violative of these Terms and Conditions or any law or statute in force at the time Also, the User agrees to absolve emedicare from and indemnify emedicare against all claims that may arise as a result of any legal claim arising from the nature of the User Comment.</p>
<p><strong>3.6.14 </strong>User shall ensure that the User Comment is not threatening the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order. Further the Doctor shall ensure that the User Comment will not cause incitement to the commission of any cognizable offence or prevent investigation of any offence or is insulting to any other nation.</p>
<p>&nbsp;</p>
<p><strong>3.7 CONTENT OWNERSHIP AND COPYRIGHT CONDITIONS OF ACCESS</strong></p>
<p>&nbsp;</p>
<p><strong>3.7.1</strong>The contents listed on the Website are (i) User generated content, or (ii) belong to emedicare. The information that is collected by emedicare directly or indirectly from the End- Users and the Doctors shall belong to emedicare. Copying of the copyrighted content published by emedicare on the Website for any commercial purpose or for the purpose of earning profit will be a violation of copyright and emedicare reserves its rights under applicable law accordingly.</p>
<p><strong>3.7.2</strong>emedicare authorizes the User to view and access the content available on or from the Website solely for ordering, receiving, delivering and communicating only as per this Agreement. The contents of the Website, information, text, graphics, images, logos, button icons, software code, design, and the collection, arrangement and assembly of content on the Website (collectively,&nbsp;<strong>"emedicare Content"</strong>), are the property of emedicare and are protected under copyright, trademark and other laws. User shall not modify the emedicare Content or reproduce, display, publicly perform, distribute, or otherwise use the emedicare Content in any way for any public or commercial purpose or for personal gain.</p>
<p><strong>3.7.3</strong>User shall not access the Services for purposes of monitoring their availability, performance or functionality, or for any other benchmarking or competitive purposes.</p>
<ul>
<li><strong>8 REVIEWS AND FEEDBACK</strong></li>
</ul>
<p>By using this Website, you agree that any information shared by you with emedicare or with any Doctor will be subject to our Privacy Policy.</p>
<p>You are solely responsible for the content that you choose to submit for publication on the Website, including any feedback, ratings, or reviews (&ldquo;Critical Content&rdquo;) relating to Doctors or other healthcare professionals. The role of emedicare in publishing Critical Content is restricted to that of an &lsquo;intermediary&rsquo; under the Information Technology Act, 2000. emedicare disclaims all responsibility with respect to the content of Critical Content, and its role with respect to such content is restricted to its obligations as an &lsquo;intermediary&rsquo; under the said Act. emedicare shall not be liable to pay any consideration to any User for re-publishing any content across any of its platforms.</p>
<p>Your publication of reviews and feedback on the Website is governed by Clause 5 of these Terms. Without prejudice to the detailed terms stated in Clause 5, you hereby agree not to post or publish any content on the Website that (a) infringes any third-party intellectual property or publicity or privacy rights, or (b) violates any applicable law or regulation, including but not limited to the IG Rules and SPI Rules. emedicare, at its sole discretion, may choose not to publish your reviews and feedback, if so required by applicable law, and in accordance with Clause 5 of these Terms. You agree that emedicare may contact you through telephone, email, SMS, or any other electronic means of communication for the purpose of:</p>
<ol>
<li>Obtaining feedback in relation to Website or emedicare&rsquo;s services; and/or</li>
<li>Obtaining feedback in relation to any Doctors listed on the Website; and/or</li>
</ol>
<ul>
<li>Resolving any complaints, information, or queries by Doctors regarding your Critical Content;</li>
</ul>
<p>and you agree to provide your fullest co-operation further to such communication by emedicare. emedicare&rsquo;s Feedback Collection and Fraud Detection Policy, is annexed as the Schedule hereto, and remains subject always to these Terms.</p>
<ul>
<li><strong>9 RECORDS</strong></li>
</ul>
<p>emedicare may provide End-Users with a free facility known as &lsquo;Records&rsquo; on its mobile application &lsquo;emedicare&rsquo;. Information available in your Records is of two types:</p>
<ol>
<li>User-created: Information uploaded by you or information generated during your interaction with emedicare ecosystem, e.g. appointment, medicine order placed by you.</li>
<li>Practice-created: Health Records generated by your interaction with a Doctor who uses &lsquo;emedicare Ray&rsquo; or other Services of emedicare software.</li>
</ol>
<p>The specific terms relating to such Health Account are as below, without prejudice to the rest of these Terms and the Privacy Policy:</p>
<ol start="3">
<li><strong>3.9.1 </strong>Your Records is only created after you have signed up and explicitly accepted these Terms.</li>
<li><strong>3.9.2 </strong>Any Practice created Health Record is provided on an as-is basis at the sole intent, risk and responsibility of the Doctor and emedicare does not validate the said information and makes no representation in connection therewith. You should contact the relevant Doctor in case you wish to point out any discrepancies or add, delete, or modify the Health Record in any manner.</li>
</ol>
<ul>
<li><strong>3.9.3 </strong>The Health Records are provided on an as-is basis. While we strive to maintain the highest levels of service availability, emedicare is not liable for any interruption that may be caused to your access of the Services.</li>
</ul>
<ol start="3">
<li><strong>3.9.4 </strong>The reminder provided by the Records is only a supplementary way of reminding you to perform your activities as prescribed by your Doctor. In the event of any medicine reminders provided by emedicare, you should refer to your prescription before taking any medicines. emedicare is not liable if for any reason reminders are not delivered to you or are delivered late or delivered incorrectly, despite its best efforts. In case you do not wish to receive the reminders, you can switch it off through the emedicare app.</li>
<li><strong>3.9.5 </strong>It is your responsibility to keep your correct mobile number and email ID updated in the Records. The Health Records will be sent to the Records associated with this mobile number and/or email ID. Every time you change any contact information (mobile or email), we will send a confirmation. emedicare is not responsible for any loss or inconvenience caused due to your failure in updating the contact details with emedicare.</li>
<li><strong>3.9.6 </strong>emedicare uses industry&ndash;level security and encryption to your Health Records. However, emedicare does not guarantee to prevent unauthorized access if you lose your login credentials or they are otherwise compromised. In the event you are aware of any unauthorized use or access, you shall immediately inform emedicare of such unauthorized use or access. Please safeguard your login credentials and report any actual suspected breach of account to support@emedicare.in.</li>
</ol>
<ul>
<li><strong>3.9.7 </strong>If you access your dependents&rsquo; Health Records by registering your dependents with your own Records, you are deemed to be responsible for the Health Records of your dependents and all obligations that your dependents would have had, had they maintained their own separate individual Records. You agree that it shall be your sole responsibility to obtain prior consent of your dependent and shall have right to share, upload and publish any sensitive personal information of your dependent. emedicare assumes no responsibility for any claim, dispute or liability arising in this regard, and you shall indemnify emedicare and its officers against any such claim or liability arising out of unauthorized use of such information.</li>
<li><strong>3.9.8 </strong>In case you want to delete your Records, you can do so by contacting our service support team. However only your account and any associated Health Records will be deleted, and your Health Records stored by your Doctors will continue to be stored in their respective accounts.</li>
</ul>
<ol start="3">
<li><strong>3.9.9 </strong>You may lose your &ldquo;User created&rdquo; record, if the data is not synced with the server.</li>
<li><strong>3.9.10 </strong>If the Health Record is unassessed for a stipulated time, you may not be able to access your Health Records due to security reasons.</li>
<li><strong>3.9.11 </strong>emedicare is not liable if for any reason, Health Records are not delivered to you or are delivered late despite its best efforts.</li>
</ol>
<ul>
<li><strong>3.9.12 </strong>The Health Records are shared with the phone numbers that are provided by your Doctor. emedicare is not responsible for adding the Heath Records with incorrect numbers if those incorrect numbers are provided by the Doctor.</li>
<li><strong>3.9.13 </strong>emedicare is not responsible or liable for any content, fact, Health Records, medical deduction or the language used in your Health Records whatsoever. Your Doctor is solely responsible and liable for your Health Records and any information provided to us including but not limited to the content in them.</li>
<li><strong>3.9.14 </strong>emedicare has the ability in its sole discretion to retract Health Records without any prior notice if they are found to be shared incorrectly or inadvertently.</li>
</ul>
<ol start="3">
<li><strong>3.9.15 </strong>emedicare will follow the law of land in case of any constitutional court or jurisdiction mandates to share the Health Records for any reason.</li>
</ol>
<ul>
<li><strong>3.9.16 </strong>You agree and acknowledge that emedicare may need to access the Health Record for cases such as any technical or operational issue of the End User in access or ownership of the Records.</li>
<li><strong>9.17 </strong>You acknowledge that the Doctors you are visiting may engage emedicare's software or third party software for the purposes of the functioning of the Doctor&rsquo;s business and emedicare's services including but not limited to the usage and for storage of Records (as defined in Section 3.10) in India and outside India, in accordance with the applicable laws.</li>
<li><strong>9.18 </strong>To the extent that your Records have been shared with emedicare or stored on any of the emedicare products used by Doctor&rsquo;s you are visiting, and may in the past have visited, You hereby agree to the storage of your Records by emedicare pertaining to such previously visited clinics and hospitals who have tie ups with emedicare for the purposes of their business and for emedicare's services including but not limited to the usage and for storage of Records (as defined in Section 3.10) in India and outside India, in accordance with the applicable laws and further agree, upon creation of your account with emedicare, to the mapping of such Records as may be available in emedicare&rsquo;s database to your User account.</li>
</ul>
<ul>
<li><strong>10 </strong>emedicare MEDICINE INFORMATION</li>
</ul>
<p>For detailed terms and conditions regarding medicine information here.</p>
<ul>
<li><strong>11 </strong>emedicare DIAGNOSTICS</li>
</ul>
<p>For detailed terms and conditions regarding diagnostics here.</p>
<ul>
<li><strong>12 </strong>ONLINE CONSULTATION</li>
</ul>
<p>Click here to view Online Consultation terms &amp; conditions.&nbsp;</p>
<p>&nbsp;</p>
<ol start="4">
<li><strong>TERMS OF USE DOCTORS</strong></li>
</ol>
<p>The terms in this Clause 4 are applicable only to Doctors.</p>
<p><strong>4.1</strong>&nbsp;<strong>LISTING POLICY</strong></p>
<ul>
<li><strong>1.1 </strong>emedicare, directly and indirectly, collects information regarding the Doctors&rsquo; profiles, contact details, and practice. emedicare reserves the right to take down any Doctor&rsquo;s profile as well as the right to display the profile of the Doctors, with or without notice to the concerned Doctor. This information is collected for the purpose of facilitating interaction with the End-Users and other Users. If any information displayed on the Website in connection with you and your profile is found to be incorrect, you are required to inform emedicare immediately to enable emedicare to make the necessary amendments.</li>
<li><strong>1.2 </strong>emedicare shall not be liable and responsible for the ranking of the Doctors on external websites and search engines</li>
<li><strong>1.3 </strong>emedicare shall not be responsible or liable in any manner to the Users for any losses, damage, injuries or expenses incurred by the Users as a result of any disclosures or publications made by emedicare, where the User has expressly or implicitly consented to the making of disclosures or publications by emedicare. If the User had revoked such consent under the terms of the Privacy Policy, then emedicare shall not be responsible or liable in any manner to the User for any losses, damage, injuries or expenses incurred by the User as a result of any disclosures made by emedicare prior to its actual receipt of such revocation.</li>
<li><strong>1.4 </strong>emedicare reserves the right to moderate the suggestions made by the Doctors through feedback and the right to remove any abusive or inappropriate or promotional content added on the Website. However, emedicare shall not be liable if any inactive, inaccurate, fraudulent, or non- existent profiles of Doctors are added to the Website.</li>
<li><strong>1.5 </strong>Doctors explicitly agree that emedicare reserves the right to publish the content provided by Doctors to a third party including content platforms.</li>
<li><strong>1.6 </strong>When you are listed on emedicare.in, End-Users may see a &lsquo;show number&rsquo; option. When End-Users choose this option, they choose to call your number through a free telephony service provided by emedicare, and the records of such calls are recorded and stored in emedicare&rsquo;s servers. Such call will have an IVR message stating the purpose of recording your calls and your consent to such recordings which are dealt with as per the Privacy Policy. Such records may be accessed by emedicare for quality control and support related purposes and are dealt with only in accordance with the terms of the Privacy Policy. Such call facility provided to End-Users and to you by emedicare should be used only for appointment and booking purposes, and not for consultation on health-related issues. emedicare accepts no liability if the call facility is not used in accordance with the foregoing. In case you choose to not provide your consent to recording your calls that may have personal information required for appointment and booking purposes, emedicare reserves the right to not provide the Services for which such personal information is sought.</li>
<li><strong>1.7 </strong>You as a Doctor hereby represent and warrant that you will use the Services in accordance with applicable law. Any contravention of applicable law as a result of your use of these Services is your sole responsibility, and emedicare accepts no liability for the same.</li>
</ul>
<p>&nbsp;</p>
<ul>
<li><strong>2 PROFILE OWNERSHIP AND EDITING RIGHTS</strong></li>
</ul>
<p>emedicare ensures easy access to the Doctors by providing a tool to update your profile information. emedicare reserves the right of ownership of all the Doctor&rsquo;s profile and photographs and to moderate the changes or updates requested by Doctors. However, emedicare takes the independent decision whether to publish or reject the requests submitted for the respective changes or updates. You hereby represent and warrant that you are fully entitled under law to upload all content uploaded by you as part of your profile or otherwise while using emedicare&rsquo;s services, and that no such content breaches any third party rights, including intellectual property rights. Upon becoming aware of a breach of the foregoing representation, emedicare may modify or delete parts of your profile information at its sole discretion with or without notice to you.</p>
<ul>
<li><strong>3 REVIEWS AND FEEDBACK DISPLAY RIGHTS OF emedicare</strong>
<ul>
<li><strong>3.1 </strong>All Critical Content is content created by the Users of www.emedicare.in&nbsp;<strong>(&ldquo;Website&rdquo;)</strong>and the clients of emedicare customers and Doctors, including the End-Users. As a platform, emedicare does not take responsibility for Critical Content and its role with respect to Critical Content is restricted to that of an &lsquo;intermediary&rsquo; under the Information Technology Act, 2000. The role of emedicare and other legal rights and obligations relating to the Critical Content are further detailed in Clauses 3.9 and 5 of these Terms. emedicare&rsquo;s Feedback Collection and Fraud Detection Policy, is annexed as the Schedule hereto, and remains subject always to these Terms.</li>
<li><strong>3.2 </strong>emedicare reserves the right to collect feedback and Critical Content for all the Doctors, Clinics and Healthcare Providers listed on the Website.</li>
<li><strong>3.3 </strong>emedicare shall have no obligation to pre-screen, review, flag, filter, modify, refuse or remove any or all Critical Content from any Service, except as required by applicable law.</li>
<li><strong>3.4 </strong>You understand that by using the Services you may be exposed to Critical Content or other content that you may find offensive or objectionable. emedicare shall not be liable for any effect on Doctor&rsquo;s business due to Critical Content of a negative nature. In these respects, you may use the Service at your own risk. emedicare however, as an &lsquo;intermediary, takes steps as required to comply with applicable law as regards the publication of Critical Content. The legal rights and obligations with respect to Critical Content and any other information sought to be published by Users are further detailed in Clauses 3.9 and 5 of these Terms.</li>
<li><strong>3.5 </strong>emedicare will take down information under standards consistent with applicable law, and shall in no circumstances be liable or responsible for Critical Content, which has been created by the Users. The principles set out in relation to third party content in the terms of Service for the Website shall be applicable mutatis mutandis in relation to Critical Content posted on the Website.</li>
<li><strong>3.6 </strong>If emedicare determines that you have provided inaccurate information or enabled fraudulent feedback, emedicare reserves the right to immediately suspend any of your accounts with emedicare and makes such declaration on the website alongside your name/your clinics name as determined by emedicare for the protection of its business and in the interests of Users.</li>
</ul>
</li>
<li><strong>4 RELEVANCE ALGORITHM</strong></li>
</ul>
<p>emedicare has designed the relevance algorithm in the best interest of the End-User and may adjust the relevance algorithm from time to time to improve the quality of the results given to the patients. It is a pure merit driven, proprietary algorithm which cannot be altered for specific Doctors. emedicare shall not be liable for any effect on the Doctor&rsquo;s business interests due to the change in the Relevance Algorithm.</p>
<ul>
<li><strong>5 INDEPENDENT SERVICES</strong></li>
</ul>
<p>Your use of each Service confers upon you only the rights and obligations relating to such Service, and not to any other service that may be provided by emedicare.</p>
<ul>
<li><strong>6 emedicare REACH RIGHTS</strong></li>
</ul>
<p>emedicare reserves the rights to display sponsored ads on the Website. These ads would be marked as &ldquo;Sponsored ads&rdquo;. Without prejudice to the status of other content, emedicare will not be liable for the accuracy of information or the claims made in the Sponsored ads. emedicare does not encourage the Users to visit the Sponsored ads page or to avail any services from them. emedicare will not be liable for the services of the providers of the Sponsored ads.</p>
<p>You represent and warrant that you will use these Services in accordance with applicable law. Any contravention of applicable law as a result of your use of these Services is your sole responsibility, and emedicare accepts no liability for the same.</p>
<ul>
<li><strong>7 emedicare MEDICINE INFORMATION</strong></li>
</ul>
<p>For detailed terms and conditions regarding medicine information here.</p>
<ul>
<li><strong>8</strong><strong>BOOK APPOINTMENT AND CALL FACILITY</strong>
<ul>
<li><strong>8.1 </strong>As a valuable partner on our platform we want to ensure that the Doctors experience on the emedicare booking platform is beneficial to both, Doctors and their Users.<br /> For all terms and conditions of Book facility on emedicare profile check&nbsp;<a href=${online_consultation_url} target="_blank">Book Standards&nbsp;</a>.</li>
<li><strong>8.2 </strong>Doctor understands that, emedicare shall not be liable, under any event, for any comments or feedback given by any of the Users in relation to the Services provided by Doctor. The option of publishing or modifying or moderating or masking (where required by law or norm etc.) the feedback provided by Users shall be solely at the discretion of emedicare.</li>
</ul>
</li>
<li><strong>9</strong> <strong>DOCTOR UNDERTAKING</strong></li>
</ul>
<p>The Doctor is and shall be duly registered, licensed and qualified to practice medicine/ provide health care, wellness services, as per applicable laws/regulations/guidelines set out by competent authorities and the Doctor shall not be part of any arrangement which will prohibit him/her from practicing medicine within the territory of India. The Doctor shall at all times ensure that all the applicable laws that govern the Doctor shall be followed and utmost care shall be taken in terms of the consultation/ services being rendered.</p>
<ul>
<li><strong>10</strong><strong>USAGE IN PROMOTIONAL &amp; MARKETING MATERIALS</strong></li>
</ul>
<p>In recognition of the various offerings and services provided by emedicare to Doctor, Doctor shall (subject to its reasonable right to review and approve): (a) allow emedicare to include a brief description of the services provided to Doctor in emedicare&rsquo;s marketing, promotional and advertising materials; (b) allow emedicare to make reference to Doctor in case studies, and related marketing materials; (c) serve as a reference to emedicare&rsquo;s existing and potential clients; (d) provide video logs, testimonials, e-mailers, banners, interviews to the news media and provide quotes for press releases; (e) make presentations at conferences; and/or (f) use the Doctor&rsquo;s name and/or logo, brand images, tag lines etc., within product literature, e-mailers, press releases, social media and other advertising, marketing and promotional materials.</p>
<ol start="5">
<li><strong> RIGHTS AND OBLIGATIONS RELATING TO CONTENT</strong>
<ul>
<li><strong>1 </strong>As mandated by Regulation 3(2) of the IG Rules, emedicare hereby informs Users that they are not permitted to host, display, upload, modify, publish, transmit, update or share any information that:</li>
<li>belongs to another person and to which the User does not have any right to;</li>
<li>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</li>
<li>harm minors in any way;</li>
<li>infringes any patent, trademark, copyright or other proprietary rights;</li>
<li>violates any law for the time being in force;</li>
<li>deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</li>
<li>impersonate another person;</li>
<li>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;</li>
<li>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</li>
</ul>
</li>
</ol>
<ul>
<li><strong>2 </strong>Users are also prohibited from:
<ul>
<li>violating or attempting to violate the integrity or security of the Website or any emedicare Content;</li>
<li>transmitting any information (including job posts, messages and hyperlinks) on or through the Website that is disruptive or competitive to the provision of Services by emedicare;</li>
<li>intentionally submitting on the Website any incomplete, false or inaccurate information;</li>
<li>making any unsolicited communications to other Users;</li>
<li>using any engine, software, tool, agent or other device or mechanism (such as spiders, robots, avatars or intelligent agents) to navigate or search the Website;</li>
<li>attempting to decipher, decompile, disassemble or reverse engineer any part of the Website;</li>
<li>copying or duplicating in any manner any of the emedicare Content or other information available from the Website;</li>
<li>framing or hot linking or deep linking any emedicare Content.</li>
<li>circumventing or disabling any digital rights management, usage rules, or other security features of the Software.</li>
</ul>
</li>
<li><strong>3 </strong>emedicare, upon obtaining knowledge by itself or been brought to actual knowledge by an affected person in writing or through email signed with electronic signature about any such information as mentioned above, shall be entitled to disable such information that is in contravention of Clauses 5.1 and 5.2. emedicare shall also be entitled to preserve such information and associated records for at least 90 (ninety) days for production to governmental authorities for investigation purposes.</li>
<li><strong>4 </strong>In case of non-compliance with any applicable laws, rules or regulations, or the Agreement (including the Privacy Policy) by a User, emedicare has the right to immediately terminate the access or usage rights of the User to the Website and Services and to remove non-compliant information from the Website.</li>
<li><strong>5 </strong>emedicare may disclose or transfer User-generated information to its affiliates or governmental authorities in such manner as permitted or required by applicable law, and you hereby consent to such transfer. The SPI Rules only permit emedicare to transfer sensitive personal data or information including any information, to any other body corporate or a person in India, or located in any other country, that ensures the same level of data protection that is adhered to by emedicare as provided for under the SPI Rules, only if such transfer is necessary for the performance of the lawful contract between emedicare or any person on its behalf and the User or where the User has consented to data transfer.</li>
<li>emedicare respects the intellectual property rights of others and we do not hold any responsibility for any violations of any intellectual property rights</li>
</ul>
<ul>
<li><strong>TERMINATION</strong>
<ul>
<li><strong>1</strong>emedicare reserves the right to suspend or terminate a User&rsquo;s access to the Website and the Services with or without notice and to exercise any other remedy available under law, in cases where,
<ul>
<li>Such User breaches any terms and conditions of the Agreement;</li>
<li>A third party reports violation of any of its right as a result of your use of the Services;</li>
<li>emedicare is unable to verify or authenticate any information provide to emedicare by a User;</li>
<li>emedicare has reasonable grounds for suspecting any illegal, fraudulent or abusive activity on part of such User; or</li>
<li>emedicare believes in its sole discretion that User&rsquo;s actions may cause legal liability for such User, other Users or for emedicare or are contrary to the interests of the Website.</li>
</ul>
</li>
<li><strong>2 </strong>Once temporarily suspended, indefinitely suspended or terminated, the User may not continue to use the Website under the same account, a different account or re-register under a new account. On termination of an account due to the reasons mentioned herein, such User shall no longer have access to data, messages, files and other material kept on the Website by such User. The User shall ensure that he/she/it has continuous backup of any medical services the User has rendered in order to comply with the User&rsquo;s record keeping process and practices.</li>
</ul>
</li>
</ul>
<ol start="7">
<li><strong> LIMITATION OF LIABILITY</strong></li>
</ol>
<p>In no event, including but not limited to negligence, shall emedicare, or any of its directors, officers, employees, agents or content or service providers (collectively, the &ldquo;Protected Entities&rdquo;) be liable for any direct, indirect, special, incidental, consequential, exemplary or punitive damages arising from, or directly or indirectly related to, the use of, or the inability to use, the Website or the content, materials and functions related thereto, the Services, User&rsquo;s provision of information via the Website, lost business or lost End-Users, even if such Protected Entity has been advised of the possibility of such damages. In no event shall the Protected Entities be liable for:</p>
<ol>
<li>provision of or failure to provide all or any service by Doctors to End- Users contacted or managed through the Website;</li>
<li>any content posted, transmitted, exchanged or received by or on behalf of any User or other person on or through the Website;</li>
</ol>
<ul>
<li>any unauthorized access to or alteration of your transmissions or data; or</li>
</ul>
<ol>
<li>any other matter relating to the Website or the Service.</li>
</ol>
<p>In no event shall the total aggregate liability of the Protected Entities to a User for all damages, losses, and causes of action (whether in contract or tort, including, but not limited to, negligence or otherwise) arising from this Agreement or a User&rsquo;s use of the Website or the Services exceed, in the aggregate Rs. 1000/- (Rupees One Thousand Only).</p>
<ol start="8">
<li><strong> RETENTION AND REMOVAL</strong></li>
</ol>
<p>emedicare may retain such information collected from Users from its Website or Services for as long as necessary, depending on the type of information; purpose, means and modes of usage of such information; and according to the SPI Rules. Computer web server logs may be preserved as long as administratively necessary.</p>
<ol start="9">
<li><strong> APPLICABLE LAW AND DISPUTE SETTLEMENT</strong>
<ul>
<li><strong>1 </strong>You agree that this Agreement and any contractual obligation between emedicare and User will be governed by the laws of India.</li>
<li><strong>2 </strong>Any dispute, claim or controversy arising out of or relating to this Agreement, including the determination of the scope or applicability of this Agreement to arbitrate, or your use of the Website or the Services or information to which it gives access, shall be determined by arbitration in India, before a sole arbitrator appointed by emedicare. Arbitration shall be conducted in accordance with the Arbitration and Conciliation Act, 1996. The seat of such arbitration shall be Gurgaon (HR). All proceedings of such arbitration, including, without limitation, any awards, shall be in the English language. The award shall be final and binding on the parties to the dispute.</li>
<li><strong>3 </strong>Subject to the above Clause 9.2, the courts at Bengaluru shall have exclusive jurisdiction over any disputes arising out of or in relation to this Agreement, your use of the Website or the Services or the information to which it gives access.</li>
</ul>
</li>
</ol>
<ul>
<li><strong>CONTACT INFORMATION GRIEVANCE OFFICER</strong>
<ul>
<li><strong>1 </strong>If a User has any questions concerning emedicare, the Website, this Agreement, the Services, or anything related to any of the foregoing, emedicare customer support can be reached at the following email address: <a href="mailto:support@emedicare.in">mailto:support@emedicare.in</a> or via the contact information available from the following hyperlink:&nbsp;<a href="https://www.emedicare.in"  target="_blank">www.emedicare.in</a></li>
<li><strong>2 </strong>In accordance with the Information Technology Act, 2000, and the rules made there under, if you have any grievance with respect to the Website or the service, including any discrepancies and grievances with respect to processing of information, you can contact our Grievance Officer at:</li>
</ul>
</li>
</ul>
<p>Name: Sandeep Fogaat<br /> Fogaat Healthcare Pvt Ltd<br /> T18-601, Emaar Gurgaon Greens,<br /> Sector 102, Gurgaon (HR) - 122505.<br /> Phone:&nbsp;9999075725<br /> Email:&nbsp;<a href="mailto:support@emedicare.in">support@emedicare.in</a></p>
<p>In the event you suffer as a result of access or usage of our Website by any person in violation of Rule 3 of the IG Rules, please address your grievance to the above person.</p>
<ol start="11">
<li><strong> SEVERABILITY</strong></li>
</ol>
<p>If any provision of the Agreement is held by a court of competent jurisdiction or arbitral tribunal to be unenforceable under applicable law, then such provision shall be excluded from this Agreement and the remainder of the Agreement shall be interpreted as if such provision were so excluded and shall be enforceable in accordance with its terms; provided however that, in such event, the Agreement shall be interpreted so as to give effect, to the greatest extent consistent with and permitted by applicable law, to the meaning and intention of the excluded provision as determined by such court of competent jurisdiction or arbitral tribunal.</p>
<ol start="12">
<li><strong> WAIVER</strong></li>
</ol>
<p>No provision of this Agreement shall be deemed to be waived and no breach excused, unless such waiver or consent shall be in writing and signed by emedicare. Any consent by emedicare to, or a waiver by emedicare of any breach by you, whether expressed or implied, shall not constitute consent to, waiver of, or excuse for any other different or subsequent breach.</p>`