import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";

import toastReducer from "./toast/toastReducers";
import authReducer from "./auth/authReducer";
import userReducer from "./user/userReducers";
import meReducer from "./me/meReducer";
import pharmacyReducer from './pharmacy/pharmacyReducer'
import labReducer from './laboratory/labReducer'
import fillAppointmentReducer from './Doctor/Appointment/fillAppointmentReducer'

const presistConfig = {
    key : 'root',
    storage,
    whitelist: ['auth']
}
 
const rootReducer = combineReducers({
  auth: authReducer,
  toast: toastReducer,
  user: userReducer,
  me: meReducer,
  pharmacyId: pharmacyReducer,
  labId: labReducer,
  doctorFillAppointment: fillAppointmentReducer
});

export default persistReducer(presistConfig,rootReducer);
