import api from '../axios'

const LIST_PROFILE = '/doctor/profile'
const ADD_PROFILE = '/doctor/profile'
const DELETE_PROFILE = '/doctor/profile'
const EDIT_PROFILE = '/doctor/profile'

export const getProfile = () => {
    return api.get(LIST_PROFILE)
}

export const addProfile = data => {
    return api.post(ADD_PROFILE,data)
}

export const deleteProfile = id =>{
    return api.delete(`${DELETE_PROFILE}/${id}`)
}
export const editProfile = data =>{
    return api.post(EDIT_PROFILE,data)
}

export default {
    getProfile,
    addProfile,
    deleteProfile,
    editProfile,
}