const  fillAppointmentReducer = (state = null, action) => {
    switch (action.type) {
        case 'DOCTOR.PRESCRIPTION.SET_PATIENT_ID':
            return {
                ...state,
                patient : action.payload
            }

        default:
            return state
    }
}

export default fillAppointmentReducer;