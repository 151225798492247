import React from "react";
import Marker from "./Marker";

export default function GoogleMap(props) {
 
  const setPosition = (position) => {
    props.onChange(props.name || "position", position);
  };

    const { position = {} } = props;
    const defaultPosition = {
      lat: 8.4998965,
      lng: 76.8541262,
    };
    return (
      <div className="w-100 position-relative" style={{ ...props.styles }}>
        <Marker
          position={{ ...defaultPosition, ...position }}
          styles={{ ...props.styles }}
          getCurrentLocation={!props.position}
          onChange={updatedPosition => setPosition(updatedPosition)}
        />
      </div>
    );
}
