import React from 'react';
import { Spinner, Button } from 'react-bootstrap';

const ButtonWithSpinner = ({ isLoading = false, size = "md", varient='primary', disabled=false, children, ...rest}) => {
    return (
        <Button disabled={disabled || isLoading} varient={varient} size={size} {...rest} >
            {isLoading ? (
                <Spinner animation="border" size={size === 'lg' ? 'lg': 'sm'} />
            ) : children}
        </Button>
    );
}

export default ButtonWithSpinner;