import { showErrorToast, showSuccessToast, showToast } from '../../redux'
import { store } from '../../redux/store'

export const toast = (message, type) =>{
    if(!message){
        return;
    }
    let toastType;
    switch(type){
        case 'success' : toastType = showSuccessToast([message]); break;
        case 'error' : toastType = showErrorToast([message]); break;
        default : toastType = showToast([message])
    }
    store.dispatch(toastType)
}

export const toastError = message =>{
    toast(message,'error')
}
export const toastSuccess = message =>{
    toast(message,'success')
}
