import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

function ListLink(props) {
    const { to, exact } = props

    let isActive = props.location &&  props.location.pathname === props.to ? true : false;
    const navProps = {
        to,
        exact
    }

    return (
        <li className={isActive ? "active" : ""}>
            <NavLink {...navProps} >
                {props.children}
            </NavLink>
        </li>
    );
}

export default withRouter(ListLink);