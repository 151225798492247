import React, { useState, useEffect } from "react";
import MedicalHistoryAPI from '../../../common/api/doctor/medicalHistoryApi';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import {Search} from '../../../common/util/Search'

const ListPrescriptions = ({patientId,appointmentId,doctor}) => {
    const [prescriptionHistory, setPrescriptionHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [mount, setMount] = useState(true);
    const [search_text, set_search_text] = useState("");
    const [date, set_date] = useState("");

    const loadPrescriptionHistory = (filter) => {
        setLoading(true);
        MedicalHistoryAPI.getPrescriptionHistory(patientId, appointmentId, filter)
            .then(res => {
                setPrescriptionHistory(res.data.data)
                setLoading(false);
            })
            .catch(error => {
                console.log(error.response.data);
                setLoading(false);
            });
    }

    const searchData = (text) => {
            const filter = {
                date : date,
                name : text,
            }
            loadPrescriptionHistory(filter)
    }
    
    useEffect(() => {
        if (mount) {
            const filter = {
                date : '',
                name : '',
            }
            loadPrescriptionHistory(filter)
            setMount(false)
        }
    },[loadPrescriptionHistory])
    return (
        <div className="w-100 pt-3 pb-3">
            <h5 className="mb-4">View Prescriptions</h5>
            <div className=" row" style={{marginBottom: '20px'}}>
          <div className="col-lg-6">
            <Search 
            label="Doctor Name"
            onSearch={searchData}
            onChange={set_search_text}
            value={search_text}
             />
          </div>

          {<div className="col-lg-6">
              <div className="col text-right">
                    <TextField
                    style={{
                        borderRadius: '100px',
                        width: '200px',
                      }}
                      variant="outlined"
                      margin="dense"
                      id="date"
                      label="Date"
                      type="date"
                      value={date}
                    onChange={e => {
                      if (new Date("9999-12-31") >= new Date(e.target.value)) {
                        set_date(e.target.value)
                        const filter = {
                            date : e.target.value,
                            name : search_text,
                        }
                        loadPrescriptionHistory(filter)
                      }
                    }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    </div>
          </div>}
        </div>
            {
                patientId && doctor &&
                <table className="table table-condensed">
                    <thead>
                        <tr>
                            <th> ID </th>
                            <th> Date </th>
                            <th> Doctor Name </th>
                            <th> Diagnosis </th>
                            <th> Prescription </th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && prescriptionHistory.length > 0 && prescriptionHistory.map((item, index) => (
                            <tr key={index}>
                                <td> 
                                    {item.unique_id} 
                                </td>
                                <td> 
                                    {moment(item.appointment.date).format("MMM DD, YYYY")} 
                                </td>
                                <td> 
                                    Dr. {[
                                        item.appointment.doctor.first_name,
                                        item.appointment.doctor.middle_name,
                                        item.appointment.doctor.last_name
                                    ].filter(name => name).join(" ")}
                                </td>
                                <td> 
                                    {item.info.diagnosis} 
                                </td>
                                <td>
                                    {
                                        item.pdf_url ? 
                                        <a target="_blank" href={item.pdf_url} className="pr-3 pl-3 text-danger">
                                            <i className="fas fa-file-pdf" style={{ fontSize: "25px" }}></i>
                                        </a> : 
                                        <i className="fas fa-file-pdf text-danger pr-3 pl-3 cursor-pointer" style={{ fontSize: "25px" }} onClick={() => alert("Prescription not found")}></i>
                                    }
                                </td>
                            </tr>
                        ))}
                        {!loading && prescriptionHistory.length === 0 && 
                            <tr>
                                <td className="text-center" colSpan="6">No data found</td>
                            </tr>
                        }
                    </tbody>
                </table>
            }
        </div>
    );
}

export default ListPrescriptions;