export const patient_no_show_content = `
<p><strong>Patient No-Show Policy</strong></p>
<p>Patients not showing up for doctor appointments is a widespread phenomenon in India. This can have serious consequences, affecting the Doctors schedule and can also affect others patients who are in need of medical services.</p>
<p>Our Patient No-Show policy has been put in place to ensure timely healthcare for everyone using the emedicare platform. In the emedicare context, Patient No-Show means a patient hasn&rsquo;t turned up on time for an appointment booked through emedicare.in or emedicare App without cancelling, rescheduling, or informing the doctor in advance about the same. Every Doctor has provided the facility to define his/her own Cancellation and Rescheduling period on emedicare Platform or the emedicare Cancellation and Rescheduling policy and same has been displayed to the Patient user and accepted while booking the appointment.</p>
<p><strong>Capturing patient-no-show from doctors/ clinics</strong></p>
<p>When a patient doesn&rsquo;t show up for a confirmed appointment (without having cancelled or rescheduled before appointment time as per Cancelation &amp; Reschedule Policy), doctors using emedicare software can mark the specific appointment as PNS on emedicare platform with comment box facility.</p>
<p>PNS can be marked only after 2 hours the scheduled appointment date and time.</p>
<p><strong>Verifying PNS from patients</strong></p>
<p>Whenever a doctor/clinic marks PNS, patients will be informed via an SMS and email to verify this claim and understand the reason behind the missed appointment. Patients should respond in next 7 days after receiving the SMS/email, with the reason on his Dashboard. A comment box facility can be provided for this where patient, doctor and admin can add their comments. Admin will check reply and will mark the PNS as &ldquo;Valid&rdquo; or &ldquo;Not Valid&rdquo;.</p>
<p><strong>Actions taken on repeat patient no-show</strong></p>
<p>Every PNS captured on emedicare is dealt with seriousness based on past patient behavior. This is done in order to ensure patients understand the importance of a doctor&rsquo;s time and not misuse the convenience provided by emedicare. Our PNS Policy aims at minimizing instances of uninformed missed appointments, thereby making most of a doctor&rsquo;s valuable time.</p>
<ol>
<li>Repeat PNS actions are taken ONLY for patients who schedule appointments using &lsquo;<strong>Book Appointment</strong>&rsquo; feature on emedicare.</li>
<li>At the&nbsp;<strong>3<sup>rd</sup> valid instance</strong>of PNS in last 12 months, patient&rsquo;s account will be temporarily disabled from booking further online appointments on emedicare.in for next 4 months. Admin can enable user for booking appointment at any time.</li>
</ol>
<p><strong><u>Note:- PNS count should be maintained under user profile and should be recorded in user details DB file. There should be a separate DB file for blacklisted users. Any user type i.e. Patient, Doctor, Pharmacy, Health Associate can be marked as &ldquo;Blacklisted&rdquo; by Admin.&nbsp; </u></strong></p>
<ol start="3">
<li>For below cases Admin will mark the PNS as &ldquo;<strong>Valid</strong>&rdquo;:</li>
<li>Patient does not provide response with the reason in next 7 days from the date of receipt of such email/SMS communication.</li>
<li>Patient responds to the email/SMS with below reasons:
<ul>
<li>Forgot the appointment</li>
<li>Chose to visit another doctor/consulted online</li>
<li>Busy with other work</li>
<li>Other reasons (which emedicare at its discretion decides to be a reason for valid PNS)</li>
</ul>
</li>
</ol>
<ol start="4">
<li>Below are the some cases where Admin will mark the PNS as &ldquo;<strong>Not Valid</strong>&rdquo;:</li>
</ol>
<ol>
<li>Patient says she/he got marked as PNS in spite of visiting the practice/doctor. This will be marked valid/invalid only after emedicare support team validates the authenticity of the claim.</li>
<li>Patient says she/he was extremely ill to visit the doctor (This will be a one-time consideration only)</li>
<li>If a patient has a history of repeat PNS, patient&rsquo;s account can be temporarily disabled from booking further online appointments on emedicare.in, even on 1st or 2nd instance of PNS.</li>
<li>All refunds with respect to PNS for a pre-paid appointment shall be at the discretion of the doctor and emedicare has no role with respect to such refunds.</li>
<li>emedicare reserves the right to make the final decision in the case of a conflict. The total aggregate liability of emedicare with respect to any claims made herein shall be INR 200.</li>
<li>emedicare reserve the right to modify the above mentioned terms from time to time.</li>
</ol>
<p>&nbsp;</p>
<p>&nbsp;</p>
`