import React from 'react'
import API from "../../common/api";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap';

function Footer() {
  const [me, set_me] = React.useState(false);
  const [mount, setMount] = React.useState(true);
  const userRoles = useSelector(state => state.auth && state.auth.token && state.auth.token.roles ? state.auth.token.roles : []);

  React.useEffect(() => {
    if (mount) {
      API.me()
      .then(response => {
          set_me(response.data)
      })
      .catch(error => console.log(error.response.data))
        setMount(false)
      }
    })

    return (
        <footer className="footer">
            {/* Footer Top */}
            <div className="footer-top">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-5 col-md-6">
                    {/* Footer Widget */}
                    <div className="footer-widget footer-about">
                      <div 
                        className="w-100 mb-2"
                      >
                        <img src={process.env.PUBLIC_URL+"/assets/img/shadow-logo.png"} alt="logo" style={{width: "40%", height: "auto", marginLeft: "-15px"}} />
                      </div>
                      <div className="footer-about-content">
                        <p>
                        emedicare provides you health services at affordable prices and makes it accessible to everyone. Our aim is to empowering our users with online booking Doctors appointments, purchase online medicines, diagnostic and maintain your health records. Whether you choose to interact with emedicare online, on the phone, or through other channels, our goal is to make sure you get best services and information to make better healthcare decisions about you and your family health.
                        </p>
                      </div>
                    </div>
                    {/* /Footer Widget */}
                  </div>
                  <div className="col-lg-2 col-md-6">
                    {/* Footer Widget */}
                    <div className="footer-widget footer-menu">
                      <h2 className="footer-title">For Patients</h2>
                      <ul>
                        <li><Link to="/login" onClick={() => window.scrollTo(0, 0)}>Login</Link></li>
                        <li>
                          <Link 
                            to={{
                              pathname: "/signup",
                              state: { type: 'PATIENT'}
                            }}  
                            onClick={() => window.scrollTo(0, 0)}
                          >Register</Link>
                        </li>
                        {
                          userRoles.includes("patient") && (
                            <>
                            <li><Link to="/appointments" onClick={() => window.scrollTo(0, 0)}>Appointments</Link></li>
                            <li><Link to="/dashboard" onClick={() => window.scrollTo(0, 0)}>Patient Dashboard</Link></li>
                          </>
                          ) 
                        }
                      </ul>
                    </div>
                    {/* /Footer Widget */}
                  </div>
                  <div className="col-lg-2 col-md-6">
                    {/* Footer Widget */}
                    <div className="footer-widget footer-menu">
                      <h2 className="footer-title">For Doctors</h2>
                      <ul>
                        <li><Link to="/login" onClick={() => window.scrollTo(0, 0)}>Login</Link></li>
                        <li>
                          <Link 
                            to={{
                              pathname: "/signup",
                              state: { type: 'DOCTOR'},
                            }} 
                            onClick={() => window.scrollTo(0, 0)}
                          >Register</Link>
                        </li>
                        {
                          userRoles.includes("doctor") && (
                            <>
                            <li><Link to="/doctor/appointments/todayAppointments" onClick={() => window.scrollTo(0, 0)}>Appointments</Link></li>
                            <li><Link to="/dashboard" onClick={() => window.scrollTo(0, 0)}>Doctor Dashboard</Link></li>
                          </>
                          ) 
                        }
                      </ul>
                    </div>
                    {/* /Footer Widget */}
                  </div>
                  <div className="col-lg-3 col-md-6">
                    {/* Footer Widget */}
                    <div className="footer-widget footer-contact">
                      <h2 className="footer-title">Contact Us</h2>
                      <div className="footer-contact-info">
                        <div className="footer-address">
                          <span><i className="fas fa-map-marker-alt" /></span>
                          <p> Emaar Gurgaon Greens,<br />  Sector 102, Gurugram </p>
                        </div>
                        <p>
                          <i className="fas fa-phone-alt" />
                          01247174372
                        </p>
                        <p>
                          <i className="fas fa-phone-alt" />
                          99999075725
                        </p>
                        <p className="mb-0">
                          <i className="fas fa-envelope" />
                          support@emedicare.in
                        </p>
                      </div>
                    </div>
                    {/* /Footer Widget */}
                  </div>
                </div>
              </div>
            </div>
            {/* /Footer Top */}
            {/* Footer Bottom */}
            <div className="footer-bottom">
              <div className="container-fluid">
                <div className="copyright">
                  <div className="row">
                    <div className="col-md-4 col-lg-4">
                      <div className="copyright-text">
                       <p className="mb-0">© {new Date().getFullYear()} emedicare. All rights reserved.</p>
                      </div>
                    </div>
                    <div className="col-md-8 col-lg-8">
                      {/* Copyright Menu */}
                      <div className="copyright-menu">
                        <ul className="policy-menu">
                          <li><Link to="/about-us">About Us</Link></li>
                          <li><Link to="/terms-and-conditions">Terms And Conditions</Link></li>
                          <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                        </ul>
                      </div>
                      {/* /Copyright Menu */}
                    </div>
                  </div>
                </div>
                {/* /Copyright */}
              </div>
            </div>
            {/* /Footer Bottom */}
        </footer>
    )
}

export default Footer
