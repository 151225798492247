import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { getCode } from '../../../../common/api/googleApi'
import { HomeDelivery } from '../../../General/Form/SepcificSelect';
import CurrencyCode from '../../../General/Form/CurrencyCode'
import { toastSuccess } from '../../../../common/util/toast';
import autoCatch from '../../../../common/util/autoCatch';
import ButtonWithSpinner from '../../../General/Form/ButtonWithSpinner';
import Input from './../../../General/Form/Input';
import laboratoryRegister from '../../../../common/api/laboratory/laboratoryRegister';
import GoogleMap from "./../../../General/GoogleMaps";
import { connect } from "react-redux"; 
import { useDispatch } from 'react-redux';
import { setLabAddressData } from '../../../../redux'



const initialValues = {
    pincode: '',
    street_name: '',
    city_village: '',
    district: '',
    state: '',
    country: '',
    home_delivery: 'NO',
    amount: ''
  }

const mapStateToProps = state => {
    const { labId } = state;
    return { labId };
  };

const LabAddress = (props) => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch()

    const pincode = Yup.number().min(100000, "Must be 6 digit").max(999999, "Must be 6 digit").nullable()

    const validationSchema = Yup.object({
      pincode: pincode
          .required('Pincode field is required'),
      street_name: Yup.string()
          .required('Street/Area field is required'),
      city_village: Yup.string()
          .required('City/village field is required'),
      district: Yup.string()
          .required('District field is required'),
      state: Yup.string()
          .required('State field is required'),
      country: Yup.string()
          .required('Country field is required'),
      home_delivery: Yup.string()
        .required('Sample collection field field is required'),
      amount: Yup.number().when('home_delivery', {
          is: 'YES',
          then: Yup.number('enter a valid amount').required('Amount is required')
        }),

  })

    return (
        <>
         <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
            setSubmitting(true);
            let home_delivery = values.home_delivery === 'YES' ? 1 : 0;
            let inputData = {
              ...values,
              data_id: props.labId.id,
              latitude: values.position.lat,
              longitude: values.position.lng,
              order_amount: values.amount,
              sample_collection: home_delivery,
            }
            delete inputData.amount;
            delete inputData.position;
            delete inputData.home_delivery;

            autoCatch(laboratoryRegister.laboratoryAddress(inputData).then(res => {
            dispatch(setLabAddressData(res.data))
            if (res.status === 200) {
                toastSuccess('Registration successful')
                props.onSubmit(3)
            }
            setLoading(true);
            setSubmitting(false);
            }), data => {
            if (data && data.errors) {
                setErrors(data.errors)
            }
            setSubmitting(false);
            })

        }}
        >{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        dirty,
        isValid,
        }) => (
            <form onSubmit={handleSubmit}>
            <h4 className="card-title my-4">Laboratory Address</h4>
            <div className="row form-row">
                <div className="col-lg-4 col-md-12">
                <div className="form-group">
                <label>Pincode</label>
                    <Input
                      value={values.pincode}
                      onChange={handleChange}
                      onBlur={e => {
                        console.log(e)
                        const val = e.target.value
                        // setFieldTouched('pincode', true);
                        if (!val) return;
                        getCode(val, addr => {
                          if (!addr) {
                            return
                          }
                          console.log(addr, "Addr")
                          const {
                            country = '',
                            district = '',
                            state = '',
                            pincode = ''
                          } = addr
                          if (pincode) {
                            setFieldValue('country', country)
                            setFieldValue('state', state)
                            setFieldValue('district', district)
                            setFieldValue('pincode', pincode);
                          }

                        })

                      }}
                      type="number"
                      name="pincode"
                      touched={touched.pincode}
                      errors={errors.pincode}
                      required
                      label="Pincode" />
                </div>
                </div>
                <div className="col-lg-4 col-md-12">
                <div className="form-group">
                <label>Country</label>
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country}
                      required
                      hasError={touched.country && errors.country}
                      feedback={errors.country}
                      type="text"
                      name="country"
                      label="Country" />
                </div>
                </div>
                <div className="col-lg-4 col-md-12">
                <div className="form-group">
                <label>State</label>
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                      hasError={touched.state && errors.state}
                      feedback={errors.state}
                      required
                      type="text"
                      name="state"
                      label="State" />
                </div>
                </div>
            </div>

            
            <div className="row form-row">
                <div className="col-lg-4 col-md-12">
                <div className="form-group">
                <label>District</label>
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      value={values.district}
                      hasError={touched.district && errors.district}
                      feedback={errors.district}
                      type="text"
                      name="district"
                      label="District" />
                </div>
                </div>
                <div className="col-lg-4 col-md-12">
                <div className="form-group">
                <label>City/Village </label>
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city_village}
                      hasError={touched.city_village && errors.city_village}
                      feedback={errors.city_village}
                      type="text"
                      required
                      name="city_village"
                      label="City/Village" />   

                </div>
                </div>

                <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                        <label>Street/Area </label>
                        <Input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.street_name}
                        hasError={touched.street_name && errors.street_name}
                        feedback={errors.street_name}
                        type="text"
                        required
                        name="street_name"
                        label="Street/Area" />
                    </div>
                </div>
                <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <label>Latitude</label>
                      <Input
                        label="Latitude"
                        type="number"
                        disabled
                        name="latitude"
                        value={
                          values.position && values.position.lat
                            ? values.position.lat
                            : ""
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>Longitude</label>
                      <Input
                        label="Longitude"
                        type="number"
                        disabled
                        name="longitude"
                        value={
                          values.position && values.position.lng
                            ? values.position.lng
                            : ""
                        }
                      />
                    </div>
                    <div className="form-group">
                        <label>Sample Collection from home <span className="text-danger">&nbsp;*</span></label>           
                        <HomeDelivery
                        value={values.home_delivery}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                    </div>
                    {values.home_delivery === 'YES' &&(
                        <div className="form-group">
                            <label>Minimum value of sample collection from home. 
                            <span className="text-danger">&nbsp;*</span></label>
                            <div className="phone-number">
                              <div>
                              <CurrencyCode
                                defaultValue="INR"
                                name="currency_code"
                                onChange={setFieldValue}
                                value={values.currency_code}
                              />
                              </div>
                                <Input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.amount}
                                hasError={touched.amount && errors.amount}
                                feedback={errors.amount}
                                type="number"
                                //required
                                name="amount"
                            />
                        </div>
                        </div>
                    )}
                </div>    
                <div className="col-lg-8 col-md-12" style={{ height: "350px" }}>
                    <GoogleMap
                        name="position"
                        onChange={setFieldValue}
                        position={values.position}
                    />
                </div>
            </div>
                
      
            <div className="row form-row">
                <div className="col-12">
                <div className="form-group text-right">
                    <ButtonWithSpinner
                    isLoading={isSubmitting}
                    type="submit"
                    disabled={!dirty || !isValid || isSubmitting} >
                    Next
                    </ButtonWithSpinner>
                </div>
                </div>
            </div>

            </form>
        )}
        </Formik>
        </>

    )
}

export default connect(mapStateToProps, {})(LabAddress);