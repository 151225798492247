import { ERROR ,CLEAR, SUCCESS, DEFAULT } from "./toastTypes";

export const clearToast = () =>{
    return {
        type: CLEAR
    }
}

export const showToast = message =>{
    return {
        type: DEFAULT,
        payload: message
    }
}
export const showErrorToast = message =>{
    return {
        type: ERROR,
        payload: message
    }
}
export const showSuccessToast = message =>{
    return {
        type: SUCCESS,
        payload: message
    }
}