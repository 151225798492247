import api from '../axios'

const GEOCODING = "/geocoding";
const REVERSE_GEOCODING = "/reversegeocoding";

export const geoCoding = (input) => {
    const { type, address } = input
    return api.get(GEOCODING,{
        params:{
            type, address
        }
    })
}

export const reverseGeoCoding = (input) => {
    const { type, latitude, longitude } = input
    return api.get(REVERSE_GEOCODING, {
        params: {
            type, latitude, longitude
        }
    })
}