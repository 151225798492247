import React, { useEffect } from "react";
import Routes from "./Routes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import CustomToast from "./components/General/CustomToast/CustomToast";
import { BrowserRouter } from "react-router-dom";

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_HELP_SCOUT_KEY) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerText = `!function(e,t,n){function a(){
      var e=t.getElementsByTagName("script")[0],n=t.createElement("script");
      n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`;
      document.head.appendChild(script);
      window.Beacon("init", process.env.REACT_APP_HELP_SCOUT_KEY);
      window.Beacon("config", {
        color: "#426af4",
        display: {
          zIndex: 10001,
        },
        labels: {
          sendAMessage: "emedicare",
        },
      });
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_GTM_ID) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerText = `(function (w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', '${process.env.REACT_APP_GTM_ID}')`;
      document.head.appendChild(script);
    }
  }, []);

  return (
    <Provider store={store}>
      <CustomToast />
      <BrowserRouter>
        <div className="App">
          <PersistGate persistor={persistor}>
            <BrowserRouter basename="/fms-react">
              <Routes />
            </BrowserRouter>
          </PersistGate>
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
