import React from 'react';
import InfoDetails from './InfoDetails';
import './style.scss';

const InfoSection = () => {
    return (
        <div className="w-100 info-section pt-4 pb-4">
            <div className="w-100 info-section-header pt-4 pb-4 mb-2">
                <p className="m-0 text-center">We made it simple</p>
                <h2 className="m-0 text-center mb-1">How it <span className="header-span">Works?</span></h2>
                <p className="m-0 text-center">We are the fastest growing <b>doctors directory, medical directory</b> and <b>hospital directory</b></p>
            </div>
            <div className="info-section-content d-flex justify-content-center">
                <div className="col-md-3">
                    <InfoDetails
                        Icon={process.env.PUBLIC_URL+"/assets/img/footer-img-03.png"}
                        Title="Search Best Online"
                        SubTitle="Professional"
                    />
                </div>
                <div className="col-md-3">
                    <InfoDetails 
                        Icon={process.env.PUBLIC_URL+"/assets/img/footer-img-02.png"}
                        Title="Get instant" 
                        SubTitle="Appointment"
                    />
                </div>
                <div className="col-md-3">
                    <InfoDetails
                        Icon={process.env.PUBLIC_URL+"/assets/img/footer-img-01.png"}
                        Title="Leave your"
                        SubTitle="Feedback"
                    />
                </div>
            </div>
        </div>
    );
}

export default InfoSection;