import api from '../axios'

// param common - ?patient_id

const LIST_SOCIAL_HISTORY = '/doctor/patient/socialhistory'
const LIST_FAMILY_HISTORY = '/doctor/patient/familyhistory'
const LIST_ALLERGIC_HISTORY = '/doctor/patient/allergicdetails'  
const LIST_PRESCRIPTION_HISTORY = '/appointments/prescription'  

const ADD_SOCIAL_HISTORY = '/doctor/patient/socialhistory'  
const ADD_FAMILY_HISTORY = '/doctor/patient/familyhistory'  
const ADD_ALLERGIC_HISTORY = '/doctor/patient/allergicdetails'  

const getSocialHistory = (id,appointmentId) => {
    return api.get(LIST_SOCIAL_HISTORY, { 
        params: {
            patient_id: id,
            appointment_id: appointmentId
        }
    })
}

const getFamilyHistory = (id, appointmentId) => {
    return api.get(LIST_FAMILY_HISTORY, { 
        params: {
            patient_id: id,
            appointment_id: appointmentId
        }
    })
}

const getAllergicHistory = (id, appointmentId) => {
    return api.get(LIST_ALLERGIC_HISTORY, { 
        params: {
            patient_id: id,
            appointment_id: appointmentId
        }
    })
}

const getPrescriptionHistory = (id, appointmentId, filter) => {
    return api.get(LIST_PRESCRIPTION_HISTORY, { 
        params: {
            patient_id: id,
            appointment_id: appointmentId,
            name : filter.name,
            date : filter.date,
        }
    })
}

const addSocialHistory = ({patientId, appointmentId, detail}) => {
    return api.post(ADD_SOCIAL_HISTORY, { 
            patient_id : patientId,
            appointment_id : appointmentId,
            details : detail
    })
}

const addFamilyHistory = ({patientId, appointmentId, detail}) => {
    return api.post(ADD_FAMILY_HISTORY, { 
            patient_id : patientId,
            appointment_id : appointmentId,
            details : detail
    })
}

const addAllergicHistory = ({patientId, appointmentId, detail}) => {
    return api.post(ADD_ALLERGIC_HISTORY, { 
            patient_id : patientId,
            appointment_id : appointmentId,
            details : detail
    })
}

export default {
    getSocialHistory,
    getFamilyHistory,
    getAllergicHistory,
    getPrescriptionHistory,
    addSocialHistory,
    addFamilyHistory,
    addAllergicHistory,
}