import React from 'react';
import Select from 'react-select';

import { countryCodeData } from '../../../common/util/countyCodeMapping';

const colourStyles = {
    control: styles => ({ ...styles, lineHeight: "38px" }),
    menu: styles => ({ ...styles, zIndex: 10001 })
};
const options = countryCodeData;

const CountryCode = ({
    onChange = () => { },
    onBlur = () => { },
    setOptionValue = () => { },
    value,
    defaultValue = null,
    name: fieldName = 'countryCode',
    ...props
}) => {

    const handleChange = value => {
        // this is going to call setFieldValue and manually update values.topcis
        onChange(fieldName, value.value);
        setOptionValue(value)
    };

    const handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        onBlur(fieldName, true);
    };
    defaultValue = defaultValue || options[0]
    let val = options[0];
    if (value && value.id) {
        val = options.find(item => value === item.id)
    } else if (defaultValue) {
        val = options.find(item => defaultValue === item.value)
    }

    return (
        <div style={{ zIndex: 10001, position: "relative" }}>
            <Select
                id={fieldName}
                options={options}
                onChange={handleChange}
                onBlur={handleBlur}
                value={val}
                styles={colourStyles}
                defaultValue={val}
            />
            {!!props.error &&
                props.touched && (
                    <div style={{ color: 'red', marginTop: '.5rem' }}>{props.error}</div>
                )}
        </div>
    );
}
export default CountryCode
