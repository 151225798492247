import React from "react";
import {html_content} from "./TermsAndConditions"

export function TermsAndConditionsView () {
    return (
        <div dangerouslySetInnerHTML={{ __html: html_content }} />
    )
};

export function TermsAndConditionsHtml () {
    return (
        <div style={{margin:"40px 200px 40px 200px", display: "inline-block"}}>
        <div dangerouslySetInnerHTML={{ __html: html_content }} />
        </div>
    )
};