import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

function LoginRegisterNav() {
    
    const location = useLocation()
    let redirectTo = new URLSearchParams(location.search).get('to')
    return (
        <div className="container-fluid mt-3 mb-4">
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <ul className="nav justify-content-center tabbed-login">
                        <li className="nav-item">
                            <NavLink to={"/login"+ ( redirectTo ? '?to='+redirectTo : "" )} className="nav-link">Login</NavLink>
                        </li>
                        <li className="nav-item ml-5">
                            <NavLink to={"/signup" + ( redirectTo ? '?to='+redirectTo : "" ) } className="nav-link">Register</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default LoginRegisterNav;
