import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup'
import moment from 'moment';
import MedicalHistoryAPI from '../../../common/api/doctor/medicalHistoryApi'
import Input from './../../General/Form/Input';

const AddListHistory = ({ patientId, appointmentId, type = "socialHistory" }) => {
    const [loading, setLoading] = useState(true);
    const [history, setHistory] = useState([]);
    const getHistory = () => {
        const getHistoryEndpoints = {
            socialHistory: () => MedicalHistoryAPI.getSocialHistory(patientId, appointmentId),
            familyHistory: () => MedicalHistoryAPI.getFamilyHistory(patientId, appointmentId),
            allergicHistory: () => MedicalHistoryAPI.getAllergicHistory(patientId, appointmentId)
        }
        getHistoryEndpoints[type]()
            .then(response => {
                setHistory(response.data.data.map(item => ({
                    unique_id: item.unique_id,
                    detail: item.details,
                    doctor: item.doctor_name,
                    date: item.date
                })));
                setLoading(false);
            }).catch((error) => {
                console.log(error)
                setLoading(false);
            });
    }

    useEffect(() => {
        getHistory();
    }, []);

    return (
        <Formik
            enableReinitialize
            initialValues={{
                detail: ''
            }}
            validationSchema={Yup.object({
                detail: Yup.string().required("The social history field is required")
            })}
            onSubmit={(values, { resetForm }) => {
                const saveHistoryEndpoints = {
                    socialHistory: (data) => MedicalHistoryAPI.addSocialHistory(data),
                    familyHistory: (data) => MedicalHistoryAPI.addFamilyHistory(data),
                    allergicHistory: (data) => MedicalHistoryAPI.addAllergicHistory(data)
                }
                saveHistoryEndpoints[type]({
                    patientId,
                    appointmentId,
                    detail: values.detail
                })
                    .then(() => {
                        resetForm();
                        getHistory(type);
                    })
                    .catch(error => console.log(error.response.data));
            }}>
            {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid
            }) => (
                <form onSubmit={handleSubmit} className="pt-2 pb-2">
                    <h5 className="mb-4">{type.charAt(0).toUpperCase() + type.slice(1).replace(/([a-z](?=[A-Z]))/g, '$1 ')}</h5>
                    <div className="w-100 d-flex">
                        <div className="w-75 mr-3">
                            <Input
                                name={`detail`}
                                label={'Add ' + type.charAt(0).toUpperCase() + type.slice(1).replace(/([a-z](?=[A-Z]))/g, '$1 ')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.detail}
                                autoComplete="new-password"
                            />
                        </div>
                        <div className="d-flex w-25">
                            <button type="submit" className="btn border-primary text-primary pr-5 pl-5" style={{ height: "50px" }} disabled={!values.detail}>Add</button>
                        </div>
                    </div>
                    <table className="table table-hover">
                        <tbody>
                            {!loading && history.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        {item.unique_id}
                                    </td>
                                    <td>
                                        {item.detail}
                                    </td>
                                    <td>
                                        Dr. {item.doctor}
                                    </td>
                                    <td>
                                        {moment(item.date).format("MMM DD, YYYY")}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {
                        !loading && history.length === 0 && (
                            <h6 className="text-center">
                                {type === "socialHistory" && 'No social history found'}
                                {type === "familyHistory" && 'No family history found'}
                                {type === "allergicHistory" && 'The patient has no allergic history'}
                            </h6>
                        )
                    }
                </form>
            )
            }
        </Formik>
    );
}

export default AddListHistory;