import { SET_USER_DATA } from './userTypes'

const initialState = {
    title: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    gender: '',
    date_of_birth: '',
    age: 0,
    blood_group: '',
    height: '',
    weight: '',
    marital_status: '',
    occupation: '',
    alt_mobile_number: '',
    alt_country_code: '+91',
    email: '',
    mobile_number: '',
}

const userReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default userReducer;