import React from 'react';
import { connect } from 'react-redux';
import { Toast } from 'react-bootstrap';
import { clearToast } from '../../../redux'

function CustomToast({ toast, closeToast }) {
    
    if (!toast) {
        return <></>
    }

    let messages = toast.messages || null;
    if (!messages) {
        return <></>
    }

    let delay = toast.autoHideDelay || 9000
    let autohide = !!toast.autoHideDelay
    let backgroundColor = '#ffecca';
    let borderColor = '#ea8345';


    if (toast.type === 'success') {
        backgroundColor = '#c6f4d8';
        borderColor = '#72c192';
    }

    if (!(messages instanceof Array)) {
        messages = [messages + ""];
    }
    console.log(messages)
    return (
        <Toast
            animation={true}
            delay={delay}
            onClose={() => closeToast()}
            show={(messages && messages.length > 0)}
            autohide={autohide} 
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 2000,
                margin: "auto",
                backgroundColor,
                borderWidth: "1px",
                borderRadius: "0 0  .25rem .25rem",
                borderColor,
                textAlign: "center"
            }}>
            <Toast.Body>
                
                {   
                    (messages && messages.length > 0) && messages.map((item, index) => {
                        return <b key={index} style={{ margin: 0 }}>{item? item : '-'}</b>
                    })
                }
            </Toast.Body>
        </Toast>
    );
}

const mapStateToProps = state => {
    return {
        toast: state.toast
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeToast: () => dispatch(clearToast())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomToast);