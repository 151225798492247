import React, { useState, useEffect } from "react"
import '../styles.css';
import { connect } from "react-redux"; 
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup'
import ButtonWithSpinner from '../../../General/Form/ButtonWithSpinner';
import { toastSuccess } from '../../../../common/util/toast';
import autoCatch from '../../../../common/util/autoCatch';
import laboratoryRegister from '../../../../common/api/laboratory/laboratoryRegister';
import { setLabTestData } from '../../../../redux'
import axiosApi from "../../../../common/api/axios"


const initialValues = {
    testArray : [],
    test_name: false,
    sample_collection: false
  }

const mapStateToProps = state => {
    const { labId } = state;
    return { labId };
  };

const LabTests = (props) => {
    
    const dispatch = useDispatch()
    const validationSchema = Yup.object({
        test_name: Yup.boolean()
                .oneOf([true], 'Must select a test name'),
    });
    
    let [labTests, setlabTests] = useState([])

    useEffect(() => {
        axiosApi.get('/guest/service?type=LAB&paginate=0')
        .then((res) => {
            setlabTests(res.data )
        });
    }, [])   

    return (
        <>
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, setErrors }) => {
                setSubmitting(true);
                const inputData = {
                    data_id: props.labId.addressId,
                    row: values.testArray
                }
                autoCatch(laboratoryRegister.labTest(inputData).then(res => {
                if (res.status === 200) {
                    dispatch(setLabTestData(res.data.data_id))
                    toastSuccess('Registration successful')
                    props.onSubmit(4)
                }
                setSubmitting(false);
                }), data => {
                if (data && data.errors) {
                    setErrors(data.errors)
                }
                setSubmitting(false);
                })

            }}
        >{({
        values,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        dirty,
        isValid,
        }) => (
            <form onSubmit={handleSubmit}>
            <h4 className="card-title my-4">List of tests</h4>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="datatable table table-center mb-0">
                                    <thead>
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Test Id</th>
                                            <th>Test Name</th>
                                            <th>Sample Collection</th>
                                        </tr>
                                    </thead>
                                   
                                    <tbody>
                                    { labTests &&(
                                        labTests.length > 0 &&(
                                            labTests.map((lab, idx) => (
                                      
                                        <tr key={lab.id}>
                                            <td>{idx + 1}</td>
                                            <td>{lab.unique_id}</td>
                                            <td>
                                                <div className="status-toggle">
                                                
                                                    <label className="checktoggle label-fixed-width pright-20">{lab.name}</label>
                                                    <input
                                                        type="checkbox"
                                                        value={values.test_name}
                                                        checked={values.testArray&&values.testArray.length&&
                                                                     values.testArray.find(ta=>ta.id===lab.id)?true:false}
                                                        onChange={(e) => {
                                                            let test = values.testArray
                                                            if(e.target.checked) {
                                                            test.push({'id': lab.id, 'sample_collect': '0'})
                                                            setFieldValue('testArray', test)
                                                        } else {
                                                            let temp = test.filter(t => (
                                                                t.id !== lab.id
                                                            ))                                                          
                                                            values.testArray = temp
                                                           
                                                        }
                                                            setFieldValue('test_name', true)
                                                        }}
                                                        name="test_name"
                                                        id="test_name"
                                                        />
                                                       
                                                </div>

                                            </td>
                                            <td>
                                                <div className="status-toggle">
                                                    <label className="checktoggle pright-20">From home </label>
                                                    {/* {console.log('---------------------', values.testArray&&values.testArray.length?
                                                                    values.testArray.find(ta=>ta.id===lab.id)?
                                                                    values.testArray.find(ta=>ta.id===lab.id).sample_collect:false:false)} */}
                                                    <input
                                                        type="checkbox"
                                                        value={values.sample_collection}
                                                        checked={values.testArray&&values.testArray.length?
                                                                    values.testArray.find(ta=>ta.id===lab.id)?
                                                                    values.testArray.find(ta=>ta.id===lab.id).sample_collect==="1"?true:false:false:false}
                                                        
                            
                                                        onChange={(e) => {
                                                            // let test = values.testArray
                                                            // test.map((i, key) => {
                                                            //     if(i.id === lab.id){
                                                                    let isElement=values.testArray&&values.testArray.length&&
                                                                     values.testArray.find(ta=>ta.id===lab.id)
                                                                     let test = values.testArray
                                                                     if(isElement){
                                                                        values.testArray.map((i, key) => {
                                                                 if(i.id === lab.id){
                                                                        if(e.target.checked){
                                                                    test[key]['sample_collect']= '1'
                                                                    setFieldValue('sample_collection', true)
                                                                   } else {
                                                                    // let temp = test.filter(t => (
                                                                    //     t.id !== lab.id
                                                                    // ))
                                                                    test[key]['sample_collect']= '0'
                                                                    // setFieldValue('testArray', temp) 
                                                                    setFieldValue('sample_collection', false) 
                                                                   }}})
                                                                     }
                                                                     else{
                                                                    
                                                                    let test = values.testArray
                                                                    if(e.target.checked) {
                                                                        test.push({'id': lab.id, 'sample_collect': '1'})
                                                                        setFieldValue('testArray', test)
                                                                    } else {
                                                                        let temp = test.filter(t => (
                                                                        t.id !== lab.id
                                                                    ))
                                                                    setFieldValue('testArray', temp) 
                                                                    }
                                                                }
                                                                setFieldValue('test_name', true)
                                                                }
                                                        // })
                                                        }
                                                        //setFieldValue('testArray', test)
                                                        name="sample_collection"
                                                        id="sample_collection"
                                                        />
                                                        
                                                </div>
                                            </td>
                                        </tr>
                                        ))))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>			
            </div>
            <div className="row form-row">
                <div className="col-12">
                <div className="form-group text-right">
                    <ButtonWithSpinner
                    isLoading={isSubmitting}
                    type="submit"
                    disabled={!dirty || !isValid || isSubmitting || values.testArray.length <=0} 
                    >
                    Next
                    </ButtonWithSpinner>
                </div>
                </div>
            </div>

            </form>
        )}
        </Formik>
        </>
        
    )
}

export default connect(mapStateToProps, {})(LabTests);