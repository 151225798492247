import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout, meAction } from "../../redux";
import API from "../../common/api";
import { Avatar } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { setProfilePic } from '../../redux/auth/authActions';

function Header() {
  let history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [me, set_me] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  const auth = useSelector((state) => state.auth);
  // const me = useSelector((state) => state.me);
  // console.log('me', me);
  const { token } = auth;
  const dispatch = useDispatch();
  useEffect(() => {
    API.me()
      .then(response => {
          // dispatch(meAction(response.data))
            window.Beacon('identify', {
              name: response.data.first_name+" "+response.data.last_name,
              email: response.data.email,
              avatar: response.data.profile_photo,
              signature: response.data.signature,
            })
            if (response.data.profile_photo) {
              // console.log('profile_photo', response.data.profile_photo);
              dispatch(setProfilePic(response.data.profile_photo || null))
          }
          set_me(response.data)
      })
      .catch(error => {
        window.Beacon('logout', { endActiveChat: true })
      })
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const logoutHandler = () => {
    API.logout()
      .then((res) => {
        dispatch(logout());
        history.push("/login");
      })
      .catch(() => {
        dispatch(logout());
        history.push("/login");
      });
  };

  return (
    <header className="header">
      <nav
        className="navbar navbar-expand-lg header-nav"
        style={{ zIndex: 1, boxShadow: "0 2px 2px #eee" }}
      >
        <div className="navbar-header">
          <div onClick={() => setShowMenu(!showMenu)}>
            <span className="bar-icon">
              <span />
              <span />
              <span />
            </span>
          </div>
          <NavLink to="/" className="navbar-brand logo">
            <img
              src={process.env.PUBLIC_URL + "/assets/img/logo2.png"}
              className="img-fluid"
              alt="Logo"
            />
          </NavLink>
        </div>
        {((width <= 575 && showMenu) || width > 575) && (
          <>
            <div
              className="nav-links flexWrap"
              style={{ display: "flex", margin: "auto" }}
            >
              <Link to="/" className="nav-link">
                <h6 className="mb-0">Doctors</h6>
                <p className="text-secondary mb-0">Book an appointment</p>
              </Link>
              <a href={process.env.REACT_APP_ECOMMERCE_URL + "/medicines"} className="nav-link">
                <h6 className="mb-0">Pharmacy</h6>
                <p className="text-secondary mb-0">
                  Medicines & Health products
                </p>
              </a>
              {/* </div><a href={process.env.REACT_APP_ECOMMERCE_URL + "/medicines/tests"} className="nav-link"> */}
              <a href={process.env.REACT_APP_ECOMMERCE_URL + "/tests"} className="nav-link">
                <h6 className="mb-0">Diagnostics</h6>
                <p className="text-secondary mb-0">Book test & checkups</p>
              </a>
            </div>
            <ul className="nav header-navbar-rht">
              {!token ? (
                <li className="nav-item">
                  <Link
                    to="/login"
                    className="nav-link btn btn-outline-primary btn-sm"
                  >
                    LOGIN / SIGNUP
                  </Link>
                </li>
              ) : (
                <>
                  <li className="nav-item p-0">
                    <Link
                      className="text-dark d-flex align-items-center"
                      to="/dashboard"
                      style={{ textDecoration: "none" }}
                    >
                      {me &&
                        <>
                        <Avatar
                        style={{
                          backgroundColor:"blue"
                        }}
                        alt={me?.first_name.replace(/(^|\s)\S/g, l => l.toUpperCase())}
                        src={me?.profile_photo}
                      />
                      <span className="pl-2">{me?.user_type === "DOCTOR" && "Dr."} {me?.first_name} {me?.last_name}</span>
                      </>
                      }
                    </Link>
                  </li>
                  <li className="nav-item">
                    <button
                      onClick={logoutHandler}
                      className="nav-link btn btn-link btn-sm py-0"
                    >
                      Logout
                    </button>
                  </li>
                </>
              )}
            </ul>
          </>
        )}
      </nav>
    </header>
  );
}

export default Header;
