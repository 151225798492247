/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'

export default function FillPrescriptionSidebar() {
    const patient = useSelector(state => state.doctorFillAppointment.patient )
   
    return (
        <>
            <div 
                className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar fill-prescription-sidebar" 
                style={{ 
                    fontFamily: 'Poppins',
                    position: 'relative', 
                    overflow: 'visible', 
                    boxSizing: 'border-box', 
                    minHeight: '1px'  
                }}>
            <div 
                className="theiaStickySidebar" 
                style={{ 
                    paddingTop: '0px', 
                    paddingBottom: '1px', 
                    position: 'static', 
                    transform: 'none' ,
                    width: '226px' 
                }}>
            <div className="profile-sidebar">
            <div className="widget-profile pro-widget-content">
                <div className="profile-info-widget">
                <a href="#" className="booking-doc-img">
                    <img 
                        src={patient.profile_photo_url || process.env.PUBLIC_URL+'/assets/img/patients/patient.jpg'} 
                        alt="User" 
                    />
                </a>

                <h3>{patient.first_name} </h3>
                <h5 
                    style={{color: '#888', fonWeight: 400}}
                    className="patient-address" >{patient.address} address 
                </h5>

                <div className="profile-det-info">
                    <div className="row">
                        <div 
                            className="col-md-6 details-title" 
                            style={{color: '#242425', fontWeight: 500}}
                        >
                            <span>Age</span>
                        </div>
                        <div className="col-md-6">
                            <span> </span>
                        </div>
                    </div>

                    <div className="row">
                        <div 
                            className="col-md-6 details-title" 
                            style={{color: '#242425', fontWeight: 500}}
                        >
                            <span>Gender</span>
                        </div>
                        <div className="col-md-6">
                            <span>Female</span>
                        </div>
                    </div>  

                    <div className="row">
                        <div 
                            className="col-md-6 details-title" 
                            style={{color: '#242425', fontWeight: 500}}
                        >
                            <span>Age</span>
                        </div>
                        <div className="col-md-6">
                            <span> </span>
                        </div>
                    </div>

                    <div className="row">
                        <div 
                            className="col-md-6 details-title" 
                            style={{color: '#242425', fontWeight: 500}}
                        >
                            <span>Phone</span>
                        </div>
                        <div className="col-md-6">
                            <span> {patient.mobile_number} </span>
                        </div>
                    </div>

                    <div className="row">
                        <div 
                            className="col-md-6 details-title" 
                            style={{color: '#242425', fontWeight: 500}}
                        >
                            <span>Email</span>
                        </div>
                        <div className="col-md-6">
                            <span>22</span>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="dashboard-widget">
            </div>
            </div>
            </div>
            </div>
        </>
    )
}