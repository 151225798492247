import { SET_LAB_DATA } from './labTypes'
import { SET_LAB_ADDRESS_DATA } from './labTypes'
import {  SET_LAB_TEST_DATA } from './labTypes'

const initialState = {
    id: '',
    addressId: '',
    lab_list: [],
    testId: ''
    
}

const labReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_LAB_DATA:
            return {
                ...state,
                id : action.payload
            }
        case SET_LAB_ADDRESS_DATA:
                 
                return {
                  ...state,
                  addressId: action.payload.data_id,
                  lab_list: action.payload.laboratory_test_lists
                };
        case  SET_LAB_TEST_DATA:
            return {
                ...state,
                testId: action.payload,
              };

        default:
            return state
    }
}

export default labReducer;