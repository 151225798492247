import React from 'react';
import Carousel from 'react-elastic-carousel';
import { useHistory } from "react-router-dom";
import { getSpecializationData } from "../../../common/api/general/searchApi";

export const ClinicAndSpecializationsCarousel = () => {
    const history = useHistory()
    const [specialization_list, set_specialization_list] = React.useState();
    const [mount, setMount] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    
    const breakPoints = [
        { width: 1, itemsToShow: 1},
        { width: 350, itemsToShow: 2 },
        { width: 700, itemsToShow: 4},
        { width: 900, itemsToShow: 5 }
      ];

    const getData = () => {
        setLoading(true);
        set_specialization_list(undefined)
        getSpecializationData().then(response => {
            set_specialization_list(response.data);
            // console.log('response.data', response.data);
            setLoading(false)
        })
          .catch(error => {
            console.log(error);
            setLoading(false);
          });
      }
    
      React.useEffect(() => {
        if (mount) {
          getData();
          setMount(false)
      }
      },[getData]);

    return (
        <>
            {
                specialization_list?.length > 0 && 
                <Carousel
                    breakPoints={breakPoints}
                    pagination={false}
                >
                    {
                        specialization_list.map((category, i) => (
                            <div key={i} className="special-card" 
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={(() => {
                                history.push(
                                    `/search/Any?shift=ANY&consulting_fee_start=0&consulting_fee_end=10000&specialization=${category.id}`
                                )
                            })}
                            >
                            {category.image ? <img style={{width:"52px"}} src={category.image} /> : <div className="img equip" />}
                            <h6 style={{ fontSize: "14px", margin: "5px 0" }}>
                                {category.name}
                            </h6>
                            </div>
                        ))
                    }
                </Carousel>
            }
        </>
    );
}