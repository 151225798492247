export function capitalizeFirstLetter(string) {
    return string ? string[0].toUpperCase() + string.slice(1) : string
}

export function pad(i) {
    if (i < 10) {
        return "0" + i
    }
    return i
}
export function formatTime(hh_mm_ss = '', _24hr = false) {
    if (!hh_mm_ss) return '';
    const timeArray = hh_mm_ss.split(':')
    if (!timeArray || timeArray.length < 2) {
        return ''
    }

    if (_24hr) {
        return timeArray[0] + ":" + timeArray[1]
    }
    let h = Number(timeArray[0] || 0);
    let m = Number(timeArray[1] || 0);

    return pad((h % 12) || 12) + ":" + pad(m) + " " + (h >= 12 ? "PM" : "AM")

}

