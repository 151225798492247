import api from '../axios'


export const laboratoryRegister = data => {
    const headers = {
        "Content-Type": 'multipart/form-data',
        "Accept": "application/json",
    };
    return api.post('oauth/laboratory/basicinfo', data)
}

export const laboratoryAddress = data => {
    const headers = {
        "Content-Type": 'multipart/form-data',
        "Accept": "application/json",
    };
    return api.post('oauth/laboratory/address', data)
}

export const labTest = data => {
    const headers = {
        "Content-Type": 'multipart/form-data',
        "Accept": "application/json",
    };
    return api.post('oauth/laboratory/testlist', data)
}

export const labBankDetails = data => {
    const headers = {
        "Content-Type": 'multipart/form-data',
        "Accept": "application/json",
    };
    return api.post('oauth/laboratory/bankdetails', data)
}

export const labOtp = data => {
    const headers = {
        "Content-Type": 'multipart/form-data',
        "Accept": "application/json",
    };
    return api.post('oauth/laboratory/otp/verify/mobileandemail', data)

}
export const labResendMobileOtp = data => {
   
    return api.post('oauth/laboratory/otp/resend/mobile', data)

}
export const labResendMailOtp = data => {
    // console.log('data---------------------------------------', data)
    return api.post('oauth/laboratory/otp/resend/email', data)

}

export default {
    laboratoryRegister,
    laboratoryAddress,
    labTest,
    labBankDetails,
    labOtp,
    labResendMobileOtp,
    labResendMailOtp

}
