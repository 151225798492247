import React, { lazy, Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import HomePage from './components/HomePage/HomePage';
import LoginPage from './components/RegisterAndLogin/LoginPage/LoginPage';
import PharmaLabLogin from './components/RegisterAndLogin/LoginPage/PharmaLabLogin';
import SignupPage from './components/RegisterAndLogin/SignupPage/SignupPage';
import CustomSpinner from './components/General/CustomSpinner';
import PrivateRoute from './PrivateRoute';
import ErrorPage from './components/ErrorPages/ErrorPage'
import './custom-theme.css';
import PharmacyRegister from './components/RegisterAndLogin/PharmacyAndLabRegister/PharmacyRegister';
import MedicalHistory from './components/Doctor/Appointment/MedicalHistory';
import {PatientNoShowPolicyHtml, OnlineConsultationHtml, PolicyHtml, ReschedulePolicyHtml, AboutHtml} from './components/Policy/View';

// lazy Loading
const LabOTPVerification = lazy(() => import('./components/RegisterAndLogin/SignupPage/OTPConfirmation/LabOTPConfirmation'))  
const PharmacyOTPVerification = lazy(() => import('./components/RegisterAndLogin/SignupPage/OTPConfirmation/PharmacyOTPVerification'))
const OTPConfirmation = lazy(() => import('./components/RegisterAndLogin/SignupPage/OTPConfirmation/OTPConfirmation'));
const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const Appointment = lazy(() => import('./components/Dashboard/patient/Appointments'));
const DocAppointment = lazy(() => import('./components/Dashboard/doctor/Appointment'));
const DoctorScheduleTimings = lazy(() => import('./components/Dashboard/DoctorScheduleTimings'));
const Family = lazy(() => import('./components/Dashboard/Family'));
const DoctorSearch = lazy(() => import('./components/BookAppointment/DoctorSearch'));
const DoctorProfile = lazy(() => import('./components/BookAppointment/DoctorProfile'));
const Checkout = lazy(() => import('./components/BookAppointment/Checkout'));
const followups = lazy(() => import('./components/BookAppointment/Followups/CheckoutPage'));
const CheckoutPage = lazy(() => import('./components/BookAppointment/Checkout/CheckoutPage'));
const ForgetPassword = lazy(() => import('./components/RegisterAndLogin/ForgetPassword/ForgetPassword'));
const ResetPassword = lazy(() => import('./components/RegisterAndLogin/ForgetPassword/ResetPassword')); 
const DoctorAppointmentHome = lazy(() => import('./components/Doctor/Appointment/Home'));  
 
const FillPrescription = lazy(() => import('./components/Doctor/Appointment/Prescription'));  
const ViewProfile = lazy(() => import('./components/Doctor/Appointment/ViewProfile'));  
 
const PatientPrescriptions = lazy(() => import('./components/Dashboard/patient/Prescription/PatientPrescriptions'));  
const PrescriptionDetails = lazy(() => import('./components/Dashboard/patient/Prescription/PrescriptionDetails'));  
const medicineOrders = lazy(() => import('./components/Dashboard/patient/medicineOrders'));  
const TestOrders = lazy(() => import('./components/Dashboard/patient/TestOrders'));  
const PurchaseHistory = lazy(() => import('./components/Dashboard/patient/PurchaseHistory'));  
const MedicineOrdersView = lazy(() => import('./components/Dashboard/patient/Order/NewQuotes/MedicineOrdersView'));  
const OrdersView = lazy(() => import('./components/Dashboard/patient/Order/Orders/OrdersView'));  
const Invoice = lazy(() => import('./components/Invoice'));   
const RedirectAction = (lazy(() => import('./components/Redirect/Redirect')))
const PaymentandPayout = (lazy(() => import('./components/Dashboard/doctor/PaymentandPayout')))
const PaymentPreferences = lazy(()=> import('./components/Dashboard/doctor/PaymentPreferences'))

const PayoutView = (lazy(() => import('./components/Dashboard/doctor/PaymentandPayout/Payouts/PayoutView')))
const PrivacyPolicyPage = (lazy(() => import('./components/Policy/PrivacyPolicyPage')))
const TermsAndConditionsPage = (lazy(() => import('./components/TermsAndConditions/TermsAndConditionsPage')))
const AboutUsPage = (lazy(() => import('./components/Policy/AboutUsPage')));

function ErrorRoutes(props){
    return (
        <>
            <Route path="/404"  component={()=><ErrorPage status="404" message="Page Not Found." />} />
            <Route exact path="/error/:message" component={ErrorPage} />
            <Route path="/error/:status/:message" component={ErrorPage} />
        </>
    )

}
export default function Routes(props) {
    return (
        <>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/signin" component={PharmaLabLogin} />
            <Route exact path="/signup" component={SignupPage} />
            <Route exact path="/register" component={PharmacyRegister}/>
            <Suspense fallback={<CustomSpinner />}>
                <Switch>
                    <PrivateRoute exact path="/doctor/appointments/:type" component={DoctorAppointmentHome} />
                    <Redirect exact from="/doctor/appointments" to="/doctor/appointments/todayAppointments"/>
                    <PrivateRoute exact path="/doctor/patient/:patientId/view-profile" component={ViewProfile} />
                    <PrivateRoute exact path="/doctor/patient/:patientId/appointments/:appointmentId/medical-history" component={MedicalHistory} />
                    <PrivateRoute exact path="/doctor/patient/:patientId/appointments/:appointmentId/fill-prescription" component={FillPrescription} />
                    <Route path="/doctor/:doctorId" component={DoctorProfile} />
                </Switch>
                <Route exact path="/pharmacy/confirm" component={PharmacyOTPVerification} />
                <Route exact path="/laboratory/confirm" component={LabOTPVerification} />
                <Route exact path="/signup/confirm" component={OTPConfirmation} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/family" component={Family} />
                <PrivateRoute doctor to='/dashboard' path="/schedule-time" component={DoctorScheduleTimings} />
                <Route exact path="/search" component={() => <Redirect to="/"/> } />
                <Route exact path="/search/:q" component={DoctorSearch} />
                <PrivateRoute reditectBack path="/checkout/:checkoutId?" component={Checkout} />
                <PrivateRoute path="/followup/:followUpId?/confirm" component={followups} />
                <Route exact path="/forgot-password" component={ForgetPassword} />
                <PrivateRoute exact path="/patient/prescriptions" component={PatientPrescriptions} />
                <PrivateRoute exact path="/patient/prescriptions/:prescriptionId/quote/:location" component={PrescriptionDetails} />
                <PrivateRoute exact path="/patient/prescriptions/:prescriptionId/quote" component={PrescriptionDetails} />
                <PrivateRoute exact path="/invoice" component={Invoice} />
                <PrivateRoute exact path="/appointments" component={Appointment} />
                <PrivateRoute exact path="/medicine-orders" component={medicineOrders} />
                <PrivateRoute exact path="/test-orders" component={TestOrders} />
                <PrivateRoute exact path="/medicine-orders/view" component={MedicineOrdersView} />
                <PrivateRoute exact path="/orders/view" component={OrdersView} />
                <PrivateRoute doctor exact path="/appointments-doc" component={DocAppointment} />
                <PrivateRoute exact path="/reset-password" component={ResetPassword} />
                <PrivateRoute exact path="/signup/reset-password" component={ResetPassword} />
                <Route path="/redirect" component={RedirectAction}/>
                <Route path="/doctor-payments-payouts" component={PaymentandPayout}/>
                <Route path="/doctor-payments-payouts-view" component={PayoutView}/>
                <Route path="/payment-preferences" component={PaymentPreferences}/>
                <PrivateRoute exact path="/checkout-summery" component={CheckoutPage} />
                <PrivateRoute exact path="/purchase-history" component={PurchaseHistory} />
                <Route path="/patient-no-show-policy" component={PatientNoShowPolicyHtml} />
                <Route path="/online-consultaion-policy" component={OnlineConsultationHtml} />
                <Route path="/policy" component={PolicyHtml} />
                <Route path="/about-us" component={AboutUsPage} />
                <Route path="/reschedule-policy" component={ReschedulePolicyHtml} />
                <Route path="/privacy-policy" component={PrivacyPolicyPage} />
                <Route path="/terms-and-conditions" component={TermsAndConditionsPage} />
                <ErrorRoutes />
            </Suspense>
        </>
    )
}
