import { LOGIN, LOGOUT, PROFILE_PIC } from "./authTypes";

const initialState = {
  token: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.payload,
      };
    case PROFILE_PIC:
      console.log("PROFILE PIC")
      const token = action.payload ? {...state.token, profile_photo: action.payload} : null
      return {
        ...state,
        token,
      };
    case LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default authReducer;
