import axios from './axios'


window.google = window.google ? window.google : {}
const geocoder = new window.google.maps.Geocoder();

export const getCode = (zip, fn = () =>{}) => {
    // console.log('pincode');
    geocoder.geocode({ address: zip }, (results, status) => {
        // console.log(results, "results", status)
        if (status === "OK") {
            // console.log("one ", results)
            const address_components = results[0].address_components
            const position = results[0].geometry
            if (!address_components) {
                throw new Error({ message: "Cant complete address fetch " + status });
            }
            // console.log("two ", address_components)
            let resultAddr = {}
            address_components.forEach(({ types = [], long_name = '' }) => {

                // console.log("three ", results)
                if (types.includes('country')) {
                    resultAddr['country'] = long_name;
                }
                else if (types.includes('administrative_area_level_2')) {
                    resultAddr['district'] = long_name;
                }
                else if (types.includes('administrative_area_level_1')) {
                    resultAddr['state'] = long_name;
                }
                else if (types.includes('postal_code')) {
                    resultAddr['pincode'] = long_name && Number(long_name) ? Number(long_name) : '';
                }
                // console.log("four ", resultAddr)

                
            })

            if(position && position.location){
                resultAddr['position'] = {
                    lat: position.location.lat(),
                    lng: position.location.lng()
                }
            }

            // console.log("five", resultAddr)
            fn(resultAddr)
        }
    });
} 

export default axios;
