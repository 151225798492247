import React from 'react'
import LoginRegisterNav from '../LoginRegisterNav';
import LoginSection from './LoginSection/LoginSection';
import CommonTemplate from '../../Template/CommonTemplate';
import './LoginPage.css'

function LoginPage() {
  
    React.useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <CommonTemplate>
                <LoginRegisterNav />
                <LoginSection />
            </CommonTemplate>

        </>
    )

}

export default LoginPage;