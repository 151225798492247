import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { capitalizeFirstLetter } from '../../../common/util/stringUtil'
const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Must be 8 characters or more.<br/>
    Include at least one upper case, lower case, numbers and symbols.
  </Tooltip>
);

const PasswordInput = (props) => {
    const {
        name,
        value = '',
        handleChange = () => { },
        handleBlur = () => { },
        hasError = false,
        feedback,
        noHint = false,
        autocomplete = "new-password",
        ...rest
    } = props
    const [showPassword, setShowPassword] = useState(false)
    const label = props.label || name || ''
    return (
        <div className={"form-group form-focus" + (value || value === 0 ? ' focused' : '')}>
            <i className={"fa password-eye " + (showPassword ? 'fa-eye' : 'fa-eye-slash')} onClick={() => {
                setShowPassword(!showPassword)
            }}></i>
            {noHint ? <input
                    type={showPassword ? 'text' : 'password'}
                    name={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={value || ''}
                    autocomplete={autocomplete}
                    className={hasError ? 'password form-control is-invalid' : 'form-control'}
                    style={{ padding: "21px 30px 6px 12px" }}
                    {...rest}
                />:  (<OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
            >
                <input
                    type={showPassword ? 'text' : 'password'}
                    name={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={value || ''}
                    autocomplete={autocomplete}
                    className={hasError ? 'password form-control is-invalid' : 'form-control'}
                    style={{ padding: "21px 30px 6px 12px" }}
                    {...rest}
                />
            </OverlayTrigger>) }
            <label className="focus-label">{capitalizeFirstLetter(label)}</label>
            {hasError && feedback && (
                <div style={{ margin: 0 }} className="invalid-feedback">{feedback}</div>
            )}
        </div>
    )
}

export default PasswordInput