import React from 'react'
import { capitalizeFirstLetter } from '../../../common/util/stringUtil'

const Input = (props) => {
    let {
        name,
        type = 'text',
        handleChange = () => { },
        handleBlur = () => { },
        hasError = false,
        feedback,
        touched,
        errors,
        mandatory = false,
        required = false,
        value,
        autocomplete = "new-password",
        ...rest
    } = props
    const label = props.label || name || ''
    value = (value === null || typeof value === "undefined") ? '' : value
    mandatory = mandatory ? mandatory : required;

    if (touched && errors && !hasError) {
        hasError = errors
        if (!feedback) {
            feedback = errors
        }
    }

    return (<>
        {mandatory && <span className="text-danger">&nbsp;*</span>}
        <div className={"form-group form-focus" + (value || value === 0 ? ' focused' : '')}>
            <input
                type={type}
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                className={hasError ? 'form-control is-invalid' : 'form-control'}
                autocomplete={autocomplete}
                onKeyPress={(e) => {
                    if (type === "number" && !Number.isInteger(parseInt(e.key))){
                        e.preventDefault();
                    }
                }}
                {...rest}
            />
            <label className="focus-label">{capitalizeFirstLetter(label)}</label>
            {hasError && feedback && (
                <div style={{ margin: 0 }} className="invalid-feedback">{feedback}</div>
            )}
        </div>
    </>)
}

export default Input