import React from "react";
import { useHistory } from 'react-router-dom';
import {ClinicAndSpecializationsCarousel} from './ClinicAndSpecializationsCarousel'

function ClinicAndSpecialities() {
    const history = useHistory();

    return (
    <>
      <section className="section">
        <div className="container-fluid home-width pt-5 pb-5">
          <div className="w-100">
            <div className="w-100 text-left">
              <div className="row">
                <div className="col-12 d-flex justify-content-between align-items-center">
                  <h2 className="b">Our Specialities</h2>
                    <button className="btn btn-outline-primary" onClick={() => history.push("/search/Any?shift=ANY&consulting_fee_start=0&consulting_fee_end=10000")}>
                      View All
                    </button>
                </div>
              </div>
              <div className="w-100">
                  <ClinicAndSpecializationsCarousel />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-9">
              {/* Slider */}
              <div className="specialities-slider slider"></div>
              {/* /Slider */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ClinicAndSpecialities;
