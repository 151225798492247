import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const handleDragStart = (e) => e.preventDefault();

const items = [
  <img src={process.env.PUBLIC_URL+"/assets/img/cover1.png"} onDragStart={handleDragStart} />,
  <img src={process.env.PUBLIC_URL+"/assets/img/cover2.png"} onDragStart={handleDragStart} />,
  <img src={process.env.PUBLIC_URL+"/assets/img/cover3.png"} onDragStart={handleDragStart} />,
];

export const Slider = () => {
  return (
    <div className="w-100 pt-3 pb-3">
      <AliceCarousel
        mouseTracking
        autoPlay
        infinite
        disableButtonsControls
        autoPlayInterval="6000"
      >
        <a href={process.env.REACT_APP_HA_URL}>
          <img style={{ width: "100%" }} src={process.env.PUBLIC_URL+"/assets/img/emedicare_banners_1.png"} onDragStart={handleDragStart} />
        </a>
        <a href="">
          <img style={{ width: "100%" }} src={process.env.PUBLIC_URL+"/assets/img/emedicare_banners_2.png"} onDragStart={handleDragStart} />
        </a>
        <a href={process.env.REACT_APP_ECOMMERCE_URL + "/medicines"}>
          <img style={{ width: "100%" }} src={process.env.PUBLIC_URL+"/assets/img/emedicare_banners_3.png"} onDragStart={handleDragStart} />
        </a>
      </AliceCarousel>
    </div>
  );
}