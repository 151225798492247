const base_url = process.env.REACT_APP_URL
export const reschedule_policy_content = `
<h2 style="text-align: center;"><b>Cancellation, Rescheduling &amp; Refund</b></h2>
<p>emedicare defines its cancellation &amp; rescheduling policy for Doctors &amp; front users (Patients). This policy will be applicable on all emedicare platforms i.e. in-clinic appointments, online appointments and emergency appointments.</p>
<p><strong>Cancellation &amp; Rescheduling Policy:</strong></p>
<ol style="list-style-type: lower-roman;">
<li>This policy is applicable only on appointments made using&nbsp;<strong>&lsquo;Book Appointment&rsquo;</strong> button on&nbsp;<a href=${base_url}>emedicare.in</a>or&nbsp;.</li>
<li>This policy is applicable for all types of appointments i.e. in-clinic, online and emergency.</li>
<li>Maximum number of rescheduling the appointment is 1. After 1 rescheduling the appointment will not be allowed to reschedule again.</li>
<li>Timing of cancellation and rescheduling must be recorded for refund purpose etc. User details should also be recorded to track who cancelled or rescheduled the appointment.</li>
<li>Doctor will define his/her own appointment cancel time period and appointment reschedule time period in his profile section. This will have the top priority while cancelling or rescheduling the confirmed appointments.</li>
<li>This information will be displayed to Front end user (Patient) while booking the appointment and system will take acceptance from user. This time period will be recorded in appointment data table to avoid any conflict as Doctor can change these parameter after booking appointments.</li>
<li>While cancelling or rescheduling the appointment system will check the Doctor&rsquo;s appointment cancel time period and appointment reschedule time period recorded in appointment data table.</li>
</ol>
<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; E.g. You have booked appointment with Dr. Nidhi Sharma for 21 May 2021 @ 10.00 AM and Dr. Nidhi Sharma has defined her appointment cancel time period to <strong>12 hours</strong> and appointment reschedule time period to <strong>6 hours</strong>. Case 1, patient can cancel this appointment before 20 May 2021 @ 10.00 PM. After 20 May 2021 @ 10.00 PM system should not allow to cancel the appointment.</p>
<p>Case 2, patient can reschedule this appointment before 21 May 2021 @ 04.00 AM. After 21 May 2021 @ 04.00 AM system should not allow to reschedule the appointment.</p>
<ol style="list-style-type: lower-roman;" start="8">
<li>Doctor can cancel or reschedule the appointment at any point of time.</li>
<li>Admin should also have the authority to cancel or reschedule the appointments.</li>
<li>User should provide a comment box while cancelling or rescheduling the appointment to provide the reason for this.</li>
</ol>
<p>&nbsp;</p>
<p><strong>Refund Policy:</strong></p>
<ol style="list-style-type: lower-roman;">
<li>This policy is applicable only on appointments made using&nbsp;<strong>&lsquo;Book Appointment&rsquo;</strong> button on&nbsp;<a href=${base_url}>emedicare.in</a>or&nbsp;.</li>
<li>This policy is applicable for all types of appointments i.e. in-clinic, online and emergency.</li>
<li>Refund claim requests will only be accepted within 10 days from the scheduled appointment date. E.g. If the Doctor, you booked an appointment with, cancels/reschedules or doesn&rsquo;t turn up on 20 May 2021, then claim made till 30 May 2021 will be valid.</li>
<li>Appointment ID must match with the registered mobile number of the User.</li>
</ol>
<p><strong>Refund claim is valid under following circumstances: </strong></p>
<ol style="list-style-type: lower-roman;">
<li>You are present at the clinic at appointment timing and unable to meet the doctor with whom you booked your in-clinic appointment (subject to exceptions in section 4). In case of online appointment, if you don&rsquo;t get a call from Doctor, Doctor confirmation is necessary in this case. User has to arrange technical resources e.g. telecommunication tool, Internet etc. which are required for online consultation. If Doctor tried to contact patient and he is unable to take a call, no refund will be provided.</li>
<li>Your appointment has been cancelled within 2 hours of the scheduled appointment time by Doctor or emedicare.</li>
<li>Your appointment has been rescheduled(postponed) to the next day or later, within 2 hours of your original appointment time by Doctor or emedicare.</li>
<li>Your appointment has been rescheduled(preponed) to a time that is more than 30 mins prior to your original appointment time, and the rescheduling is done within 2 hours of your original appointment time.</li>
</ol>
<p>E.g. If your original appointment is on 8 June - 1 PM, and it is rescheduled (rescheduling is done anytime between 11 am - 1 pm ) to a time prior to 8 June - 12:30 p.m.</p>
<p>&nbsp;</p>
<p><strong>Refund claim is NOT valid under following circumstances:</strong></p>
<ol style="list-style-type: lower-roman;">
<li>Your appointment is cancelled or rescheduled, due to your personal reasons or unavailability.</li>
<li>g. If you/your friends/family cancel or reschedule due to any reason.</li>
<li>g. If anyone from the Doctor, you booked an appointment with, cancels or reschedules on your behalf or as per your request, and not due to Doctor&rsquo;s unavailability.</li>
<li>Your appointment has been rescheduled to a later time on the scheduled appointment day before 2 hours of appointment time.</li>
<li>You are required to wait, even after the appointment time slot has started, to be consulted by the Doctor. Such claims will be valid if wait time is more than 2 hours.</li>
<li>You are unable to reach the clinic at your confirmed appointment time or not available for online consultation.</li>
<li>In adverse cases beyond the reasonable control of the Doctor like acts of god, nature - flood, fire, etc., when Business-As-Usual is not possible.</li>
<li>The above mentioned specific terms relating to emedicare and are without prejudice to the rest of the Terms and Conditions and the Privacy Policy available on the emedicare website/ Application.</li>
<li>emedicare reserves the right to make the final decision in the case of a conflict.</li>
</ol>
<p><strong>&nbsp;</strong></p>
`