import React, { useEffect, useState } from "react";
import CommonTemplate from "./CommonTemplate";
import Breadcrumb from "../General/Breadcrumb/Breadcrumb";
import ListLink from "../General/ListLink";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, meAction } from "../../redux";
import API from "../../common/api/authApi";
import { useLocation } from "react-router";
import FillPrescriptionSidebar from '../Doctor/Appointment/FillPrescriptionSidebar'
import ImageUpload from "./ImageUpload";
import { useHistory } from "react-router-dom";
import UsernameModel from "./UserName/Model"

function DashboardTemplate(props) {
  let history = useHistory();
  const [userName, setUserName] = useState()
  const { breadcrumbItems, title } = props;
  const dispatch = useDispatch();
  const location = useLocation()
  let isFillPrescriptionPage = location.pathname === '/doctor/fill-prescription' ? true : false
  let isMedicalHistoryPage = location.pathname === '/doctor/patient/medical-history' ? true : false

  const updateUsername = val => {
    setUserName(val)
  }

  const logoutHandler = () => {
    API.logout()
      .then((res) => {
        dispatch(logout());
        history.push("/login");
      })
      .catch(() => {
        dispatch(logout());
        history.push("/login");
      });
  };
  const user = useSelector((state) =>
    state && state.auth && state.auth.token ? state.auth.token : {}
  );

  useEffect(() => {
    API.me()
      .then(response => {
        dispatch(login({
          ...user,
          ...[
            'first_name',
            'middle_name',
            'last_name',
            'email',
            'user_type',
            'is_active',
            'roles',
            'first_login'
          ].reduce((a, b) => {
            a[b] = response.data[b];
            return a;
          }, {})
        }))
        dispatch(meAction(response.data))
        // console.log('response.data', response.data);
        setUserName(response.data.username)
      })
      .catch(error => console.log(error.response.data))
  }, []);

  return (
    <CommonTemplate>
      <Breadcrumb items={breadcrumbItems} title={title} />
      <div
        className="content"
        style={{ transform: "none", minHeight: "200px", overflowX: "auto" }}
      >
        <div className="container-fluid" style={{ transform: "none" }}>
          <div className="row" style={{ transform: "none" }}>
            {/* Profile Sidebar */}
            <div
              className="theiaStickySidebar profile-sidebar-parent"
              style={{
                position: "relative",
                overflow: "visible",
                boxSizing: "border-box",
                minHeight: "1px",
              }}
            >
              {
                isFillPrescriptionPage || isMedicalHistoryPage ? <FillPrescriptionSidebar /> :
                  <div
                    className="theiaStickySidebar"
                    style={{
                      paddingTop: "0px",
                      paddingBottom: "1px",
                      position: "static",
                      transform: "none",
                    }}
                  >
                    <div className="profile-sidebar">
                      <div className="widget-profile pro-widget-content">
                        <div className="profile-info-widget">
                          <a href="/dashboard" className="booking-doc-img">
                            <img
                              src={
                                user.profile_photo || process.env.PUBLIC_URL+"/assets/img/blank-profile.jpg"
                              }
                              alt="User Image"
                            />
                          </a>
                          {/* <div className="booking-doc-img">
                      <ImageUpload 
                      src={user.profile_photo}
                      />
                      </div> */}
                          <div className="profile-det-info">
                            <h3>{user?.user_type === "DOCTOR" && "Dr."} {user?.first_name} {user?.last_name}</h3>
                            <div className="patient-details">
                              <h5>{user.email}</h5>
                            </div>
                            <div className="profile-det-info">
                              {userName && <UsernameModel userName={userName} updateUsername={updateUsername} />}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dashboard-widget">
                        <nav className="dashboard-menu">
                          <ul>
                            <ListLink to="/dashboard">
                              <i className="fas fa-columns" />
                              <span>View & Edit Profile</span>
                            </ListLink>
                            {user.user_type === "PATIENT" && (
                              <>
                                <ListLink to="/appointments">
                                  <i className="fas fa-calendar-check" />
                                  <span>Appointments/follow ups</span>
                                </ListLink>

                                <ListLink to="/patient/prescriptions">
                                  <i className="fas fa-file-prescription" />
                                  <span>Get Quote/Prescription</span>
                                </ListLink>
                                <ListLink to="/medicine-orders">
                                  <div className=" row" style={{ marginTop: "10px" }} >
                                    <div className="col-lg-1">
                                      <i className="fas fa-capsules" />
                                    </div>
                                    <div className="col-lg-10">
                                      <span>New Quotes/Medicine</span>
                                    </div>
                                  </div>
                                </ListLink>
                                <ListLink to="/test-orders">
                                  <i className="fas fa-vials" />
                                  <span>Diagnostics</span>
                                </ListLink>
                                <ListLink to="/purchase-history">
                                  <i className="fas fa-sort-amount-down-alt" />
                                  <span>Purchase History</span>
                                </ListLink>
                                <ListLink to="/family">
                                  <i className="fas fa-users" />
                                  <span>Family Members</span>
                                </ListLink>
                              </>
                            )}
                            {user.user_type === "DOCTOR" && parseInt(user.is_active) === 1 && (
                              <>
                                <ListLink to="/schedule-time">
                                  <i className="fas fa-clock" />
                                  <span>Schedule Time</span>
                                </ListLink>
                                <ListLink to="/doctor/appointments">
                                  <i className="fas fa-calendar-check" />
                                  <span>Appointments</span>
                                </ListLink>
                                <ListLink to="/doctor-payments-payouts">
                                  <i className="fas fa-hand-holding-usd" />
                                  <span>Payments & Payouts</span>
                                </ListLink>
                                <ListLink to="/payment-preferences">
                                  <i class="fas fa-asterisk" />
                                  <span>Payment Preferences</span>
                                </ListLink>
                              </>
                            )}
                            {/* <li>
                          <a href="chat.html">
                            <i className="fas fa-comments" />
                            <span>Message</span>
                            <small className="unread-msg">23</small>
                          </a>
                        </li> */}
                            {/* <li>
                          <a href="profile-settings.html">
                            <i className="fas fa-user-cog" />
                            <span>Profile Settings</span>
                          </a>
                        </li> */}

                            <ListLink to="/reset-password">
                              <i className="fas fa-undo" />
                              <span>Profile Settings</span>
                            </ListLink>

                            <li>
                              <button
                                className="btn btn-link"
                                style={{
                                  color: "#757575",
                                  display: "block",
                                  padding: "16px 20px",
                                }}
                                onClick={logoutHandler}
                              >
                                <i className="fas fa-sign-out-alt" />
                                <span>&nbsp;Logout</span>
                              </button>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                    <div
                      className="resize-sensor"
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        right: "0px",
                        bottom: "0px",
                        overflow: "hidden",
                        zIndex: -1,
                        visibility: "hidden",
                      }}
                    >
                      <div
                        className="resize-sensor-expand"
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          right: 0,
                          bottom: 0,
                          overflow: "hidden",
                          zIndex: -1,
                          visibility: "hidden",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: "0px",
                            top: "0px",
                            transition: "all 0s ease 0s",
                            width: "347px",
                            height: "1063px",
                          }}
                        />
                      </div>
                      <div
                        className="resize-sensor-shrink"
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          right: 0,
                          bottom: 0,
                          overflow: "hidden",
                          zIndex: -1,
                          visibility: "hidden",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            transition: "0s",
                            width: "200%",
                            height: "200%",
                          }}
                        />
                      </div>
                    </div>
                  </div>
              }
            </div>
            {/* / Profile Sidebar */}
            <div className="main-content-parent">
              <div className="card">
                <div className="card-body pt-0">{props.children || ""}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonTemplate>
  );
}

export default DashboardTemplate;
