import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { toastSuccess } from '../../../../common/util/toast';
import { mobileNumberRegex, gstinRegex,handleNumberWithCode } from '../../../../common/util/formUtil';
import autoCatch from '../../../../common/util/autoCatch';
import ButtonWithSpinner from '../../../General/Form/ButtonWithSpinner';
import Input from './../../../General/Form/Input';
import laboratoryRegister from '../../../../common/api/laboratory/laboratoryRegister';
import CountryCode from './../../../General/Form/CountryCode';
import { PhoneNumberUtil } from 'google-libphonenumber'
import FileUpload from './../../../General/Form/FileUpload'
import { setLabData } from '../../../../redux'
import { useDispatch } from 'react-redux';


const defaultFormValues = {
    laboratory_name: '',
    email: '',
    gstin: '',
    country_code: '+91',
    mobile_number: '',
    alt_mobile_number: null,
    alt_country_code: '+91',
    lab_reg_number: '',
    lab_issuing_authority: '',
    lab_date_of_issue: '', 
    lab_valid_upto: '',
    lab_file: '',
}

export const handleNumber = (input, countryCodeInt) => {

    // Get an instance of `PhoneNumberUtil`.
    const phoneUtil = PhoneNumberUtil.getInstance();
    if (!input || !countryCodeInt) return false;

    try {
        const number = phoneUtil.parseAndKeepRawInput(input, countryCodeInt);
        const isValid = phoneUtil.isValidNumber(number);
        return isValid;
    } catch (e) {
        console.log(e)
    }
    return false;
}
const FILE_SIZE = 1024 * 1024 * 2;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
   "application/pdf"
];

function daysInMonth(anyDateInMonth) {
    return new Date(anyDateInMonth.getFullYear(), 
                    anyDateInMonth.getMonth()+1, 
                    0).getDate();
                }


const LabDetails = (props) => {
    const [loading, setLoading] = useState(true);
    const [currentCountry, setCurrentCountry] = useState()
    const dispatch = useDispatch()

    let initialValues = defaultFormValues;
    const validationSchema = Yup.object({
        laboratory_name: Yup.string()
            .required('Laboratory name field is required.'),
        email: Yup.string()
            .email('The email must be a valid email address.')
            .required('The email field is required.'),
        gstin: Yup.string()
            .matches(gstinRegex, "Must be a standard gst number.")
            .max(15, 'Must be 15 characters.')
            .required('The GSTIN number field is required.'),
        lab_reg_number: Yup.string()
            .required('The lab registration number field is required.'),
        lab_issuing_authority: Yup.string()
            .required('The issuing authority field is required.'),
        country_code: Yup.string()
            .matches(mobileNumberRegex, "Invalid code")
            .required('Country code is required.'),
        mobile_number: Yup.number('Must be a number')
            .nullable()
            .test('mobile-num', 'Invalid mobile number', function (value) {
            if (!value) return false;
            const currentCountrycode = currentCountry && currentCountry.code ? currentCountry.code : null
            return handleNumber(value + "", currentCountrycode || "IN")
        })
            .required('Mobile Number is required.'),
        alt_country_code: Yup.string()
            .matches(mobileNumberRegex, "Invalid code"),
        alt_mobile_number: Yup.number('Must be a number')
        .nullable()
        .test('mobile-num-1', 'Invalid mobile number', function (value) {
            if (!value) return true;
            const { alt_country_code: country_code } = this.parent
            return handleNumberWithCode(value, country_code)
        }),
        lab_date_of_issue: Yup.date()
            .test('daysInMonth', 'Please enter a valid date',
            value => value && daysInMonth(value))
            .nullable()
            .max(new Date(),
                'Date of issue cannot be future dates')
            .required('Issue date is mandatory'),

        lab_valid_upto: Yup.date()
        .test('daysInMonth', 'Please enter a valid date',
            value => value && daysInMonth(value))
            .nullable()
            .min(new Date(), "Date must be in the future")
                .required('Date of validity is mandatory'),
        lab_file: Yup.mixed()
        .required("Registration certificate required")
        .test(
          "fileSize",
          "File size is too large",
          value => value && value.size <= FILE_SIZE
        )
        .test(
          "fileFormat",
          "Invalid File Format",
          value => value && SUPPORTED_FORMATS.includes(value.type)
        )
    })


    return (
        <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
            setSubmitting(true);
            let formData = new FormData();
            const {
                laboratory_name,
                email,
                gstin,
                country_code,
                mobile_number,
                alt_country_code,
                lab_reg_number,
                lab_issuing_authority,
                lab_date_of_issue,
                lab_valid_upto,
                lab_file 
            } = values
            let alt_mob = values.alt_mobile_number === null ? "" : values.alt_mobile_number
            if (laboratory_name && email && gstin && mobile_number && country_code) {
                formData.append('laboratory_name',  laboratory_name )
                formData.append('email', email )
                formData.append('gstin',  gstin )
                formData.append('mobile_number', mobile_number )
                formData.append('country_code', country_code )
                formData.append('alt_mobile_number', alt_mob )
                formData.append('alt_country_code', alt_country_code )
            }

            if (lab_reg_number && lab_issuing_authority && lab_date_of_issue && lab_valid_upto) {
                formData.append('lab_reg_number', lab_reg_number)
                formData.append('lab_issuing_authority', lab_issuing_authority)
                formData.append('lab_date_of_issue', lab_date_of_issue)
                formData.append('lab_valid_upto', lab_valid_upto)
            }
            if (lab_file) {
                formData.append('lab_file', lab_file)
            }

            autoCatch(laboratoryRegister.laboratoryRegister(formData).then(res => {
            const lab1Id = res.data.data_id
            dispatch(setLabData(lab1Id))
            if (res.status === 200) {
                toastSuccess('Registration successful')
                props.onSubmit(2)
            }
            setLoading(true);
            setSubmitting(false);
            }), data => {
            if (data && data.errors) {
                setErrors(data.errors)
            }
            setSubmitting(false);
            })

        }}
        >{({
        values,
        errors,
        touched,
        setTouched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        setFieldTouched,
        isSubmitting,
        dirty,
        isValid,
        }) => (
            <form onSubmit={handleSubmit}>
            <h4 className="card-title my-4">Laboratory Details</h4>
            <h5 className="card-title my-4">Basic information</h5>
            <div className="row form-row">
                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <label>Laboratory Name</label>
                        <Input
                        name="laboratory_name"
                        mandatory
                        label="Laboratory Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.laboratory_name}
                        touched={touched.laboratory_name}
                        errors={errors.laboratory_name}
                        />
                    </div>
                </div>

                <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                        <label>Email id</label>
                        <Input
                        name="email"
                        label="Email"
                        type="email"
                        mandatory
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        touched={touched.email}
                        errors={errors.email}
                        />
                    </div>
                </div>
            </div>
            <div className="row form-row">    
                <div className="col-lg-8 col-md-12">
                    <div className="form-group">
                        <label>Mobile Number <span className="text-danger">&nbsp;*</span></label>
                        <div className="phone-number">
                            <div>
                            <CountryCode
                                value={currentCountry}
                                onChange={setFieldValue}
                                defaultValue="+91"
                                onBlur={setFieldTouched}
                                setOptionValue={value => {
                                    setCurrentCountry(value)
                                                        }}
                                error={errors.country_code}
                                touched={touched.country_code}
                                />
                            </div>
                            <div>
                                <Input
                                name="mobile_number"
                                label="Mobile Number"
                                type="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.mobile_number}
                                touched={touched.mobile_number}
                                errors={errors.mobile_number}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                        <label>GSTIN Number</label>
                        <Input
                        mandatory
                        name="gstin"
                        label="GSTIN Number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.gstin}
                        touched={touched.gstin}
                        errors={errors.gstin}
                        />
                    </div>
                </div>
            </div>    
            {/** row 2 */}
            <div className="row form-row">
                <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                        <label>Registration Number</label>
                        <Input
                        mandatory
                        name="lab_reg_number"
                        label="Laboratory Registration Number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lab_reg_number}
                        touched={touched.lab_reg_number}
                        errors={errors.lab_reg_number}
                        />
                    </div>
                </div>

                <div className="col-lg-8 col-md-12">
                    <div className="form-group">
                        <label>Alternative Mobile Number </label>
                        <div className="phone-number">
                            <div>
                            <CountryCode
                                value={currentCountry}
                                onChange={setFieldValue}
                                defaultValue="+91"
                                onBlur={setFieldTouched}
                                setOptionValue={value => {
                                    setCurrentCountry(value)
                                                        }}
                                error={errors.alt_country_code}
                                touched={touched.alt_country_code}
                                />
                            
                            </div>
                            <div>
                                <Input
                                name="alt_mobile_number"
                                label="Alternative Mobile Number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                value={values.alt_mobile_number}
                                touched={touched.alt_mobile_number}
                                errors={errors.alt_mobile_number}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row form-row">
                <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                        <label>Lab Issuing Authority</label>
                        <Input
                        mandatory
                        name="lab_issuing_authority"
                        label="Lab Issuing Authority"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lab_issuing_authority}
                        touched={touched.lab_issuing_authority}
                        errors={errors.lab_issuing_authority}
                        />
                    </div>
                </div>
            
                <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                        <label>Date of Registration <span className="text-danger">*</span></label>
                        <input
                        type="date"
                        className={"form-control" + (errors.lab_date_of_issue && touched.lab_date_of_issue ? " is-invalid" : "")}
                        name="lab_date_of_issue"
                        onChange={e => {
                            setFieldValue('lab_date_of_issue', e.target.value)
                            handleChange(e);
                        }}
                        onBlur={e => {
                            setFieldValue('lab_date_of_issue', e.target.value)
                            handleBlur(e);
                        }}
                        value={values.lab_date_of_issue}
                        />

                        {errors.lab_date_of_issue && (
                        <div style={{ margin: 0, position: "absolute" }} className="invalid-feedback">{errors.lab_date_of_issue}</div>
                        )}
                    </div>
                </div>

                <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                        <label>Registration Valid Upto<span className="text-danger">*</span></label>
                        <input
                        type="date"
                        className={"form-control" + (errors.lab_valid_upto && touched.lab_valid_upto ? " is-invalid" : "")}
                        name="lab_valid_upto"
                        onChange={e => {
                            setFieldValue('lab_valid_upto', e.target.value)
                            handleChange(e);
                        }}
                        onBlur={e => {
                            setFieldValue('lab_valid_upto', e.target.value)
                            handleBlur(e);
                        }}
                        value={values.lab_valid_upto}
                        />

                        {errors.lab_valid_upto && (
                        <div style={{ margin: 0, position: "absolute" }} className="invalid-feedback">{errors.lab_valid_upto}</div>
                        )}
                    </div>
                </div>
            </div>

            {/** row 3 */}
            <div className="row form-row">
                <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                        <label>Upload Registration Certificate<span className="text-danger">&nbsp;*</span></label>
                        <FileUpload
                            errors={errors.lab_file}
                            name="lab_file"
                            onChange={value => {
                                console.log(value)
                            setFieldValue("lab_file", value)
                            }}
                        onBlur={setTouched}
              />
                    </div>
                </div>
            </div>    
                

            {/** row 5 */}
            
            
            <div className="row form-row">
                <div className="col-12">
                <div className="form-group text-right">
                    <ButtonWithSpinner
                    isLoading={isSubmitting}
                    type="submit"
                    disabled={!dirty || !isValid || isSubmitting} >
                    Next
                    </ButtonWithSpinner>
                </div>
                </div>
            </div>

            </form>
        )}
        </Formik>
    )
}

export default LabDetails