import { LOGIN, LOGOUT, PROFILE_PIC } from  './authTypes'


export const login = token =>{
    return {
        type: LOGIN,
        payload: token
    }
}

export const setProfilePic = pic =>{
    return {
        type: PROFILE_PIC,
        payload: pic
    }
}

export const logout = () =>{
    return {
        type: LOGOUT
    }
}