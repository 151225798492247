import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AutoSuggest from "../../General/Form/AutoSuggest/AutoSuggest";
import "./BannerSection.css";
import { getSpecializationList } from "../../../common/api/general/searchApi";
import autoCatch from "../../../common/util/autoCatch";
import AutocompletePlaces from "../../General/Form/AutocompletePlaces/AutocompletePlaces";
import {Slider} from "./Slider";

function BannerSection(props) {
  const history = useHistory();
  const [location, setLocation] = useState("");
  const [specializations, setSpecializations] = useState([]);
  const [specialization, setSpecialization] = useState(null);
  useEffect(() => {
    autoCatch(
      getSpecializationList("Any").then((res) => {
        const { data = [] } = res;
        if (data && data.length > 0) {
          setSpecializations(
            [...data].sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            })
          );
        } else {
          setSpecializations([]);
        }
      })
    );
  }, []);

  const optionWithLabel =
    specializations && specializations.length > 0
      ? specializations.map((item) => ({ label: item.name, value: item.id }))
      : [];
  return (
    <>
      <section className="section section-search ">
        <div className="container-fluid d-flex justify-content-between flex-wrap doctor-mr">
          <div className="home-width col-lg-7 m-0 p-0 mb-4">
            <div className="search-box w-100" style={{maxWidth: "unset"}}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  
                  if (location) {
                    // let sp_loc = location['name'].split(",");
                    // if (!sp_loc || !sp_loc[0]) {
                    //   return false;
                    // }
                    // const loc = sp_loc[0].trim();
                    // const geo_loc = `name=${loc}&latitude=${location['latitude']}&longitude=${location['longitude']}`;
                    
                    
                    let to = `/search/${location}`;
                    
                    if (!!specialization) {
                      to = `${to}?specialization=${specialization}`;
                    }
                    console.log('abcd',to)
                    history.push(to);

                    
                    // history.push({
                    //   pathname: '/search',
                    //   state: {
                    //     loc: loc,
                    //     specialization:specialization,
                    //   }
                    // })
                    
                  }
                  return false;
                }}
              >
                <div className="container doctor-search-container">
                  <div className="row">
                    <div className="col input-group search-location" style={{maxWidth:"unset"}}>
                      <div className="search-col">
                        <h6>Location</h6>
                        <AutocompletePlaces
                          value={location}
                          inputStyle={{ borderRadius: "3px 0 0 3px" }}
                          onChange={(value) => setLocation(value || "")}
                          placeholder="Location you are looking for?"
                        />
                      </div>
                      <div
                       className="hm"
                        style={{
                          width: 2,
                          height: 50,
                          backgroundColor: "#999",
                          alignSelf: "center",
                        }}
                      ></div>
                      <div className="search-col">
                        <h6 className="mm12">Search</h6>
                        <AutoSuggest
                          name="specializations"
                          isLoading={
                            !optionWithLabel || optionWithLabel.length === 0
                          }
                          onChange={(value) =>
                            setSpecialization(value.value || "")
                          }
                          options={optionWithLabel}
                          placeholder="Search for specialization"
                        />
                      </div>

                      <div className="input-group-append">
                        <button type="submit" className="btn btn-primary">
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-4 d-flex align-items-center justify-content-end doctor-join-container position-relative mb-4">
            <img src={process.env.PUBLIC_URL+"/assets/img/doctor-top.png"} className="position-absolute doctor-top"/>
            <div className="col-11 p-0">
              <h2 className="m-0 d-flex pt-2 pb-2 justify-content-center align-items-center">
                Are you a Doctor?
                <button type="button" className="ml-2" onClick={() => history.push("/signup", {
                  type: "DOCTOR"
                })}>Join Us</button>
              </h2>
            </div>
          </div>
        </div>

        <div
          className="w-100"
        >
          {/* <img src="/assets/img/banner1.jpeg" className="w-100"/> */}
          <Slider />
        </div>
      </section> 
    </>
  );
}

export default BannerSection;
