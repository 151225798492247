import React from 'react';

const InfoDetails = ({Title,SubTitle, Icon}) => {
    return (
        <div className="w-100">
            <div className="w-100 d-flex justify-content-center">
                <div className="icon-container p-4">
                    <div className="d-flex justify-content-center align-items-center image-container">
                        <img src={Icon} />
                    </div>
                </div>
            </div>
            <div className="w-100 d-flex flex-column align-items-center pt-4 pb-4">
                <p className="mb-1">{Title}</p>
                <h4>{SubTitle}</h4>
            </div>
        </div>
    );
}

export default InfoDetails;