import React, { useEffect } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { geoCoding, reverseGeoCoding } from "../../../common/api/util/googleAddress";

function Maps(props) {
  const { position = {}, onChange = () => {} } = props;
  const autocompleteRef = React.useRef();
  const [selectedLocation, setSelectedLocation] = React.useState("");

  const onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    populateAddress({
      latitude: lat,
      longitude: lng
    })
  };

  const populateAddress = (data) => {
    reverseGeoCoding(data).then(response => {
      const formatted_address = [
        response.data.route,
        response.data.city,
        response.data.county,
        response.data.state,
        response.data.postal_code
      ].filter(l => l).join(", ");
      setSelectedLocation(formatted_address);
      onChange({ lat:data.latitude, lng:data.longitude, formatted_address });
    }).catch(error => {
      console.log(error);
      setSelectedLocation('');
    });
  }

  const centerMoved = (mapProps, map, coord) => {
    const lat = map.getCenter().lat();
    const lng = map.getCenter().lng();
    if (!lat || !lng) return;
    populateAddress({
      latitude: lat,
      longitude: lng
    })
  };
  const styles = {
    width: "100%",
    maxWidth: "600px",
    height: "350px",
    margin: "0 auto",
    ...props.styles,
  };

  const fetchPlaces = (mapProps, map)  => {
    const { google } = mapProps;
    const places = new google.maps.places.Autocomplete(autocompleteRef.current, {
      types: ["geocode"]
    });
    places.addListener("place_changed", () => {
      setSelectedLocation(places.getPlace().formatted_address || autocompleteRef.current.value);
      geoCoding({
        address: places.getPlace().formatted_address || autocompleteRef.current.value
      })
      .then(response => {
        console.log(response.data);
        onChange({
          lat: response.data.latitude,
          lng: response.data.longitude,
          formatted_address: places.getPlace().formatted_address
        })
      }).catch(error => {
        console.log(error)
      })
    });
  }

  const renderInitialData = () => {
    if (props.getCurrentLocation) {
      (() => {
        if (navigator && navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((pos) => {
            const coords = pos.coords;
            const lat = coords.latitude;
            const lng = coords.longitude;
            populateAddress({
              latitude: lat,
              longitude: lng
            })
          }, error => {
            alert(error.message);
            populateAddress({
              latitude: 8.5241,
              longitude: 76.9366
            })
          }, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          });
        }
      })();
    } else {
      populateAddress({
        latitude: position.lat,
        longitude: position.lng
      })
    }
  }

  useEffect(() => {
    renderInitialData();
  },[]);

  return (
    <Map
      google={props.google}
      onReady={fetchPlaces}
      style={styles}
      onDragend={centerMoved}
      zoom={14}
      center={{
        lat: parseFloat(position.lat),
        lng: parseFloat(position.lng),
      }}
      initialCenter={{
        lat: parseFloat(position.lat),
        lng: parseFloat(position.lng),
      }}
    >
      <div style={{
        position: "absolute",
        zIndex: 10000,
        padding: "5px",
        width: "100%"
      }}>
        <input
          className="form-control"
          style={{fontSize: "12px"}}
          ref={autocompleteRef}
          value={selectedLocation}
          onChange={e => setSelectedLocation(e.target.value)}
        />
      </div>
      <Marker
        position={position}
        draggable={true}
        onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
      />
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
})(Maps);
