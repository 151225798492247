import api from './axios'

const REGISTER = '/oauth/register'
const VERIFY_OTP = '/oauth/otp/verify/mobileandemail'
const VERIFY_OTP_MOBILE = '/oauth/otp/verify/mobile'
const RESEND_EMAIL_OTP = '/oauth/otp/resend/email'
const RESEND_PHONE_OTP = '/oauth/otp/resend/mobile'
const FORGOT_PASSWORD = '/oauth/password/forgot'
const FORGOT_PASSWORD_VERIFY = '/oauth/password/forgot/verify'
const RESET_PASSWORD = '/oauth/password/change'
const CHECK_USERNAME = '/oauth/check/username'
const CHANGE_USERNAME = '/change/username'

export const checkUsername = username => {
    return api.get(`${CHECK_USERNAME}?username=${username}`)
}

export const changeUsername = data => {
    return api.post(`${CHANGE_USERNAME}`, data)
}


export const registerUser = data => {
    return api.post(REGISTER, data)
}

export const verifyOtp = (data, isFbOrGoogle = false) => {
    let { mobile_number, mobile_otp, email, email_otp } = data
    const URL = isFbOrGoogle ? VERIFY_OTP_MOBILE : VERIFY_OTP
    return api.post(URL, {
        mobile_number,
        mobile_otp,
        email,
        email_otp
    })
}

export const resendEmailOtp = email =>{
    return api.post(RESEND_EMAIL_OTP,{email})
}

export const resendMobileOtp = mobile_number =>{
    return api.post(RESEND_PHONE_OTP,{mobile_number})
}

export const forgotPassword = email_or_mobile =>{
    return api.post(FORGOT_PASSWORD,{email_or_mobile})
}
export const forgotPasswordVerify = data =>{
    return api.post(FORGOT_PASSWORD_VERIFY,data)
}
// RESET_PASSWORD
export const forgotPasswordReset = data =>{
    return api.post(RESET_PASSWORD,data)
}

export default {
    checkUsername,
    registerUser,
    verifyOtp,
    resendEmailOtp,
    resendMobileOtp,
    forgotPassword,
    forgotPasswordVerify,
    forgotPasswordReset,
    changeUsername,
}