import api from '../axios'


export const pharmacyRegister = data => {
    const headers = {
        "Content-Type": 'multipart/form-data',
        "Accept": "application/json",
    };
    return api.post('oauth/pharmacy/basicinfo', data)
}

export const pharmacyAddress = data => {
    const headers = {
        "Content-Type": 'multipart/form-data',
        "Accept": "application/json",
    };
    return api.post('oauth/pharmacy/address', data)
}

export const AdditionalDetailsRegister = data => {
    const headers = {
        "Content-Type": 'multipart/form-data',
        "Accept": "application/json",
    };
    return api.post('oauth/pharmacy/additionaldetails', data)
}

export const pharmacyOtp = data => {
    const headers = {
        "Content-Type": 'multipart/form-data',
        "Accept": "application/json",
    };
    return api.post('oauth/pharmacy/otp/verify/mobileandemail', data)

}
export const pharmacyResendMobileOtp = data => {
    const headers = {
        "Content-Type": 'multipart/form-data',
        "Accept": "application/json",
    };
    return api.post('oauth/pharmacy/otp/resend/mobile', data)

}
export const pharmacyResendMailOtp = data => {
    const headers = {
        "Content-Type": 'multipart/form-data',
        "Accept": "application/json",
    };
    return api.post('oauth/pharmacy/otp/resend/email', data)

}

export default {
    pharmacyRegister,
    pharmacyAddress,
    AdditionalDetailsRegister,
    pharmacyOtp,
    pharmacyResendMobileOtp,
    pharmacyResendMailOtp

}
