import React from 'react';
import Header from '../General/Header';
import Footer from '../General/Footer';

function CommonTemplate(props) {
    return (
        <>
            <Header/>
            {props.children}
            <Footer/>
        </>
    );
}

export default CommonTemplate;