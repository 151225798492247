import React from "react";
import { Modal, Button } from 'react-bootstrap';
import {TermsAndConditionsView} from './View'


export default class Example extends React.Component {
    constructor(props, context) {
      super(props, context);
  
      this.state = {
        smShow: false,
        lgShow: false,
      };
    }
  
    render() {
      let smClose = () => this.setState({ smShow: false });
      let lgClose = () => this.setState({ lgShow: false });
  
      return (
        <>
          <Button variant="link" style={{color:this.props.color}} onClick={() => this.setState({ lgShow: true })}>Terms and Conditions</Button>
          <Modal
            size="lg"
            show={this.state.lgShow}
            onHide={lgClose}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <TermsAndConditionsView />
            </Modal.Body>
          </Modal>
        </>
      );
    }
  }