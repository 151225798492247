import React from "react";
import "./style.css";
function MainUserActions() {
  return (
    <section className="section bg-1 user-actions-ha-p">
      <div className="w-100 home-width pt-5 pb-5">
        <div className="row">
          <div className="col-12 col-lg-6 d-flex justify-content-center">
            <div className="card mb-3 action-card">
              <div className="row no-gutters">
                <div
                  className="col-md-4 col-lg-3"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflowX: "hidden"
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL+"/assets/img/man.png"}
                    style={{ borderRadius: 0, height: "220px", width: "auto" }}
                    className="card-img"
                    alt="doc"
                  />
                </div>
                <div className="col-md-8 col-lg-9" style={{ display: "flex", alignItems: "center" }}>
                  <div className="card-body pl-4">
                    <h4 className="card-title b f-34">Health Associate</h4>
                    <p className="card-text b-500 text-secondary">
                      Be a health representative without much hussle that meet your needs
                    </p>
                    <p className="card-text">
                      <a style={{marginRight:"10px"}} href={process.env.REACT_APP_ADMIN_URL + '/auth/'} className="btn btn-primary findnow-btn mb-2">Login</a>
                      <a href={process.env.REACT_APP_ADMIN_URL + "/auth/registration"} className="btn btn-primary findnow-btn mb-2">Signup</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex justify-content-center">
            <div className="card mb-3 action-card">
              <div className="row no-gutters">
                <div
                  className="col-md-4 col-lg-3"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflowX: "hidden"
                  }}
                >
                  <img
                    src={process.env.PUBLIC_URL+"/assets/img/medical.png"}
                    style={{ borderRadius: 0, height: "220px", width: "auto", padding: "0 18px" }}
                    className="card-img"
                    alt="doc"
                  />
                </div>
                <div className="col-md-8 col-lg-9" style={{display:"flex", alignItems: "center"}}>
                  <div className="card-body pl-4">
                    <h4 className="card-title b f-34">Find a Pharmacy</h4>
                    <p className="card-text b-500">
                      Find top Pharmacies without much hussle that meet your needs.
                    </p>
                    <p className="card-text">
                    <a href={process.env.REACT_APP_ECOMMERCE_URL + "/medicines"} className="btn btn-primary findnow-btn mb-2">
                    Find Now
                      </a>
                      {/* <button className="btn btn-primary findnow-btn">Find Now</button> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainUserActions;
